const StopIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="noun_Stop_2209801"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g id="Group_866" data-name="Group 866">
      <path
        id="Path_473"
        data-name="Path 473"
        d="M8.965,0A9,9,0,1,1,0,8.965,8.993,8.993,0,0,1,8.965,0Zm-4.8,7.624h9.671v2.753H4.165Z"
        fill="#e60000"
        fill-rule="evenodd"
      />
    </g>
  </svg>
);

export default StopIcon;
