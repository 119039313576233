import { RouteObject } from "react-router";

export const routes = {
  home: "/",
  login: "/login",
  logout: "/logout",
  admin: "/admin",
  exports: "/exports",
  subscriberProfilesCdr: "/subscriberProfilesCdr",
  servicesData: "/services-data",
  logs: "logs",
  measurements: "measurements",
  customQuery: "custom-query",
};

export const adminSubroutes = {
  users: "users",
  roles: "roles",
  configuartion: "config",
  addUser: "add-user",
  editUser: "edit-user",
  addRole: "add-role",
  editRole: "edit-role",
  status: "status",
};

export const exportsSubroutes = {
  exports: "exports",
  previousExports: "previous",
  createNewExport: "new",
  editExport: "edit",
  viewExport: "view",
  download: "download",
};

export const subscriberProfilesCdrSubroutes = {
  profileInfo: "info",
  products: "products",
  lastEdrs: "last-edrs",
  lastCdrs: "last-cdrs",
  bundleDetails:"bundle-details",
  customCounter:"customCounter"
};

export const logsSubroutes = {
  results: "results",
  singleLog: "single-log",
};

export const routesArray:RouteObject[] = [{path: routes.admin}, 
  {path: `${routes.admin}/*`},
  {path: `${routes.home}`},
  {path: routes.login},
  {path: routes.logout},
  {path: `${routes.exports}/*`},
  {path: `${routes.servicesData}/*`},
  {path: `${routes.subscriberProfilesCdr}/*`},
  {path: `${routes.logs}/*`},
  {path: `${routes.measurements}/*`},
  {path: routes.customQuery},
  ]