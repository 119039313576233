import styled from "@emotion/styled";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import React, { FunctionComponent, useMemo, useEffect } from "react";
import { colors } from "../../common/colors";
import DetailedJSON from "../../common/detailed-JSON";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import { useGetFullServiceDataMutation } from "../../lib/store/services/symphony";
import ExportModal from "../shared/export-modal";
import { ServiceDataFields } from "../subscriber-profiles-cdr/types";
import ServicesDataRow from "./services-data-row";
import { ServiceDataResponse } from "./table-data-types";
import { initialStatus, serviceDataReducer } from "./serviceDataReducer";
import { convertObjectToStringWithoutQuotes } from "./service-data-utils";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { parseColFilterParam } from "../../lib/utils";
import { getFormattedDate } from "../../lib/date-utils";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const LastServicesDataTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ServiceDataTableRows = ({
  data,
  tableHeaders,
}: {
  data: ServiceDataResponse;
  tableHeaders: string[];
}) => {
  const tableRowElements = useMemo(() => {
    const tableRows: JSX.Element[] = [];
    data?.data.forEach((row, i) => {
      let parsedRow = parseServiceDataResponseRow(row, data);

      tableRows.push(<ServicesDataRow parsedRow={parsedRow} tableHeaders={tableHeaders} />);
    });
    return tableRows;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.data, data.table, tableHeaders]);
  return <>{tableRowElements}</>;
};

export const RenderTable: FunctionComponent<{
  tableHeaderCells: JSX.Element[];
  tableHeaders: string[];
  handleColumnValuesChange: (columnValues: Record<string, string>) => void;
  data: ServiceDataResponse;
  fullData?: ServiceDataResponse;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  filters: ServiceDataFields;
}> = ({
  tableHeaderCells,
  tableHeaders,
  handleChangePage,
  data,
  handleChangeRowsPerPage,
  handleColumnValuesChange,
  filters,
}) => {
  let parsedRows = [];

  const [state, dispatch] = React.useReducer(serviceDataReducer, initialStatus);
  const selectedMarket: string = useSelector(selectMarket);
  const opco: string = React.useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  useEffect(() => {
    let arr = [];

    data?.data.forEach((row) => {
      const parsedRow = parseServiceDataResponseRow(row, data);
      arr.push(parsedRow);
    });
    dispatch({ type: "setData", payload: arr });
  }, [data]);

  const [getFullServicesData, { isLoading }] = useGetFullServiceDataMutation();
  const fetchFullData = async (includeColFilters: boolean) => {
    const res: { data: ServiceDataResponse } | { error: FetchBaseQueryError | SerializedError } =
      await getFullServicesData({
        table: filters?.in,
        searchKeyword: filters?.searchFor,
        from: filters?.from ? getFormattedDate(filters.from) : undefined,
        to: filters?.to ? getFormattedDate(filters.to) : undefined,
        exportFlag: true,
        pageNo: 0,
        colFilters: includeColFilters ? parseColFilterParam(filters.colFilters) : {},
        opco: opco,
      });

    "data" in res &&
      res.data.data.forEach((row) => {
        const parsedRow = parseServiceDataResponseRow(row, res.data);
        parsedRows.push(parsedRow);
      });
    return parsedRows;
  };

  const headersTable = tableHeaders.map((property) => {
    return {
      label: property,
      mapping: (row) => {
        let value = row[property];

        if (Array.isArray(value)) {
          if (value.length === 0) {
            return "[]";
          } else {
            let res = value.map((item) => {
              if (Array.isArray(item)) {
                const str = item.toString();
                return `"${str}"`;
              } else if (typeof item === "object" && item !== null) {
                return convertObjectToStringWithoutQuotes(item);
              } else if (typeof item === "boolean") {
                return item ? `"true"` : `"false"`;
              } else {
                return item;
              }
            });
            return `"${res.toString()}"`;
          }
        } else if (typeof value === "object" && value !== null) {
          return `"${JSON.stringify(value).replace(/,/g, " ")}"`;
        } else if (typeof value === "boolean") {
          return value ? `"true"` : `"false"`;
        } else if (value === undefined) {
          return "";
        } else {
          return `"${value}"`;
        }
      },
    };
  });

  return (
    <div>
      <TableContainer
        style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "50px" }}
        sx={{ maxHeight: "50vh" }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="user table">
          <LastServicesDataTableHead>
            <TableRow>{tableHeaderCells}</TableRow>
          </LastServicesDataTableHead>
          <TableBody>
            <TableColumnFilterRow
              columns={tableHeaders}
              onChange={handleColumnValuesChange}
              charsToTriggerOnChange={2}
              columnFilterValues={filters.colFilters}
            />
            <ServiceDataTableRows data={data} tableHeaders={tableHeaders} />
          </TableBody>
        </Table>
      </TableContainer>
      <FooterWrapper>
        <TablePagination
          component="div"
          count={data.total}
          page={filters.pageNo}
          onPageChange={handleChangePage}
          rowsPerPage={filters.perPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableFooter>
          <ExportModal
            data={state.data}
            headers={headersTable}
            selectedTab="serviceData"
            fetchFullData={fetchFullData}
            isFetching={isLoading}
          />
          <DetailedJSON
            data={null}
            tableName="Services Data"
            subTableName={filters.in}
            fetchDataOnOpenPromise={fetchFullData}
            isLoadingData={isLoading}
          />
        </TableFooter>
      </FooterWrapper>
    </div>
  );
};

const parseServiceDataResponseRow = (row: any, responseData: ServiceDataResponse): {} => {
  let parsedRow = {};
  //here there are the Dump_Node and Dump_Timestamp fields that also need to be shown
  let newRow = { ...row };

  //tk 84423 this is the actual json coming from gcp with the data to show in the GUI
  //after fibq implementation in BE, response data structure can be slightly different depending on the selected table
  if (row && row[responseData.table][responseData.table]) {
    parsedRow = JSON.parse(row[responseData.table][responseData.table]) as Object;

    newRow = { ...row[responseData.table] }; //retrieve dump_node and timestamp from here instead
  } else if (row && row[responseData.table]) {
    if (typeof row[responseData.table] === "string")
      parsedRow = JSON.parse(row[responseData.table]) as Object;
    else newRow = { ...row[responseData.table] };
  }

  // remove duplicated property;
  delete newRow[responseData.table];
  return { ...newRow, ...parsedRow };
};
