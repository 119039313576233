const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="noun_Refresh_2713651"
    width="20.728"
    height="17.156"
    viewBox="0 0 20.728 17.156"
  >
    <g id="Group_696" data-name="Group 696">
      <path
        id="Path_412"
        data-name="Path 412"
        d="M50.131,5.922a.438.438,0,0,1-.564.282.452.452,0,0,1-.282-.611A8.551,8.551,0,0,1,65.83,7.849l1.034-.987a.465.465,0,1,1,.658.658L65.736,9.306a.454.454,0,0,1-.658,0L63.292,7.52a.465.465,0,1,1,.658-.658l.94.94a7.637,7.637,0,0,0-14.759-1.88Z"
        transform="translate(-46.935)"
        fill="#fff"
      />
      <path
        id="Path_413"
        data-name="Path 413"
        d="M.8,165.632a.454.454,0,0,1-.658,0,.454.454,0,0,1,0-.658l1.786-1.833a.454.454,0,0,1,.658,0l1.833,1.833a.465.465,0,1,1-.658.658l-.987-.987a7.639,7.639,0,0,0,14.712,2.068.489.489,0,0,1,.611-.282.466.466,0,0,1,.235.611,8.552,8.552,0,0,1-16.5-2.444Z"
        transform="translate(0 -155.339)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default RefreshIcon;
