import styled from "@emotion/styled";
import { Alert, Button, CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
//import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";
import { colors } from "./common/colors";
import { RootRoutes } from "./components/root-routes";
import Sidebar from "./components/sidebar/sidebar";
import { useIsLoggedIn } from "./lib/hooks/useIsLoggedIn";
import { theme } from "./lib/theming/mui-theme";

const Main = styled.div`
  width: 100%;
  display: flex;
  height: 100vh;
`;

const MainContent = styled.div`
  width: calc(100vw - 200px);
  padding: 20px;
  height: 100%;
  overflow: auto;
`;

const StillHereSection = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 300px;
  background: ${colors.brightRed};
`;

const CenteredText = styled(Typography)`
  text-align: center;
  color: ${colors.textBlack};
`;

const HereButton = styled(Button)`
  margin-left: 25px;
  text-transform: none;
  background: ${colors.orange};
  :hover {
    color: ${colors.textBlack};
    background: ${colors.orange};
  }
  color: ${colors.textWhite};
  width: 150px;
`;

const timeout = 1800_000;
const promptBeforeIdle = 5_000;

const App = () => {
  const [state, setState] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setState("Idle");
    setOpen(false);
  };

  const onActive = () => {
    setState("Active");
    setOpen(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpen(true);
  };

  // const { getRemainingTime, activate } = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   onPrompt,
  //   timeout,
  //   promptBeforeIdle,
  //   throttle: 500,
  // });
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000));
  //   }, 500);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  // const handleStillHere = () => {
  //   activate();
  // };

  const isLoggedIn = useIsLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (remaining <= 0 && isLoggedIn) {
      navigate("logout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remaining]);

  return (
    <Main>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoggedIn && <Sidebar />}
        <MainContent>
          {isLoggedIn && (
            <StillHereSection
              style={{
                display: open ? "flex" : "none",
                // display: "flex",
              }}
            >
              {/* <Alert severity="warning">
                <CenteredText>
                  You are going to be logged off if no action in {remaining} seconds!
                </CenteredText>
                <HereButton onClick={handleStillHere}>I'm still here</HereButton>
              </Alert> */}
            </StillHereSection>
          )}
          <RootRoutes />
        </MainContent>
      </ThemeProvider>
    </Main>
  );
};

export default App;
