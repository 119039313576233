import { NOTIFICATION_VARIABLE_COL } from "./constants";

//no need to parse recordElement as now it is a simple key value object
// export const parseRecordElement = (detailsCdr: DetailCdrEdr): RecordElement => {
//   return (
//     detailsCdr?.recordElements?.RecordElement?.reduce((acc, r) => {
//       return {
//         ...acc,
//         ...r,
//       };
//     }, {}) ?? {}
//   );
// };

export const formatEpochTime = (epochTime) => {
  let formattedTime = "";
  if (epochTime) {
    const date = new Date(epochTime);
    const year = date.getUTCFullYear();
    let month: string | number = date.getUTCMonth() + 1; // Month is zero-based, so add 1
    let day: string | number = date.getUTCDate();
    let hours: string | number = date.getUTCHours();
    let minutes: string | number = date.getUTCMinutes();
    let seconds: string | number = date.getUTCSeconds();
    // Add a leading zero this will convert date from 31/10/2022 0:0:0 to 31/10/2022 00:00:00
    month = month.toString().length < 2 ? month.toString().padStart(2, "0") : month.toString();
    day = day.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");
    hours = hours.toString().padStart(2, "0");
    minutes = minutes.toString().padStart(2, "0");
    seconds = seconds.toString().padStart(2, "0");
    formattedTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  return formattedTime;
};

export const convertRegexToDateString = (value: unknown) => {
  const convertedDate = formatEpochTime(
    Number((value as string).match(/(?<=eventTime:)[^,]*/g)?.[0] ?? ""),
  );
  // regex is used here to find the string which has the eventTime in front and finishes at the ,
  // after that the value is converted into a more readable format using formatEpochTime function.
  const eventTimeUnixRegex = /(?<=eventTime:)[^,]*/g;
  // regex is used here to idenitfy the piece of the string that will be replaced by the convertedDate
  return (value as string).replace(eventTimeUnixRegex, convertedDate);
};

export const getRecordElementFormattedValue = <T>(valueToConvert: T, col: string) => {
  if (valueToConvert == null) {
    return valueToConvert;
  }

  switch (col) {
    case NOTIFICATION_VARIABLE_COL:
      return convertRegexToDateString(valueToConvert);

    case "sentTime":
    case "deliveryTime":
    case "subscriptionLastResetTime":
    case "lastResetTime":
      return formatEpochTime(Number(valueToConvert));

    case "Dump_Timestamp":
      return formatEpochTime(valueToConvert);
    default:
      if (typeof valueToConvert === "boolean" || typeof valueToConvert === "number") {
        return String(valueToConvert);
      }
      if (typeof valueToConvert === "object" )
        return JSON.stringify(valueToConvert);
      // if (typeof valueToConvert === "number") {
      //   return String(BigInt(valueToConvert));
      // }
      return valueToConvert;
  }
};

export const isEpochTimestamp = <T>(value: T) => {
  // Check if the value is a number
  if (typeof value !== "number") {
    return false;
  }
  // Check if the value is within a reasonable range for Unix timestamps
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000); // Get the current timestamp in seconds
  const minTimestamp = 0; // The minimum Unix timestamp (January 1, 1970)
  const maxTimestamp = currentTimestampInSeconds + 3600; // Set a maximum threshold (e.g., 1 hour from now)
  return value >= minTimestamp && value <= maxTimestamp;
};

export const isDateTimeValid = (dateTimeString: string | undefined) => {
  const parsedDate = new Date(dateTimeString);
  return !isNaN(parsedDate.getTime()); // Check if parsing was successful
};