import { getUnixTime } from "date-fns";
import decode from "jwt-decode";

const ACCESS_TOKEN_KEY = "ACCESS_TOKEN";

export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setToken = (token: string) => {
  return localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const clearToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const isAccessTokenExpired = () => {
  const token = getToken();
  if (!token) //not found in local storage, so in theory it is not expired
  return false;

  const decodedToken = decode(token) as { exp: number };
  const currentTime = getUnixTime(new Date());

  if (decodedToken.exp <= currentTime) 
    return true; //expired
  
    return false; //not expired yet
}
