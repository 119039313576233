import styled from "@emotion/styled";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { ProductsData, ProfileInfoTableData, account } from "./table-types";
import { Loading } from "../shared/loading";
import React from "react";

const ModalHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const style = {
  position: "absolute",
  overflow: "scroll",
  border: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};
const CloseModalButton = styled(Button)`
  background: none;
  color: black;
  font-weight: 700;
  font-size: 22px;
`;

const FooterButton = styled(Button)`
  background: #e60a17;
  border-radius: 34px;
  height: 40px;
  min-width: 180px;
  text-transform: none;
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
`;

const ProfileDetailedJSONModal = ({
  devicesData,
  accountsData,
  tableName,
  fetchDataOnOpenPromise,
  isLoadingData,
}: {
  devicesData: ProductsData[];
  accountsData: account[];
  tableName: string;
  fetchDataOnOpenPromise?: () => Promise<ProfileInfoTableData>;
  isLoadingData?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [promiseData, setPromiseData] = useState<ProfileInfoTableData>(null);

  const handleOpen = async () => {
    setIsOpen(true);

    if (fetchDataOnOpenPromise) {
      const responseData = await fetchDataOnOpenPromise();
      setPromiseData(responseData)  
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const deviceData = promiseData !== null ? promiseData.products : devicesData;
  const profileData = promiseData !== null ? promiseData.profiles.fullAccountsJson : accountsData;

  return (
    <div>
      <FooterButton variant="contained" onClick={handleOpen}>
        Detailed JSON
      </FooterButton>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <ModalHeadWrapper>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={700}>
              {tableName} detailed JSON
            </Typography>
            <CloseModalButton onClick={handleClose}>X</CloseModalButton>
          </ModalHeadWrapper>

          {isLoadingData || !deviceData || !profileData ? (
            <Loading />
          ) : (
            <React.Fragment>
              <Typography>Devices</Typography>
              <JsonView shouldExpandNode={allExpanded} style={defaultStyles} data={deviceData} />
              <Typography>Accounts</Typography>
              <JsonView shouldExpandNode={allExpanded} style={defaultStyles} data={profileData} />
            </React.Fragment>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ProfileDetailedJSONModal;