import { FunctionComponent } from "react";
import { Navigate, Route, Routes, matchRoutes, useLocation } from "react-router";
import { useIsLoggedIn } from "../lib/hooks/useIsLoggedIn";
import { routes, routesArray } from "../lib/routes";
import AdminDashboard from "./admin/admin-dashboard";
import { Login } from "./authentication/login";
import { Logout } from "./authentication/logout";
import { ProtectedRoute } from "./authentication/protected-route";
import CustomQueryDashboard from "./custom-query/custom-query-dashboard";
import { ExportsPage } from "./exports/exports-page";
import { Home } from "./home/home";
import LogsDashboard from "./logsModule/logs-dashboard";
import MeasurementsDashboard from "./measurements/measurements-dashboard";
import ServicesDataDashboard from "./services-data/services-data-dashboard";
import CdrSearch from "./subscriber-profiles-cdr/cdr-search";
import NotFoundPage from "./user/not-found-page";
import { useGetUserAuthorizationdataQuery } from "../lib/store/services/symphony";
import { isAccessTokenExpired } from "../lib/token-utils";
import { useSelector } from "react-redux";
import { selectMarket } from "../lib/store/slices/market-slice";

export const RootRoutes: FunctionComponent = () => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();
  const { data } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket = useSelector(selectMarket);

  //ticket 50354, manage token expiration, don't show not found page after page refresh or reopening browser
  const isTokenExp = isAccessTokenExpired();
  const requestedRouteExists = matchRoutes(routesArray, location);

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole = data?.data.roles.find(
    (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
  );

  return (
    <Routes>
      <Route
        path={routes.home}
        element={
          <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${routes.admin}/*`}
        element={
          <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
            <AdminDashboard />
          </ProtectedRoute>
        }
      />
      {isLoggedIn && <Route path={routes.login} element={<Navigate to="/" replace />} />}
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.logout} element={<Logout />} />
      {data && "data" in data && currentRole.view_export && (
        <Route
          path={`${routes.exports}/*`}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <ExportsPage />
            </ProtectedRoute>
          }
        />
      )}
      {data && "data" in data && currentRole.show_subscribers && (
        <Route
          path={`${routes.subscriberProfilesCdr}/*`}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <CdrSearch />
            </ProtectedRoute>
          }
        />
      )}
      {data && "data" in data && currentRole.show_serviceData && (
        <Route
          path={routes.servicesData}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <ServicesDataDashboard />
            </ProtectedRoute>
          }
        />
      )}
      {data && "data" in data && currentRole.show_logs && (
        <Route
          path={`${routes.logs}/*`}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <LogsDashboard />
            </ProtectedRoute>
          }
        />
      )}
      {data && "data" in data && currentRole.show_measurements && (
        <Route
          path={`${routes.measurements}/*`}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <MeasurementsDashboard />
            </ProtectedRoute>
          }
        />
      )}
      {data && "data" in data && currentRole.show_custom_query && (
        <Route
          path={routes.customQuery}
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp}>
              <CustomQueryDashboard />
            </ProtectedRoute>
          }
        />
      )}

      {/* tk50354 show not authenticated / token expired message when try to access and existing page*/}
      {!isLoggedIn && requestedRouteExists && (
        <Route
          path="*"
          element={
            <ProtectedRoute userLoggedIn={isLoggedIn} tokenExpired={isTokenExp} />
          }
        />
      )}

      <Route path="*" element={
         <NotFoundPage />
          }/>
    </Routes>
  );
};
