const ExportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.179"
    height="11.179"
    viewBox="0 0 11.179 11.179"
  >
    <g id="noun_export_447676" transform="translate(-10 -10)">
      <g id="Group_585" data-name="Group 585" transform="translate(10 10)">
        <path
          id="Path_370"
          data-name="Path 370"
          d="M19.907,23.4a.906.906,0,0,1-.908.908H11.565a.906.906,0,0,1-.908-.908V15.965a.906.906,0,0,1,.908-.908H15.9V14.4H11.565A1.562,1.562,0,0,0,10,15.965v7.42a1.566,1.566,0,0,0,1.565,1.579h7.42A1.562,1.562,0,0,0,20.55,23.4V19.067h-.657V23.4Z"
          transform="translate(-10 -13.785)"
          fill="currentColor"
        />
        <path
          id="Path_371"
          data-name="Path 371"
          d="M51.592,10H48.2v.657l2.934-.014-5.03,5.03.461.461,5.016-5.016v2.92h.657v-3.4A.632.632,0,0,0,51.592,10Z"
          transform="translate(-41.056 -10)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default ExportIcon;
