import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../common/colors";
import { useGetLogsIngestionQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";

const UserTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const ColoredTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => (prop === "isColored" ? false : true),
})<{ isColored?: boolean }>`
  background: ${({ isColored }) =>
    isColored ? colors.lightGreyBackground : colors.fadedGreyBackground};
  text-align: center;
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

const StatusTable = () => {
  const { data, error, isLoading } = useGetLogsIngestionQuery(null);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="Logs ingestion table could not be loaded" />;
  }

  return (
    <div>
      <TableContainer
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "50px" }}
        sx={{ maxHeight: 400 }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="user table">
          <UserTableHead>
            <TableRow>
              <CenteredTableCell>Name</CenteredTableCell>
              <CenteredTableCell>Data Type</CenteredTableCell>
              <CenteredTableCell>Status </CenteredTableCell>
              <CenteredTableCell>Last Ingestion</CenteredTableCell>
            </TableRow>
          </UserTableHead>
          <TableBody>
            {data.data.map((row, i) => (
              <ColoredTableRow
                isColored={i % 2 !== 0}
                key={row.File_Name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <CenteredTableCell component="th" scope="row">
                  {row.File_Name}
                </CenteredTableCell>
                <CenteredTableCell>{row.Data_Feed}</CenteredTableCell>
                <CenteredTableCell>{row.Status}</CenteredTableCell>
                <CenteredTableCell>{row.Processing_Timestamp.value}</CenteredTableCell>
              </ColoredTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StatusTable;
