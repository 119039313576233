import { TableCell, TableRow } from "@mui/material";
import { FunctionComponent } from "react";
import { Role } from "../../lib/types/role-types";
import RolesActionCell from "../admin/roles-action-cell";

const RoleRow: FunctionComponent<{ role: Role }> = ({ role }) => {
  const actions = Object.values(role.actions);
  return (
    <TableRow
      key={role.id}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <TableCell component="th" scope="row">
        {role.name}
      </TableCell>
      <TableCell>{role.localMarket}</TableCell>
      <TableCell>{actions}</TableCell>
      <TableCell>
        <RolesActionCell role={role} />
      </TableCell>
    </TableRow>
  );
};

export default RoleRow;
