import { Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { FormikValues } from "formik";
import { generateMuiFormikFields } from "../../lib/mui-formik-field-generator";
import { FormikInstance } from "../../lib/use-formik-return-type";

export const FormikSelect = <T extends FormikValues>({
  formik,
  name,
  ...rest
}: SelectProps & { formik: FormikInstance<T>; name: string }) => {
  const formikFields = generateMuiFormikFields(name, formik);
  const handleChange = !rest.multiple
    ? formik.handleChange
    : (event: SelectChangeEvent<string>) => {
        const {
          target: { value },
        } = event;
        const newValue = typeof value === "string" ? value.split(",") : value;
        formik.setFieldValue(name, newValue);
      };
  return <Select {...rest} {...formikFields} onChange={handleChange} />;
};
