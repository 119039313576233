import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { routes } from "../../lib/routes";
import { useCreateUserMutation, useRoleListQuery } from "../../lib/store/services/symphony";
import { CreateUser } from "../../lib/types/user-types";
import CustomErrorMessage from "../custom-error-message";
import { FormikRadioGroup } from "../formik-fields/FormikRadioGroup";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { Loading } from "../shared/loading";
import { Page } from "../shared/Page";
import { useMemo } from "react";
import { retrieveMarketSelectOptions } from "../shared/markets";

export const CreateUserComponent = () => {
  const { error, isLoading, data } = useRoleListQuery(undefined);
  const marketOptions = useMemo(() => {
    return retrieveMarketSelectOptions();
  }, []);

  const navigate = useNavigate();

  const [createUser, { error: errorCreateUser }] = useCreateUserMutation();
  const formik = useFormik<CreateUser>({
    initialValues: {
      password: "",
      email: "",
      localMarket: [],
      isAdmin: false,
      status: "active",
      description: "",
      roleId: [],
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("The username should be an email")
        .matches(/^[aA-zZ\s]+./, "Only alphabets are allowed for this field ")
        .max(150)
        .required("Please input the user email"),
      localMarket: yup
        .array()
        .min(1, "Please select a local market")
        .required("Please select a local market"),
      password: yup
        .string()
        .required("Please enter a password")
        .min(8, "Password is too short - should be at least 8 characters long.")
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^(?:(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*)$/,
          "Password must include at least 1 special character.",
        )
        .matches(/^(?:(?=.*[A-Z]).*)$/, "Password must include at least one uppercase letter")
        .matches(/^(?:(?=.*[a-z]).*)$/, "Password must include at least one lowercase letter"),
      isAdmin: yup.boolean().required(),
      status: yup.string().required(),
      description: yup.string().max(30),
      roleId: yup.array().min(1, "A minimum of one role is required").required(),
    }),
    onSubmit: (values) => {
      createUser({
        ...values,
        isAdmin: values.isAdmin ? true : false,
        localMarket: values.localMarket,
      }).then((response: any) => {
        if (response && !response.error) navigate(routes.admin);
      });
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return (
    <Page title="Admin Console" withBox subtitle="Create User">
      {errorCreateUser && (
        <CustomErrorMessage
          error={
            "Create user failed, data could not be saved." +
            (errorCreateUser["status"] === 403 ? errorCreateUser["data"]["details"] : "")
          }
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box m={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <FormikTextField fullWidth label="Username" name="email" formik={formik} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormikTextField
                  fullWidth
                  label="Password"
                  type="password"
                  name="password"
                  formik={formik}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="local-market-label">Local Market</InputLabel>

                <FormikSelect
                  multiple
                  labelId="local-market-label"
                  id="localMarket"
                  label="Default Local market"
                  name="localMarket"
                  formik={formik}
                >
                  {marketOptions.map((option) => (
                    <MenuItem value={option.value}>{option.text}</MenuItem>
                  ))}
                </FormikSelect>
                {formik.touched.localMarket && formik.errors.localMarket ? (
                  <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                    {formik.touched.localMarket && formik.errors.localMarket}
                  </FormHelperText>
                ) : null}
                <FormHelperText>Default: {formik.values.localMarket[0]}</FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="role-label">Role</InputLabel>

                <FormikSelect
                  multiple
                  labelId="role-label"
                  id="localMarket"
                  label="Role"
                  name="roleId"
                  formik={formik}
                >
                  {data.data.map((r) => (
                    <MenuItem key={r.id} value={r.id}>
                      {r.name}
                    </MenuItem>
                  ))}
                </FormikSelect>
                {formik.touched.roleId && formik.errors.roleId ? (
                  <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                    {formik.errors.roleId}
                  </FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">User status</FormLabel>
                <FormikRadioGroup aria-label="user status" name="status" formik={formik} row>
                  <FormControlLabel value="active" control={<Radio />} label="Enable" />
                  <FormControlLabel value="deactive" control={<Radio />} label="Disable" />
                </FormikRadioGroup>
              </FormControl>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">System administration flag</FormLabel>
                <FormikRadioGroup
                  aria-label="system administration flag"
                  name="isAdmin"
                  formik={formik}
                  row
                >
                  <FormControlLabel value={true} control={<Radio />} label="Enable" />
                  <FormControlLabel value={false} control={<Radio />} label="Disable" />
                </FormikRadioGroup>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormikTextField label="Description" name="description" multiline formik={formik} />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Create
            </LoadingButton>
          </FormControl>
        </Box>
      </form>
    </Page>
  );
};
