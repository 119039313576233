import styled from "@emotion/styled";
import { colors } from "../../common/colors";
import { Page } from "../shared/Page";
import HomePageError from "./home-page-error";
import LastLogin from "./last-login";
import LatestFiles from "./latest-files";
import LeftIframeChart from "./left-iframe-chart";
import RightIframeChart from "./right-iframe-chart";
import UpcomingMaintenance from "./upcoming-maintenance";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FirstRowTableContainer = styled.div`
  margin-bottom: 25px;
  margin-right: 15px;
  border-style: ridge;
  padding: 10px;
  width: 50%;
  border: none;
`;

const TableContainer = styled.div`
  margin-bottom: 25px;
  margin-right: 15px;
  border-style: ridge;
  border-radius: 20px;
  border-color: ${colors.fadedGreyBackground};
  padding: 10px;
  width: 50%;
`;

export const Home = () => {
  return (
    <Page title="Home">
      <Wrapper>
        <LastLogin />
        {/* <HomePageError /> */}
        <Row>
          <FirstRowTableContainer>
            <UpcomingMaintenance />
          </FirstRowTableContainer>
          <FirstRowTableContainer>
            <LatestFiles />
          </FirstRowTableContainer>
        </Row>
        <Row>
          <TableContainer>
            <LeftIframeChart />
          </TableContainer>
          <TableContainer>
            <RightIframeChart />
          </TableContainer>
        </Row>
      </Wrapper>
    </Page>
  );
};
