import styled from "@emotion/styled";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../common/colors";
import GraphTypeIcon from "../../icons/graph-type-icon";

const hoverButtonStyles = `
  color: ${colors.textWhite};
  background: ${colors.vodafoneRed};
`;

const DropDownButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  border-radius: 4px;
  margin-right: 15px;
  height: 25px;
  color: #000000;
  background: ${colors.vodafoneRed};
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  ${({ isActive }) => (isActive ? hoverButtonStyles : "")}
  &:hover {
    ${hoverButtonStyles}
  }
`;

const GraphTypeSelector = ({
  setSelectGraphType,
}: {
  setSelectGraphType: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedGraphType, setSelectedGraphType] = useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleLineChartClick = () => {
    setSelectGraphType("Line");
    setSelectedGraphType("Line");
    setOpen(false);
  };

  const handlePieChartClick = () => {
    setSelectGraphType("Pie");
    setSelectedGraphType("Pie");
    setOpen(false);
  };

  const handleScatterChartClick = () => {
    setSelectGraphType("Scatter");
    setSelectedGraphType("Scatter");
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <DropDownButton
          isActive={
            selectedGraphType === "Line" ||
            selectedGraphType === "Pie" ||
            selectedGraphType === "Scatter"
          }
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <GraphTypeIcon />
        </DropDownButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleLineChartClick}>Line</MenuItem>
                    <MenuItem onClick={handlePieChartClick}>Pie</MenuItem>
                    <MenuItem onClick={handleScatterChartClick}>Scatter</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

export default GraphTypeSelector;
