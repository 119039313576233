const LogsIcon = () => (
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" viewBox="0 0 16 19">
      <g id="Group_568" data-name="Group 568" transform="translate(425.488 -937.115)">
        <path
          id="Path_129"
          data-name="Path 129"
          d="M1.329,9.121V8.854q0-3.5,0-7c0-.405.091-.5.485-.5h.823C2.791.478,3.274,0,3.987,0S5.179.475,5.336,1.353h8.8c.393,0,.485.095.485.5q0,7.625,0,15.25c0,.4-.094.494-.492.494H1.814c-.393,0-.484-.095-.484-.5,0-.387,0-.774,0-1.176h.656V16.9H13.938V2.042H5.315c0,.273,0,.552,0,.831a2.011,2.011,0,0,1-.044.46.989.989,0,0,1-1.043.725,1.012,1.012,0,0,1-.9-.928c-.009-.132,0-.265,0-.422h.662c0,.125,0,.23,0,.333a.335.335,0,0,0,.326.342.327.327,0,0,0,.333-.333c.009-.33,0-.661,0-1.005H2v8.43H1.335V9.836a.641.641,0,0,0-.67.617.681.681,0,0,0,.6.711c.075.007.152,0,.228,0h9.321a1.024,1.024,0,0,1,1.142,1.155c0,.606,0,1.213,0,1.819a1.008,1.008,0,0,1-1.064,1.086q-4.754,0-9.508,0A1.339,1.339,0,0,1,0,13.818c0-1.044,0-2.087,0-3.13A1.385,1.385,0,0,1,1.329,9.121m-.664,2.62c0,.662,0,1.367,0,2.071a.675.675,0,0,0,.722.74q4.743,0,9.487,0c.306,0,.419-.119.42-.44q0-.878,0-1.755c0-.421-.088-.512-.494-.512q-4.619,0-9.238,0a8.218,8.218,0,0,1-.9-.1M4.641,1.346a.667.667,0,0,0-.693-.67.647.647,0,0,0-.6.67Z"
          transform="translate(-425.488 937.115)"
          fill="currentColor"
        />
        <path
          id="Path_130"
          data-name="Path 130"
          d="M61.256,48.085h.7v.234q0,7.727,0,15.454c0,.4-.1.5-.5.5H48.131v-.664H61.256Z"
          transform="translate(-471.444 891.843)"
          fill="currentColor"
        />
        <path
          id="Path_131"
          data-name="Path 131"
          d="M56.684,89.474a2.8,2.8,0,1,1,2.772-2.813,2.788,2.788,0,0,1-2.772,2.813m-.34-4.858a2.059,2.059,0,0,0-1.69,1.659,2.108,2.108,0,0,0,1.077,2.261,1.943,1.943,0,0,0,2.136-.177c-.443-.448-.882-.9-1.33-1.342a.618.618,0,0,1-.2-.485c.009-.634,0-1.269,0-1.917m2.062.905-1.131,1.149,1.14,1.152a2.018,2.018,0,0,0-.009-2.3m-1.363.187.793-.815-.793-.324Z"
          transform="translate(-476.466 857.935)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_99"
          data-name="Rectangle 99"
          width="3.778"
          height="0.674"
          transform="translate(-416.241 941.874)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_100"
          data-name="Rectangle 100"
          width="3.778"
          height="0.674"
          transform="translate(-416.241 943.23)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_101"
          data-name="Rectangle 101"
          width="3.785"
          height="0.673"
          transform="translate(-416.249 944.582)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_102"
          data-name="Rectangle 102"
          width="3.093"
          height="0.672"
          transform="translate(-416.227 945.939)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_103"
          data-name="Rectangle 103"
          width="1.702"
          height="0.674"
          transform="translate(-414.186 940.519)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_104"
          data-name="Rectangle 104"
          width="0.661"
          height="0.681"
          transform="translate(-415.487 940.511)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_105"
          data-name="Rectangle 105"
          width="6.545"
          height="0.675"
          transform="translate(-421.576 950.004)"
          fill="currentColor"
        />
        <rect
          id="Rectangle_106"
          data-name="Rectangle 106"
          width="0.666"
          height="0.674"
          transform="translate(-422.81 950.003)"
          fill="currentColor"
        />
      </g>
    </svg>
  </div>
);

export default LogsIcon;
