import styled from "@emotion/styled";
import { FormControl, MenuItem, Select, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import {useEffect, useCallback, useMemo, useState } from "react";
import { colors } from "../../common/colors";
import DetailedJSON from "../../common/detailed-JSON";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import { getFormattedDate } from "../../lib/date-utils";
import { useLazyGetFullSubscribersQuery } from "../../lib/store/services/symphony";
import ExportModal from "../shared/export-modal";
import { ProfileInfoTableData } from "./table-types";
import { SearchFields, ctrInsMap } from "./types";
import { formatEpochTime } from "./utils";
import { orderBy } from "lodash";
import { validateFunctionForExcelExport } from "../../lib/utils";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import CustomCounterTableRow from "./custom-counter-table-row";
import SelectedSingleCustomCounter from "./selected-single-custom-counter";
import { setCounterSearch } from "../../lib/store/slices/last-cdredr-slice";
import { useAppDispatch } from "../../lib/store/hooks";
import { RootState } from "../../lib/store/store";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const CounterTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  text-align: center;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const HeaderTableCell = styled(TableCell)`
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 250px;
  white-space: nowrap;
`;



const headers = [
  {
    label: "Dump Node",
    mapping: (row) => row?.dumpNode,
  },
  {
    label: "Counter ID",
    mapping: (row) => validateFunctionForExcelExport(row.bdlRef),
  },
  {
    label: "Counter Current Value",
    mapping: (row) => validateFunctionForExcelExport(row.description),
  },
  {
    label: "Counter Instance ID",
    mapping: (row) => validateFunctionForExcelExport(row.id),
  },
  {
    label: "Usage Limit",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.activationTime);
      return validateFunctionForExcelExport(rowDate);
    },
  },
  {
    label: "DRNThrProfile",
    mapping: (row) => validateFunctionForExcelExport(row.states[0]),
  },
  {
    label: "Last Reset Time",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.creationTime);
      return validateFunctionForExcelExport(rowDate);
    },
  },

];



  

type ProductsProps = {
  tableData: ProfileInfoTableData;
  search?: SearchFields;
};

const CustomCounter = ({
  tableData,
  search,
}: ProductsProps) => {
  const [selectedRow, setSelectedRow] = useState<ctrInsMap | undefined>();


  const dispatch = useAppDispatch();
  const searchCounter = useSelector((state: RootState) => state.lastCdrEdr.searchCounter);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    let newSearchValue ={...searchCounter}
      newSearchValue.pageNo = newPage
      dispatch(setCounterSearch(newSearchValue));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
      let newSearchValue ={...searchCounter}
      newSearchValue.pageNo = 0
      newSearchValue.perPage = parseInt(event.target.value, 10)
      dispatch(setCounterSearch(newSearchValue));
    
  };
  const [customCounterData,setCustomCounterData] = useState([])
  const handleRowClick = (row: ctrInsMap) => {
    setSelectedRow(row);
  };

  const columnsToDisplay = useMemo(
    () => [
      "counterId",
      "counterCurrentValue",
      "counterInstanceId",
      "usageLimit",
      "drnThrProfile",
      "lastUpdateTime",
      "lastResetTime",
      "defaultState",
    ],
    [customCounterData],
  );




  
  const handleColumnValuesChange = useCallback((columnValues: Record<string, string>) => {
    //remove focus from column filter textInput to stop the user from typing while performing API call
    (document.activeElement as HTMLElement).blur();
  //  setColumnValues(columnValues)
    let newSearchValue ={...searchCounter}
    newSearchValue.colFilters = parseColFilterParam(columnValues)
    dispatch(setCounterSearch(newSearchValue));
 
  }, []);


  const sortedNotes = useMemo(() => {
    if (!tableData?.products?.length) return []; // Handle empty data

    return orderBy(
      tableData?.products.slice(0, 2).map((p, i) => ({ index: i, name: p.DumpNode })),
      (n) => n?.name,
    );

  }, [tableData]);

  const [selectedNode, setSelectedNode] = useState<number>(sortedNotes[0]?.index || 0); // Handle potential empty sortedNotes

  useEffect(() => {
    
      handleFormatData(tableData)
  }, [selectedNode,searchCounter.colFilters,searchCounter.perPage,search.pageNo])


  const handleFormatData =(formataData)=>{
    let rowData = []

    formataData.products[selectedNode].entityCtrlnsts.map((deviceEntityData)=>{
      deviceEntityData.value.ctrInsMap.map((deviceData)=>{

        let deviceObj:any = {
        }
        deviceObj.counterId = deviceData.counterDefId
        deviceObj.counterCurrentValue = deviceData.currentValue
        deviceObj.counterInstanceId = deviceData?.counterInsId
        deviceObj.usageLimit = deviceData?.usageLimit
        deviceObj.drnThrProfile = deviceData?.thrsPrfGrpInst[0].thPfGrpDefName
        deviceObj.lastUpdateTime = formatEpochTime(deviceData?.lastUpdateTime)
        deviceObj.lastResetTime = formatEpochTime(deviceData?.lastResetTime)
        deviceObj.defaultState = deviceData?.defaultState

        rowData.push(deviceObj)
      })
    })
    if(searchCounter.colFilters && Object.entries(searchCounter.colFilters).length){
       customCustomFilter(rowData,searchCounter.colFilters)
    }else {
      setCustomCounterData(rowData)
    }
  }

  const customCustomFilter = (filterData,filterValues) => {


    let filterCustomData = [...filterData]
    Object.entries(filterValues)?.map((mapItem) => {
      let key: string = mapItem[0];
      let value: any = mapItem[1];

      filterCustomData = filterCustomData?.filter((filterItem: any) => {
       
        if (
          filterItem[key]
            ?.toString()
            ?.toLowerCase()
            .includes(value?.toString()?.toLowerCase())
        ) {
          return filterItem;
        }
      }
      );
    });
    setCustomCounterData(filterCustomData)


  }


  const columns = useMemo(() => columnsToDisplay, [columnsToDisplay]);
  const handleBack = () => {
    setSelectedRow(undefined);
  };

 
  const selectedMarket: string = useSelector(selectMarket);
  const opco: string = useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  const [getFullSubscriberData, { isLoading }] = useLazyGetFullSubscribersQuery();
  const fetchFullData = async (filterResult: boolean) => {
    const res = await getFullSubscriberData(
      {
        msisdn: search?.msisdn,
        from: search?.fromDate ? getFormattedDate(search.fromDate) : undefined,
        to: search?.toDate ? getFormattedDate(search.toDate) : undefined,
        pageNo: 0,
        opco: opco,
        exportFlag: true,
      },
      true,
    );
    // Get all subscriptions with corresponding dumpNode names
    const allSubscriptions = [];
    for (const note of sortedNotes) {
      const subscriptions = tableData?.products[note.index]?.entityCtrlnsts || [];

      // Add dumpNode name to each subscription object
      subscriptions.forEach((subscription) => {
        const dumpNode = tableData?.products?.[note.index]?.DumpNode;
        if (dumpNode) {
          allSubscriptions.push({
            ...subscription,
            dumpNode: dumpNode,
          });
        }
      });
    }
    //tk73095, show full data in detailedJson aswell, added boolean to keep export function with filters
    if (!filterResult) return res?.data?.data?.products;

    return allSubscriptions;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = searchCounter.pageNo > 0 ? Math.max(0, (1 + searchCounter.pageNo) * searchCounter.perPage - customCounterData.length) : 0;

  return (
    <div>
      {selectedRow && <SelectedSingleCustomCounter counterData={selectedRow} onBackClick={handleBack} />}
      <div style={{ display: selectedRow ? "none" : undefined }}>
        {search.types === "subscriber" && (
          <FormControl style={{ marginBottom: 15 }} size="small">
            <Select
              onChange={(e) => {
                setSelectedNode(Number(e.target.value));
              }}
              value={selectedNode}
            >
              {sortedNotes.map((node) => (
                <MenuItem key={node.index + node.name} value={node.index}>
                  {node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {tableData && (
          <>
            <TableContainer
              style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "50px" }}
              sx={{ maxHeight: "50vh" }}
              component={Paper}
            >
              <Table
                style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
                stickyHeader
                size="small"
                aria-label="user table"
              >
                <TableColumnFilterRow
                  columns={columns}
                  onChange={handleColumnValuesChange}
                  charsToTriggerOnChange={1}
                  columnFilterValues={searchCounter.colFilters}
                />
                <CounterTableHead>
                  <TableRow>
                    <HeaderTableCell>Counter ID</HeaderTableCell>
                    <HeaderTableCell>Counter Current Value</HeaderTableCell>
                    <HeaderTableCell>Counter Instance ID</HeaderTableCell>
                    <HeaderTableCell>Usage Limit</HeaderTableCell>
                    <HeaderTableCell>DRNThrProfile</HeaderTableCell>
                    <HeaderTableCell>Last Update Time</HeaderTableCell>
                    <HeaderTableCell>Last Reset Time</HeaderTableCell>
                    <HeaderTableCell>Default State</HeaderTableCell>
                  </TableRow>
                </CounterTableHead>
                <TableBody>
                  {(searchCounter.perPage > 0
                    ? customCounterData.slice(searchCounter.pageNo * searchCounter.perPage, searchCounter.pageNo * searchCounter.perPage + searchCounter.perPage)
                    : customCounterData
                  ).map((subscription, index) => (
                    <CustomCounterTableRow
                      onClick={handleRowClick}
                      cols={subscription}
                      key={subscription?.id + index.toString()}
                    />
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TableFooter>
              <TablePagination
                component="div"
                count={customCounterData.length}
                page={searchCounter.pageNo}
                onPageChange={handleChangePage}
                rowsPerPage={searchCounter.perPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <ButtonsWrapper>
                <ExportModal
                  selectedTab={CdrDashboardTabs.customCounter}
                  data={customCounterData}
                  fetchFullData={fetchFullData}
                  headers={headers}
                  isFetching={isLoading}
                />
                <DetailedJSON
                  data={[]}
                  tableName="Custom Counter"
                  fetchDataOnOpenPromise={fetchFullData}
                  isLoadingData={isLoading}
                />
              </ButtonsWrapper>
            </TableFooter>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomCounter;
export const parseColFilterParam = (columnFilters: Record<string, string>): Record<string, string> => {
  let finalColFilters = {};

  Object.keys(columnFilters).forEach((columnName) => {
    const colFilterValue = columnFilters[columnName];
    if (colFilterValue !== null && colFilterValue !== undefined && colFilterValue.trim().length > 1)
      finalColFilters[columnName] = colFilterValue;
  });

  return finalColFilters;
};