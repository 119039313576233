const ViewExportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g id="Group_504" data-name="Group 504" transform="translate(-1028 -294)">
      <circle
        id="Ellipse_49"
        data-name="Ellipse 49"
        cx="10"
        cy="10"
        r="10"
        transform="translate(1028 294)"
        fill="#01860b"
        opacity="0.229"
      />
      <g id="noun_View_3601268" transform="translate(1032.932 301.103)">
        <g id="Group_869" data-name="Group 869" transform="translate(0 0.222)">
          <path
            id="Path_482"
            data-name="Path 482"
            d="M72.552,23.4a1.407,1.407,0,0,0-1.059-.4,1.568,1.568,0,0,0-1.059.4,1.343,1.343,0,0,0-.433.971,1.324,1.324,0,0,0,.433.927,1.568,1.568,0,0,0,1.059.4,1.407,1.407,0,0,0,1.059-.4,1.157,1.157,0,0,0,.433-.927A1.184,1.184,0,0,0,72.552,23.4Z"
            transform="translate(-66.425 -21.671)"
            fill="#01860b"
            fill-rule="evenodd"
          />
          <path
            id="Path_483"
            data-name="Path 483"
            d="M10.137,2.908S5.043-3.134,0,2.908C5.043,8.9,10.137,2.908,10.137,2.908ZM5.043.86a1.847,1.847,0,0,1,1.448.6,1.847,1.847,0,0,1,.6,1.448,1.964,1.964,0,0,1-2.047,2A2.176,2.176,0,0,1,3.6,4.356,2.224,2.224,0,0,1,3,2.908a2.031,2.031,0,0,1,.6-1.448A2,2,0,0,1,5.043.86Z"
            transform="translate(0 -0.222)"
            fill="#01860b"
            fill-rule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ViewExportIcon;
