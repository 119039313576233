import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ButtonProps, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { formatEpochTime } from "../subscriber-profiles-cdr/utils";

const StyledTableCell = styled.div`
  text-align: center;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.open {
    white-space: normal;
    overflow: auto;
  }
`;

const ServicesDataRow = ({
  tableHeaders,
  parsedRow,
}: {
  tableHeaders: string[];
  parsedRow: {};
}) => {
  const [extendRow, setExtendRow] = useState(false);
  const handleExpandButtonClick: ButtonProps["onClick"] = (e) => {
    e.stopPropagation();
    setExtendRow((prevExtendRow) => !prevExtendRow);
  };

  const handleValue = (value, header) => {
    if (["Dump_Timestamp"].includes(header) && parsedRow[header]?.value) {
      return formatEpochTime(parsedRow[header].value);
    } else if (["modifiedDate"].includes(header)) {
      const finalValue = typeof value === "string" ? Number(value) : value;
      return formatEpochTime(new Date(finalValue));
    } else if (value === 0 && typeof value == "number") {
      return "0";
    } else if (typeof value == "boolean") {
      return (String(value).toLowerCase() === "true").toString();
    } else return value;
  };

  const cellClassName = extendRow ? "open" : undefined;

  const tableCells = tableHeaders.map((header, k) => {
    const value = handleValue(parsedRow[header], header);
    return (
      <TableCell>
        {k === 0 ? (
          <StyledTableCell key={header} className={cellClassName}>
            <Button onClick={handleExpandButtonClick} endIcon={<ExpandMoreIcon />}></Button>
            {typeof value === "object" ? JSON.stringify(value, null, 2) : value}
          </StyledTableCell>
        ) : (
          <StyledTableCell key={header} className={cellClassName}>
            {typeof value === "object" ? JSON.stringify(value, null, 2) : value}
          </StyledTableCell>
        )}
      </TableCell>
    );
  });

  return <TableRow>{tableCells}</TableRow>;
};

export default ServicesDataRow;
