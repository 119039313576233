import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import InfoIcon from "../../icons/info-icon";
import { User } from "../../lib/types/user-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 648,
  height: 399,
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};

const ModalButton = styled(Button)`
  color: black;
  width: 16px;
  height: 16px;
  &.MuiButton-root {
    :hover {
      background: none;
    }
  }
`;

const DescriptionSection = styled.div`
  width: 581px;
  height: 80px;
  text-align: left;
  letter-spacing: 0px;
  color: #4a4a4a;
  opacity: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const UserInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const InnerRow = styled.div`
  text-align: left;
  margin-bottom: 5px;
`;

const Container = styled.div`
  display: flex;
  min-height: 50%;
`;

const PictureContainer = styled.div`
  width: 300px;
  text-align: center;
`;

const UserInformationModal: React.FunctionComponent<{ user: User }> = ({ user }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Button onClick={handleOpen}>
        <InfoIcon />
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <ButtonContainer>
            <ModalButton onClick={handleClose}>X</ModalButton>
          </ButtonContainer>
          <Container>
            <PictureContainer>Picture will appear here</PictureContainer>
            <div>
              <UserInformationWrapper>
                <InnerRow>Username: {user.email}</InnerRow>
                <InnerRow>Local Market: {user.localMarket}</InnerRow>
                <InnerRow>
                  Role:{" "}
                  {user.roleId
                    ? user.roleId
                        .map((role) => {
                          return role.name;
                        })
                        .join(" , ")
                    : "Unknown"}
                </InnerRow>
                <InnerRow>User Status: {user.status ? "Enabled" : "Disabled"}</InnerRow>
                <InnerRow>
                  System Adminisration Flag: {user.isAdmin ? "Enabled" : "Disable"}
                </InnerRow>
              </UserInformationWrapper>
            </div>
          </Container>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Description
          </Typography>
          <DescriptionSection>{user.description}</DescriptionSection>
        </Box>
      </Modal>
    </div>
  );
};

export default UserInformationModal;
