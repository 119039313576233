import { GetAllBqFieldsResponse } from "../../lib/types/bqfields-types";

export abstract class FIBQConstants {         
    public static BQ_STREAM_SERVICE_DATA:string = "ServiceData";
    public static BQ_STREAM_CDR:string = "CDR";
    public static BQ_STREAM_EDR:string = "EDR";
    public static BQ_STREAM_SUBSCRIBER:string = "SUBSCRIBER";
}

// export const alwaysSelectedAliases: string [] = [
//     // "Detail_CDRS",
//     // "Detail_EDRS",
//     // "Accounts",
//     // "Devices"
// ];

export const getFIBQStreamsTabs = (allBqFields: GetAllBqFieldsResponse, 
    excludedStreams:string[], isServiceDataConfigured:boolean) => {
    if (!allBqFields || allBqFields.data.bqFields.length === 0) return [];

    //retrieve the unique streams from all the configured BQFields data
    let tabs: string[] =
      !excludedStreams || excludedStreams.length === 0
        ? [...new Set(allBqFields.data.bqFields.map((field) => field.stream))]
        : [
            ...new Set(
                allBqFields.data.bqFields
                .filter((field) => !excludedStreams.includes(field.stream))
                .map((field) => field.stream),
            ),
          ];

    //to separate serviceData stream from its tables (stream is "ServiceData-Tablename")
    if (!isServiceDataConfigured) return tabs;

    tabs = tabs.filter((tab) => !tab.startsWith(FIBQConstants.BQ_STREAM_SERVICE_DATA));
    if (!excludedStreams.includes(FIBQConstants.BQ_STREAM_SERVICE_DATA))
    tabs.push(FIBQConstants.BQ_STREAM_SERVICE_DATA);

    return tabs;
}
