import styled from "@emotion/styled";
import { TableCell, TableRow } from "@mui/material";
import { FunctionComponent } from "react";
import DisabledIcon from "../../icons/disabled-icon";
import { User } from "../../lib/types/user-types";
import UsersActionCell from "./users-action-cell";
import { useGetUserQuery } from "../../lib/store/services/symphony";
import { Loading } from "../shared/loading";
import CustomErrorMessage from "../custom-error-message";

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

const StatusSwitch = ({ isEnabled }: { isEnabled: boolean }) => {
  return (
    <>
      <DisabledIcon /> {isEnabled ? "Enabled" : "Disabled"}
    </>
  );
};

const ActiveSwitch = ({ isActive }: { isActive: boolean }) => {
  return (
    <>
      <DisabledIcon /> {isActive ? "Active" : "Inactive"}
    </>
  );
};

export const UserRow: FunctionComponent<{ user: User }> = ({ user }) => {
  const userId = user?.id.toString();
  const { data: userById, isLoading, error } = useGetUserQuery(userId);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !userById) {
    return <CustomErrorMessage error="Data can not be loaded" />;
  }
  return (
    <TableRow
      key={userById?.data?.email}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <CenteredTableCell component="th" scope="row">
        {userById?.data?.email}
      </CenteredTableCell>
      <CenteredTableCell>{userById?.data?.localMarket.join(",")}</CenteredTableCell>
      <CenteredTableCell>
        <StatusSwitch isEnabled={userById?.data?.isAdmin} />
      </CenteredTableCell>
      <CenteredTableCell>
        <ActiveSwitch isActive={userById?.data?.status === "active"} />
      </CenteredTableCell>
      <CenteredTableCell>
        <UsersActionCell user={userById?.data} />
      </CenteredTableCell>
    </TableRow>
  );
};
