import styled from "@emotion/styled";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { colors } from "../../common/colors";
import { getFormattedDate } from "../../lib/date-utils";
import { routes, subscriberProfilesCdrSubroutes } from "../../lib/routes";
import {
  useGetRealTimeSubscriberDataQuery,
  useGetSubscribersQuery,
} from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import Products from "./products";
import ProfileInfo from "./profile-info";
import { SearchFields } from "./types";
import { formatEpochTime, isDateTimeValid } from "./utils";
import { format } from "date-fns";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import BundleDetailsTable from "./bundle-details-table";
import { parseColFilterParam } from "../../lib/utils";
import { useAppDispatch } from "../../lib/store/hooks";
import { setSearch } from "../../lib/store/slices/last-cdredr-slice";
import LastEdrTable from "./last-edr-table";
import LastCdrTable from "./last-cdr-table";
import CustomCounter from "./customCounter";

const TabPanel = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Box>{children}</Box>;
};

const a11yProps = (index: number) => ({
  tabindex:`${index}`,
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const TabsWrapper = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexWrap: "wrap"
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const UserTab = styled(Tab)`
  background-color: ${colors.textWhite};
  border: solid 3px ${colors.greyBackground};
  width: 180px;
  height: 40px;
  margin: 10px 10px 10px 0;
  text-transform: none;
  border-radius: 34px;
  &.Mui-selected {
    background: ${colors.vodafoneRed};
    color: white;
    border: none;
  }
  &.Mui-disabled {
    background-color: lightgray;
  }
`;

const CdrDashboardInner = ({
  selectedTab,
  search
}: {
  selectedTab: string;
  search: SearchFields;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Check if dates are valid before making the API call
  const isFromDateValid = isDateTimeValid(search?.fromDate);
  const isToDateValid = isDateTimeValid(search?.toDate);
  const selectedMarket: string = useSelector(selectMarket);

  const handleChange = (
    _event: unknown,
    newSelectedTab: keyof typeof subscriberProfilesCdrSubroutes,
  ) => {
    navigate(`${routes.subscriberProfilesCdr}/${subscriberProfilesCdrSubroutes[newSelectedTab]}`);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    let newSearchValue ={...search}
      newSearchValue.pageNo = newPage
      dispatch(setSearch(newSearchValue));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newSearchValue ={...search}
    newSearchValue.pageNo = 0
      newSearchValue.perPage = parseInt(event.target.value, 10)
      dispatch(setSearch(newSearchValue));
    
  };

  const opco: string = useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  //autoswitch tabs when changing search type and we are not in the right one already
  useEffect(() => {
    if (
      search.types === "cdr" &&
      selectedTab !== CdrDashboardTabs.lastCdrs &&
      selectedTab !== CdrDashboardTabs.lastEdrs
    )
      handleChange(null, CdrDashboardTabs.lastCdrs);
    else if (
      search.types === "subscriber" &&
      (selectedTab === CdrDashboardTabs.lastCdrs || selectedTab === CdrDashboardTabs.lastEdrs)
    )
      handleChange(null, CdrDashboardTabs.profileInfo);
  }, [search.types]);
  

 const { data:subscData, error:subError, isFetching:subDataFetching } = useGetSubscribersQuery({
    msisdn: search?.msisdn,
    from: isFromDateValid ? getFormattedDate(search.fromDate) : undefined,
    to: isToDateValid ? getFormattedDate(search.toDate) : undefined,
    pageNo: 0,
    perPage: 50,
    opco: opco,
    colFilters: parseColFilterParam(search.colFilters),
  },
  //do not fetch subscribers if CDR search type is selected, or when==now
  {skip: search.types !== "subscriber" || search.when === "now" });

  const {
    data: rtqData,
    error: rtqError,
    isFetching: rtqDataFetching,
  } = useGetRealTimeSubscriberDataQuery(
    {
      MSISDN: search?.msisdn,
      OPCO: opco,
      //fetch again when user clicks search button only when minutes change to prevent multiple queries
      refetchParam:
        search && typeof search.searchClickedTimestamp === "object"
          ? search.searchClickedTimestamp.getMinutes()
          : 0,
    },
    //do not fetch when rtq search is selected
    { skip: search.when !== "now" },
  );

  const showLoader: boolean =  rtqDataFetching || subDataFetching;
  const data = search.when === "now" ? rtqData : subscData;
  const error = search.when === "now" ? rtqError : subError;

  if (error || (!data && search.types === "subscriber")) {
    if (!isFromDateValid || !isToDateValid)
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;

    if (showLoader) return <Loading />;

    return (
      <CustomErrorMessage error="Data is missing. Please check all the fields and try again" />
    );
  }

  const nonEmptyFilters: Record<string, string | string[]> = {};
  Object.entries(search).forEach(([key, value]) => {
    if (value) {
      nonEmptyFilters[key] = value;
    }
  });

  const productsTimeDumpStamp =
    !showLoader &&
    search.types === "subscriber" &&
    selectedTab !== CdrDashboardTabs.lastCdrs &&
    selectedTab !== CdrDashboardTabs.lastEdrs &&
    selectedTab !== CdrDashboardTabs.bundleDetails &&
    data !== undefined &&
    "products" in data?.data &&
    data?.data?.products[0]?.Dump_timestamp &&
    formatEpochTime(data?.data?.products[0]?.Dump_timestamp);

    //rtq is selected we only show profile info and products
    const showOtherSubscriberTabs:boolean = search.when !== "now" && search.types === "subscriber";

  return (
    <div style={{ marginBottom: "5px" }}>
      {selectedTab === CdrDashboardTabs.products || selectedTab === CdrDashboardTabs.profileInfo ? (
        <Typography margin={"5px"}>
          {productsTimeDumpStamp
            ? `Dump timestamp is: ${productsTimeDumpStamp}`
            : `Dump timestamp is: missing`}
        </Typography>
      ) : (
        ""
      )}

      {search.types === "cdr" && search && (
        <Typography>
          From: <b>{format(new Date(search.fromDate), "dd/MM/yyyy HH:mm:ss")}</b> - To:
          <b>{format(new Date(search.toDate), "dd/MM/yyyy HH:mm:ss")}</b>
        </Typography>
      )}
      <Box className={showLoader ? "disabled" : ""}>
        <TabsWrapper value={selectedTab} onChange={handleChange} aria-label="tabs">
          {search.types === "subscriber" && (
            <UserTab value={CdrDashboardTabs.profileInfo} label="Profile info" {...a11yProps(0)} />
          )}

          {search.types === "subscriber" && (
            <UserTab
              disabled={search.when === "now"}
              value={CdrDashboardTabs.products}
              label="Products"
              {...a11yProps(1)}
            />
          )}

          {showOtherSubscriberTabs && (
            <UserTab label="Last 50 CDRs" value={CdrDashboardTabs.lastCdrs} {...a11yProps(2)} />
          )}
          {showOtherSubscriberTabs && (
            <UserTab label="Last 50 EDRs" value={CdrDashboardTabs.lastEdrs} {...a11yProps(3)} />
          )}
          {search.types === "cdr" && (
            <UserTab label="CDRs" value={CdrDashboardTabs.lastCdrs} {...a11yProps(2)} />
          )}
          {search.types === "cdr" && (
            <UserTab label="EDRs" value={CdrDashboardTabs.lastEdrs} {...a11yProps(3)} />
          )}

          {showOtherSubscriberTabs && (
            <UserTab
              value={CdrDashboardTabs.bundleDetails}
              label="Bundle Details"
              {...a11yProps(4)}
            />
          )}
          {showOtherSubscriberTabs && (
            <UserTab
              value={CdrDashboardTabs.customCounter}
              label="Custom Counter"
              {...a11yProps(5)}
            />
          )}
        </TabsWrapper>
      </Box>

      {selectedTab === CdrDashboardTabs.profileInfo && search && data !== undefined && (
        <TabPanel>
          {showLoader && <Loading />}
          <div className={showLoader ? "disabled" : ""}>
            <ProfileInfo tableData={data?.data} selectedTab={selectedTab} search={search} />
          </div>
        </TabPanel>
      )}
      {selectedTab === CdrDashboardTabs.lastCdrs && search && (
        <TabPanel>
          <LastCdrTable selectedTab={selectedTab} search={search} />
        </TabPanel>
      )}
      {selectedTab === CdrDashboardTabs.lastEdrs && search && (
        <TabPanel>
          <LastEdrTable selectedTab={selectedTab} search={search} />
        </TabPanel>
      )}

      {selectedTab === CdrDashboardTabs.products && search && data !== undefined && (
        <TabPanel>
          {showLoader && <Loading />}
          <div className={showLoader ? "disabled" : ""}>
            <Products
              page={search.pageNo}
              rowsPerPage={search.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              tableData={data?.data}
              search={search}
            />
          </div>
        </TabPanel>
      )}
      {selectedTab === CdrDashboardTabs.customCounter && search && data !== undefined && (
        <TabPanel>
          {showLoader && <Loading />}
          <div className={showLoader ? "disabled" : ""}>
            <CustomCounter tableData={data?.data} search={search} />
          </div>
        </TabPanel>
      )}
      {selectedTab === CdrDashboardTabs.bundleDetails && search && (
        <TabPanel>
          <BundleDetailsTable search={search} />
        </TabPanel>
      )}
    </div>
  );
};
export default React.memo(CdrDashboardInner);

