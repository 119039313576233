import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "../../icons/edit-icon";
import { exportsSubroutes, routes } from "../../lib/routes";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import { Export } from "../../lib/types/export-types";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import ExportInformationModal from "./export-information-modal";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { useSelector } from "react-redux";

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExportActionsCell: FunctionComponent<{ exportInfo: Export }> = ({ exportInfo }) => {
  const { data, isLoading, error } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  const handleNotAccessClick = () => {
    alert("You do not have access to this functionality of the app");
  };

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole = data?.data?.roles.find(
    (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
  );

  return (
    <IconWrapper>
      {currentRole && currentRole.view_export === true ? (
        <ExportInformationModal exportInfo={exportInfo} />
      ) : (
        ""
      )}
      {currentRole && currentRole.edit_export === true ? (
        <RouterLink to={`${routes.exports}/${exportsSubroutes.editExport}/${exportInfo.id}`}>
          <Button>
            <EditIcon />
          </Button>
        </RouterLink>
      ) : (
        <Button onClick={handleNotAccessClick}>
          <EditIcon />
        </Button>
      )}
    </IconWrapper>
  );
};

export default ExportActionsCell;
