import styled from "@emotion/styled";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { format } from "date-fns";
import { path } from "ramda";
import * as React from "react";
import { FunctionComponent, useEffect } from "react";
import { colors } from "../../common/colors";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import BackArrowIcon from "../../icons/back-arrow-icon";
import { useGetFullLogsDataMutation, useGetLogsDataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import Error from "../shared/error";
import ExportModal from "../shared/export-modal";
import { Loading } from "../shared/loading";
import { GetLogsData } from "../subscriber-profiles-cdr/types";
import { formatEpochTime, isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import LogsRow from "./logs-row";
import { LogsWithParsedLogs } from "./logs-types";
import SingleLog from "./single-log";
import { convertObjectToStringWithoutQuotes } from "./log-excel-utils";
import { validateFunctionForExcelExport } from "../../lib/utils";
import { setSearchResultField } from "../../lib/store/slices/logs-slice";
import { useAppDispatch } from "../../lib/store/hooks";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const LogsTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const FirstTableCell = styled(TableCell)`
  border-radius: 20px 0 0 0;
  text-align: center;
`;

const LastTableCell = styled(TableCell)`
  border-radius: 0 20px 0 0;
  text-align: center;
`;
const CenteredTableCell = styled(TableCell)`
  text-align: center;
  justify-content: space-between;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.lightBlackSubtitle};
  font: normal 14px Raleway;
`;

const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 5%;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const headers = [
  {
    label: "Dump Timestamp",
    mapping: (row) => row.Dump_timestamp?.value,
  },
  {
    label: "DumpNode",
    mapping: (row) => validateFunctionForExcelExport(row.DumpNode),
  },
  {
    label: "EventHeader_eventID",
    mapping: (row) =>
      validateFunctionForExcelExport(JSON.parse(row.Logs).commonEventHeader.eventId),
  },
  {
    label: "EventHeader_domain",
    mapping: (row) =>
      validateFunctionForExcelExport(JSON.parse(row.Logs).commonEventHeader?.domain),
  },
  {
    label: "EventHeader_eventName",
    mapping: (row) =>
      validateFunctionForExcelExport(JSON.parse(row.Logs).commonEventHeader?.eventName),
  },
  {
    label: "SysLogFields",
    mapping: (row) => {
      return `"${convertObjectToStringWithoutQuotes(JSON.parse(row.Logs).syslogFields)}"`;
    },
  },
];
const getFormattedDate = (dateString: string) => {
  return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
};

const LogsTable: FunctionComponent<{ filters: GetLogsData }> = ({ filters }) => {
  const [selectedRow, setSelectedRow] = React.useState<LogsWithParsedLogs | undefined>();
  const [columnValues, setColumnValues] = React.useState<Record<string, string>>({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [columnFiltersValues, setColumnFiltersValues] = React.useState<Record<string, string>>({});
  const dispatch = useAppDispatch();

  // Reset page to 0 when the search prop changes
  useEffect(() => {
    setPage(0);
  }, [filters]);

  const handleColumnValuesChange = React.useCallback((columnValues: Record<string, string>) => {
    //remove focus from column filter textInput to stop the user from typing while performing API call
    (document.activeElement as HTMLElement).blur();
    let newSearchValue = { ...filters };
    newSearchValue.colFilters = columnValues;
    dispatch(setSearchResultField(newSearchValue));
  }, []);

  // Check if dates are valid before making the API call
  const isFromDateValid = isDateTimeValid(filters?.from);
  const isToDateValid = isDateTimeValid(filters?.to);

  const { data, isLoading, isFetching, error } = useGetLogsDataQuery({
    from: isFromDateValid ? getFormattedDate(filters.from) : undefined,
    to: isToDateValid ? getFormattedDate(filters.to) : undefined,
    searchKeyword: filters.searchKeyword,
    perPage: filters.perPage,
    pageNo: filters.pageNo,
    colFilters: parseColFilterParam(filters.colFilters),
  });

  const [getFullLogsData, { isLoading: isLoadingFullData }] = useGetFullLogsDataMutation();

  const fetchFullData = async () => {
    const res: any = await getFullLogsData({
      // Provide the necessary query parameters
      from: isFromDateValid ? getFormattedDate(filters.from) : undefined,
      to: isToDateValid ? getFormattedDate(filters.to) : undefined,
      searchKeyword: filters.searchKeyword,
      exportFlag: true,
      pageNo: 0,
    });
    return res.data.data;
  };

  const tableHeaders = React.useMemo(() => {
    return [
      "Dump_timestamp",
      "DumpNode",
      "EventHeader_eventId",
      "EventHeader_domain",
      "EventHeader_eventName",
      "syslogFields",
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data]);

  const handleRowClick = (row: LogsWithParsedLogs) => {
    setSelectedRow(row);
  };

  const handleBackClick = () => {
    setSelectedRow(undefined);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    let newSearchValue = { ...filters };
    newSearchValue.pageNo = newPage;
    dispatch(setSearchResultField(newSearchValue));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newSearchValue = { ...filters };
    newSearchValue.perPage = parseInt(event.target.value, 10);
    newSearchValue.pageNo = 0;

    dispatch(setSearchResultField(newSearchValue));
  };
  const formattedData = React.useMemo(() => {
    return (
      data?.data?.map((d) => {
        const parsedLogs = JSON.parse(d.Logs);
        return {
          ...d,
          Dump_timestamp: { value: formatEpochTime(d.Dump_timestamp?.value) },
          Processing_timestamp: { value: formatEpochTime(d.Processing_timestamp?.value) },
          Logs: parsedLogs,
        };
      }) ?? []
    );
  }, [data?.data]);

  const filteredData = React.useMemo(
    () =>
      formattedData.filter((row) => {
        const logs = row.Logs;
        return Object.entries(columnValues)
          .filter(([, val]) => val)
          .every(([key, value]) => {
            const keyPath = key.split(".");
            const valueAtPath = path(keyPath, row) ?? path(keyPath, logs);
            const lowerCaseValue =
              typeof valueAtPath === "object"
                ? JSON.stringify(valueAtPath).toLowerCase()
                : (valueAtPath ?? "").toString().toLowerCase();
            return lowerCaseValue.includes(value?.toLowerCase() ?? "");
          });
      }),
    [formattedData, columnValues],
  );

  const columns = React.useRef([
    "Dump_timestamp.value",
    "DumpNode",
    "commonEventHeader.eventId",
    "commonEventHeader.domain",
    "commonEventHeader.eventName",
    "syslogFields",
  ]);

  if (isLoading || isLoadingFullData) {
    return <Loading />;
  }

  if (error || !data) {
    if (!isFromDateValid || !isToDateValid) {
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;
    }
    return <Error />;
  }

  return (
    <div>
      {selectedRow === undefined ? (
        <div>
          {isFetching && <Loading />}
          <TableContainer sx={{ maxHeight: "65vh" }} component={Paper}>
            <Table stickyHeader size="small" aria-label="user table">
              <LogsTableHead>
                <TableRow>
                  <FirstTableCell>
                    <CenteredCell>Dump timestamp</CenteredCell>
                  </FirstTableCell>
                  <CenteredTableCell>
                    <CenteredCell>DumpNode</CenteredCell>
                  </CenteredTableCell>
                  <CenteredTableCell>
                    <CenteredCell>EventHeader_eventID</CenteredCell>
                  </CenteredTableCell>
                  <CenteredTableCell>
                    <CenteredCell>EventHeader_domain</CenteredCell>
                  </CenteredTableCell>
                  <CenteredTableCell>
                    <CenteredCell>EventHeader_eventName</CenteredCell>
                  </CenteredTableCell>
                  <LastTableCell>
                    <CenteredCell>SysLogFields</CenteredCell>
                  </LastTableCell>
                </TableRow>
              </LogsTableHead>
              <TableBody>
                <TableColumnFilterRow
                  columns={tableHeaders}
                  onChange={handleColumnValuesChange}
                  charsToTriggerOnChange={1}
                  columnFilterValues={filters.colFilters}
                />
                {filteredData.map((row) => (
                  <LogsRow onClick={() => handleRowClick(row)} logs={row} key={row.Logs} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <FooterWrapper>
            <TablePagination
              component="div"
              count={data.total}
              page={filters.pageNo}
              onPageChange={handleChangePage}
              rowsPerPage={filters.perPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <TableFooter>
              <ExportModal
                data={data?.data}
                headers={headers}
                fetchFullData={fetchFullData}
                selectedTab={"logs"}
              />
            </TableFooter>
          </FooterWrapper>
        </div>
      ) : (
        <div>
          <div>
            <ButtonRow>
              <BackButton onClick={handleBackClick}>
                <BackArrowIcon /> Back to Logs
              </BackButton>
            </ButtonRow>
          </div>
          <SingleLog logs={selectedRow} />
        </div>
      )}
    </div>
  );
};

const parseColFilterParam = (columnFilters: Record<string, string>): Record<string, string> => {
  let finalColFilters = {};

  Object.keys(columnFilters).forEach((columnName) => {
    const colFilterValue = columnFilters[columnName];
    if (colFilterValue !== null && colFilterValue !== undefined && colFilterValue.trim().length > 1)
      finalColFilters[columnName] = colFilterValue;
  });

  return finalColFilters;
};

export default LogsTable;
