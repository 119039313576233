import { SingleRowDisplayer } from "./single-row-displayer";
import { ctrInsMap } from "./types";

const SelectedSingleCustomCounter= ({
  counterData,
  onBackClick,
}: {
  counterData: ctrInsMap;
  onBackClick: () => void;
}) => {
   console.log("SelectedSingleCustomCounter----------------counterData",counterData)

  const columns = [
    {
      header: "Counter ID",
      value: counterData.counterId,
    },
    {
      header: "Counter Current Value",
      value: counterData.counterCurrentValue
    },
    {
      header: "Counter Instance ID",
      value: counterData.counterInstanceId,
    },
    {
      header: "Usage Limit",
      value: counterData.usageLimit,
    },
    {
      header: "DRNThrProfile",
      value: counterData.drnThrProfile,
    },
    {
      header: "Last Update Time",
      value: counterData.lastUpdateTime,
    },
    {
      header: "Last Reset Time",
      value: counterData.lastResetTime,
    },
    {
      header: "Default State",
      value: counterData.defaultState,
    },
   
  ];

  return <SingleRowDisplayer cols={columns} onBackClick={onBackClick} />;
};

export default SelectedSingleCustomCounter;
