import { isEmpty } from "ramda";

export const cleanFilters = <T extends object>(obj: T) => {
  const nonEmptyFilters: Record<string, string> = {};
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined || value !== null || value !== "") {
      //commented as bqfields parameter has been removed in favor of OpCo to retrieve user selectedFields
      // if (key === "bqfields") {
      //   nonEmptyFilters[key] = JSON.stringify(value);
      //   return;
      // }
      if (key === "colFilters") {//convert to base64 the json string
        nonEmptyFilters[key] = window.btoa(JSON.stringify(value));
        return;
      }
      nonEmptyFilters[key] = value;
    }
  });
  return isEmpty(nonEmptyFilters) ? undefined : nonEmptyFilters;
};
