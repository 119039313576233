import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { colors } from "../../common/colors";
import { matchRoutes, useLocation } from "react-router";
import { routesArray } from "../../lib/routes";

const Wrapper = styled.div`
  border-radius: 10px;
  display: flex;
  align-content: center;
  margin: 0 auto;
  min-height: 200px;
  color: ${colors.textWhite};
  backdrop-filter: blur(16px) saturate(180%);
  background-color: ${colors.brightRed};
  border-radius: 20px;
  margin: 125px 50px 0 50px;
`;

const ErrTitle = styled.div`
  align-content: center;
  margin: 0 auto;
  font-weight: 500;
  color: ${colors.textWhite};
  font-size: 24px;
  margin: 20px 0px 20px 0px;
  padding-left: 20px;
`;

const Message = styled.div`
  padding-left: 20px;
`;

const NotFoundPage = () => {
  const location = useLocation();
  const requestedRouteExists = matchRoutes(routesArray, location);

  return (
    <Wrapper>
      <Box>
        <ErrTitle>
          {requestedRouteExists
            ? "You are not authorized to access this page."
            : "Page could not be found."}
        </ErrTitle>
        <Message>Please check the url try again</Message>
      </Box>
    </Wrapper>
  );
};

export default NotFoundPage;
