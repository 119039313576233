// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  MeasurementsGraphDataType,
  MeasurementsTableDataType,
} from "../../../components/measurements/table-types";
import {
  ServiceDataAllTablesResponse,
  ServiceDataResponse,
} from "../../../components/services-data/table-data-types";
import {
  BundleDetailsResponseData,
  CdrsResponseData,
  EdrsResponseData,
  ProfileInfoTableData,
} from "../../../components/subscriber-profiles-cdr/table-types";
import {
  GetCdrEdrFilters,
  GetLogsData,
  GetMeasurementsData,
  GetMeasurementsTableKPIData,
  GetRTQParams,
  GetServicesData,
  GetSubscribersFilter,
  GetUserAuthorizationdata,
} from "../../../components/subscriber-profiles-cdr/types";
import { getToken } from "../../token-utils";
import { CreateExport, EditExport, Export, PreviousExport } from "../../types/export-types";
import { Logs } from "../../types/logs-types";
import { CreateRole, Role } from "../../types/role-types";
import { CreateUser, EditUser, LoginResponse, User } from "../../types/user-types";
import { cleanFilters } from "./utils";
import { getMarketFromSession } from "../../../components/shared/markets";
import { saveUserBQFieldsBody, GetAllBqFieldsResponse, GetUserBQFieldsResponse } from "../../types/bqfields-types";
// Define a service using a base URL and expected endpoints
export const symphonyApi = createApi({
  reducerPath: "symphonyApi",
  tagTypes: [
    "Authanticate",
    "User",
    "Export",
    "Role",
    "ServiceData",
    "FullServiceData",
    "ServiceDataAllTables",
    "SubscriberProfile",
    "MeasurementsData",
    "MeasurementsTableData",
    "DownloadExportData",
    "LogsData",
    "FullLogsData",
    "UserAuthorizationData",
    "GetLogsIngestion",
    "GetExport",
    "PreviousExport",
    "DeleteUser",
    "FullSubscriberProfile",
    "FullCdrs",
    "FullEdrs",
    "ExportQueries",
    "BundleDetails",
    "GetEdrs",
    "GetCdrs",
    "FullBundleDetails",
    "AllBQFields",
    "UserSelectedBQFields",
    "GetEdrs",
    "GetCdrs",
    "GetRealtimeSubscriberData"
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getToken();
      const selectedMarket = getMarketFromSession();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      //UK Onboarding user selected market (italy, united-kingdom...)
      headers.set("Market", selectedMarket);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    userList: builder.query<{ data: User[] }, void>({
      query: () => "/user",
      providesTags: ["User"],
    }),

    roleList: builder.query<{ data: Role[] }, void>({
      query: () => "/role",
      providesTags: ["Role"],
    }),

    exportList: builder.query<{ data: Export[] }, void>({
      query: () => "/export",
      providesTags: ["Export"],
    }),

    createUser: builder.mutation<{ data: User }, CreateUser>({
      query: (user) => ({
        url: "/user",
        method: "POST",
        body: user,
      }),
      invalidatesTags: ["User"],
    }),

    authenticate: builder.mutation<LoginResponse, { email: string; password: string }>({
      query: ({ email, password }) => ({
        url: "/auth",
        method: "POST",
        body: {
          email,
          password,
        },
      }),
    }),

    updateUser: builder.mutation<{ data: User }, { id: number; action: "activate" | "deactivate" }>(
      {
        query: ({ id, action }) => ({
          url: `user/${action}`,
          method: "PUT",
          body: { userId: id },
        }),
        invalidatesTags: ["User"],
      },
    ),

    createExport: builder.mutation<{ data: Export }, CreateExport>({
      query: (exports) => ({
        url: "/export",
        method: "POST",
        body: exports,
      }),
      invalidatesTags: ["Export"],
    }),

    downloadExport: builder.query({
      query: (params) => ({
        url: `export/${params["id"]}/download`,
        method: "GET",
      }),
      providesTags: ["DownloadExportData"],
    }),

    exportGenerationActions: builder.mutation<
      { data: Export },
      { id: string; action: "running" | "stopped" | "paused" }
    >({
      query: ({ id, action }) => ({
        url: `export/${id}/${action}`,
        method: "PUT",
        body: {},
      }),
      invalidatesTags: ["Export"],
    }),

    getSubscribers: builder.query<{data: ProfileInfoTableData},GetSubscribersFilter>({
      query: (params) => ({
        url: `/subscribers`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["SubscriberProfile"],
    }),

    getRealTimeSubscriberData: builder.query<{data: ProfileInfoTableData},GetRTQParams>({
      query: (params) => ({
        url: `/realtimequery/getData`,
        method: "GET",
        params: cleanFilters({MSISDN:params.MSISDN, OPCO:params.OPCO}),
      }),
      providesTags: ["GetRealtimeSubscriberData"],
    }),

    getFullSubscribers: builder.query<{ data: ProfileInfoTableData }, GetSubscribersFilter>({
      query: (params) => ({
        url: `/subscribers`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["FullSubscriberProfile"],
    }),

    getFullCdrs: builder.query<{ data: CdrsResponseData }, GetCdrEdrFilters>({
      query: (params) => ({
        url: `/subscribers/cdrs`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["FullCdrs"],
    }),

    getFullEdrs: builder.query<{ data: EdrsResponseData }, GetCdrEdrFilters>({
      query: (params) => ({
        url: `/subscribers/edrs`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["FullEdrs"],
    }),
    getCdrs: builder.query<{ data: CdrsResponseData }, GetCdrEdrFilters>({
      query: (params) => ({
        url: `/subscribers/cdrs`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["GetCdrs"],
    }),

    getEdrs: builder.query<{ data: EdrsResponseData }, GetCdrEdrFilters>({
      query: (params) => ({
        url: `/subscribers/edrs`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["GetEdrs"],
    }),
    getBundleDetails: builder.query<BundleDetailsResponseData, GetSubscribersFilter>({
      query: (params) => ({
        url: `/bundleDetails`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["BundleDetails"],
    }),

    getFullBundleDetails: builder.query<BundleDetailsResponseData, GetSubscribersFilter>({
      query: (params) => ({
        url: `/bundleDetails`,
        method: "GET",
        params: cleanFilters(params),
      }),
      providesTags: ["FullBundleDetails"],
    }),

    getServiceDataAllTables: builder.query<
      ServiceDataAllTablesResponse,
      Omit<GetServicesData, "table">
    >({
      query: (params) => ({
        url: `/servicedata`,
        method: "GET",
        params: cleanFilters({ ...params, table: "all" }),
      }),
      providesTags: ["ServiceDataAllTables"],
    }),

    getServiceData: builder.query<ServiceDataResponse, GetServicesData>({
      query: ({ table = "", from = "", to = "", perPage = "", pageNo = "", ...paramRest }) => ({
        url: `/servicedata`,
        method: "GET",
        params: cleanFilters({ table, from, to, perPage, pageNo, ...paramRest }),
      }),
      providesTags: ["ServiceData"],
    }),
    getFullServiceData: builder.mutation<ServiceDataResponse, GetServicesData>({
      query: ({ table = "", from = "", to = "", perPage = "", pageNo = "", ...paramRest }) => ({
        url: `/servicedata`,
        method: "GET",
        params: cleanFilters({ table, from, to, perPage, pageNo, ...paramRest }),
      }),
      invalidatesTags: ["FullServiceData"],
    }),

    getMeasurementsTableData: builder.query<MeasurementsTableDataType, GetMeasurementsTableKPIData>(
      {
        query: ({ from, to, ...paramRest }) => ({
          url: `/measurements/tables`,
          method: "GET",
          params: cleanFilters({ from, to, ...paramRest }),
        }),
        providesTags: ["MeasurementsTableData"],
      },
    ),

    getMeasurementsData: builder.query<MeasurementsGraphDataType, GetMeasurementsData>({
      query: ({ tables, from, to, ...paramRest }) => ({
        url: `/measurements`,
        method: "POST",
        body: {
          tables,
          from,
          to,
        },
      }),
      providesTags: ["MeasurementsData"],
    }),

    getLogsData: builder.query<{ data: Logs[]; total: number }, GetLogsData>({
      query: ({
        searchKeyword = "",
        from = "",
        to = "",
        perPage = "",
        pageNo = "",
        ...paramRest
      }) => ({
        url: `/logs`,
        method: "GET",
        params: cleanFilters({ searchKeyword, from, to, perPage, pageNo, ...paramRest }),
      }),
      providesTags: ["LogsData"],
    }),
    getFullLogsData: builder.mutation<{ data: Logs[] }, GetLogsData>({
      query: ({
        searchKeyword = "",
        from = "",
        to = "",
        perPage = "",
        pageNo = "",
        ...paramRest
      }) => ({
        url: `/logs`,
        method: "GET",
        params: cleanFilters({ searchKeyword, from, to, perPage, pageNo, ...paramRest }),
      }),
      invalidatesTags: ["FullLogsData"],
    }),
    getUserAuthorizationdata: builder.query<
      GetUserAuthorizationdata,
      Partial<GetUserAuthorizationdata>
    >({
      query: () => ({
        url: `/user/permissions`,
        method: "GET",
      }),
      providesTags: ["UserAuthorizationData"],
    }),

    getLogsIngestion: builder.query({
      query: () => ({
        url: `/logs/ingestion`,
        method: "GET",
      }),
      providesTags: ["GetLogsIngestion"],
    }),
    editExport: builder.mutation<{ data: EditExport }, EditExport>({
      query: (editedExport) => ({
        url: `export/${editedExport.id}/basicInfo`,
        method: "PUT",
        body: editedExport,
      }),
      invalidatesTags: ({ data }) => ["Export", { type: "GetExport", id: data.id }],
    }),

    getExport: builder.query<{ data: Export }, string>({
      query: (id) => ({
        url: `/export/${id}/details`,
        method: "GET",
      }),
      providesTags: ({ data }) => [{ type: "GetExport", id: data.id }],
    }),

    getPreviousExport: builder.query<PreviousExport, string>({
      query: (id) => ({
        url: `/export/${id}/logs`,
        method: "GET",
      }),
    }),

    getUser: builder.query<{ data: User }, string>({
      query: (id) => ({
        url: `/user/${id}/details`,
        method: "GET",
      }),
      providesTags: ["User"],
    }),

    deleteUser: builder.mutation<any, number>({
      query: (id) => ({
        url: `/user/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["User", "DeleteUser"],
    }),

    createRole: builder.mutation<{ data: Role }, Partial<CreateRole>>({
      query: (role) => ({
        url: "/role",
        method: "POST",
        body: { ...role },
      }),
      invalidatesTags: ["Role"],
    }),

    editUser: builder.mutation<EditUser, Partial<User>>({
      query: (editUser) => ({
        url: `user/basicInfo`,
        method: "PUT",
        body: {
          userId: editUser.id,
          localMarket: editUser.localMarket,
          roleId: editUser.roleId.map((user) => {
            if (typeof user === "object") {
              return user.id;
            } else {
              return user;
            }
          }),
          status: editUser.status,
          isAdmin: editUser.isAdmin,
          description: editUser.description,
        },
      }),
      invalidatesTags: ["User", "Role"],
    }),

    editRole: builder.mutation<{ data: Role }, Partial<Role>>({
      query: (role) => ({
        url: `role/${role.id}/basicInfo`,
        method: "PUT",
        body: {
          localMarket: role.localMarket,
          actions: role.actions,
          description: role.description,
          name: role.name,
        },
      }),
      invalidatesTags: ["Role"],
    }),

    deleteRole: builder.mutation<any, number>({
      query: (id) => ({
        url: `/role/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Role"],
    }),

    getRole: builder.query<{ data: Role }, string>({
      query: (id) => ({
        url: `/role/${id}/details`,
        method: "GET",
      }),
      providesTags: ["Role"],
    }),
    exportQueries: builder.query({
      query: () => ({
        url: `export/queries`,
        method: "GET",
      }),
      providesTags: ["ExportQueries"],
    }),
    getUserSelectedBQFields: builder.query<GetUserBQFieldsResponse, void>({
      query: () => ({
        url: "/bqfields/all-selected-fields",
        method: "GET",
      }),
      providesTags: ["UserSelectedBQFields"],
      keepUnusedDataFor: 3600, //1hr
    }),
    getAllBQFields: builder.query<GetAllBqFieldsResponse, void>({
      query: () => ({
        url: "/bqfields",
        method: "GET",
      }),
      providesTags: ["AllBQFields"],
      keepUnusedDataFor: 3600, //1hr
    }),
    saveUserSelectedBQFields: builder.mutation<any, saveUserBQFieldsBody>({
      query: (data:saveUserBQFieldsBody) => ({
        url: "/bqfields/add-user-selected",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["UserSelectedBQFields", "SubscriberProfile", "FullCdrs","FullEdrs", 
      "ServiceData", "FullServiceData","GetEdrs","GetCdrs", "GetRealtimeSubscriberData"],
    }),
  }),
});

export const {
  useUserListQuery,
  useRoleListQuery,
  useExportListQuery,
  useCreateExportMutation,
  useCreateUserMutation,
  useDownloadExportQuery,
  useLazyDownloadExportQuery,
  useEditExportMutation,
  useGetPreviousExportQuery,
  useExportGenerationActionsMutation,
  useGetExportQuery,
  useUpdateUserMutation,
  useCreateRoleMutation,
  useGetServiceDataQuery,
  useGetFullServiceDataMutation,
  useGetSubscribersQuery,
  useGetRealTimeSubscriberDataQuery,
  useLazyGetFullSubscribersQuery,
  useLazyGetFullCdrsQuery,
  useLazyGetFullEdrsQuery,
  useLazyGetCdrsQuery,
  useLazyGetEdrsQuery,
  useLazyGetBundleDetailsQuery,
  useLazyGetFullBundleDetailsQuery,
  useGetMeasurementsDataQuery,
  useGetMeasurementsTableDataQuery,
  useLazyGetMeasurementsTableDataQuery,
  useGetLogsDataQuery,
  useGetFullLogsDataMutation,
  useGetUserAuthorizationdataQuery,
  useGetLogsIngestionQuery,
  useEditUserMutation,
  useGetUserQuery,
  useEditRoleMutation,
  useGetRoleQuery,
  useDeleteUserMutation,
  useDeleteRoleMutation,
  useAuthenticateMutation,
  useGetServiceDataAllTablesQuery,
  useExportQueriesQuery,
  useGetAllBQFieldsQuery,
  useGetUserSelectedBQFieldsQuery,
  useSaveUserSelectedBQFieldsMutation,
} = symphonyApi;