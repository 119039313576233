import { Constants } from "../../lib/constants";
export const localMarketDictionary = {
  italy: "VF-IT",
  uk: "VF-UK",
};

export interface marketOption {
  value: string; //it will be toLower() to send to BE / mySQL
  text: string; //capitalized for UI
  opco: string; //will be useful when we change value sent to BE
}

/**
 * returns localMarketDictionary value (VF-IT / VF-UK...)
 * @returns {string}
 * @param {string} market - italy, uk...
 * @see client\src\components\shared\markets.ts
 */
export function retrieveOpCoFromMarket(market: string): string {
  if (!market || market.length === 0) return "";

  return localMarketDictionary[market.toLowerCase()];
}

export function getMarketFromSession(): string {
  const market: string = sessionStorage.getItem(Constants.SESSION_STORAGE_SELECTED_MARKET);

  if (!market || market === "") return "";

  return market;
}

/**
 * returns dynamic options for market HTML selects based on const localMarketDictionary
 * @returns {marketOption[]}
 * @see client\src\components\shared\markets.ts
 */
export function retrieveMarketSelectOptions(): marketOption[] {
  const options: marketOption[] = [];

  Object.keys(localMarketDictionary).forEach((market) => {
    const capitaLizedMarket: string = market[0].toLocaleUpperCase() + market.slice(1);

    options.push({ value: market, text: capitaLizedMarket, opco: localMarketDictionary[market] });
  });

  return options;
}
