const DeleteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Group_483" data-name="Group 483" transform="translate(-1174 -311)">
      <circle
        id="Ellipse_43"
        data-name="Ellipse 43"
        cx="14"
        cy="14"
        r="14"
        transform="translate(1174 311)"
        fill="#e60000"
      />
      <g id="noun_Delete_4375733" transform="translate(1179.484 317.5)">
        <g id="Group_481" data-name="Group 481" transform="translate(4.516 2.5)">
          <path
            id="Path_327"
            data-name="Path 327"
            d="M11.75,3.5H9.963V3.25a.75.75,0,0,0-.75-.75H7.8a.75.75,0,0,0-.75.75V3.5H5.266a.75.75,0,0,0-.75.75v.445a.75.75,0,0,0,.4.66V11.25a1.25,1.25,0,0,0,1.25,1.25h4.681a1.25,1.25,0,0,0,1.25-1.25V5.355a.75.75,0,0,0,.4-.66V4.25a.75.75,0,0,0-.747-.75Zm-4.2-.25A.25.25,0,0,1,7.8,3h1.41a.25.25,0,0,1,.25.25V3.5H7.553Zm-2.537,1A.25.25,0,0,1,5.266,4H11.75a.25.25,0,0,1,.25.25v.445a.25.25,0,0,1-.25.25H5.266a.25.25,0,0,1-.25-.25ZM10.848,12H6.167a.75.75,0,0,1-.75-.75v-5.8H11.6v5.8A.75.75,0,0,1,10.848,12ZM8.758,6.75v3.94a.25.25,0,0,1-.5,0V6.75a.25.25,0,0,1,.5,0Zm1.8,0v3.94a.25.25,0,1,1-.5,0V6.75a.25.25,0,1,1,.5,0Zm-3.6,0v3.94a.25.25,0,1,1-.5,0V6.75a.25.25,0,1,1,.5,0Z"
            transform="translate(-4.516 -2.5)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DeleteIcon;
