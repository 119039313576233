import styled from "@emotion/styled";
import { TableCell, TableRow, TextField } from "@mui/material";
import { debounce } from "lodash";
import { pick } from "ramda";
import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from "react";

interface TableColumnFilterRowProps {
  columns: string[];
  onChange: (columnValues: Record<string, string>) => void;
  charsToTriggerOnChange: number;
  columnFilterValues?:any;
}

const SearchTextField = styled(TextField)`
  text-align: center;
  align-items: center;
  display:flex;
  min-width: 130px;
`;

const TableColumnFilterRow: FunctionComponent<TableColumnFilterRowProps> = ({
  columns,
  onChange,
  charsToTriggerOnChange,
  columnFilterValues,
}) => {
  const columnsString = JSON.stringify(columns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoColumns = useMemo(() => columns, [columnsString]);
  const [columnValues, setColumnValues] = useState<Record<string, string>>(() => {
    const initialColumnValues: Record<string, string> = {};
    for (const col of columns) {
      initialColumnValues[col] = "";
    }
    return initialColumnValues;
  });

  //debounced function which sets state, is triggered after user stops typing, and starts the onChange fn from props in useEffect
  const setColumnsAfterTyping = debounce((column, value) => {
    setColumnValues({ ...columnValues, [column]: value });
    onChange({ ...columnValues, [column]: value })
  }, 800);

  // Event listener called on every change
  const onChangeText = (colName: string) => (e: ChangeEvent<HTMLInputElement>) => {
    //update state if there are 0 or more than the minChars passed
    const triggerSetState: boolean =
      e.target.value.trim().length === 0 || e.target.value.trim().length >= charsToTriggerOnChange;
    if (triggerSetState) setColumnsAfterTyping(colName, e.target.value.trim());
  };

  useEffect(() => {
    setColumnValues((prevColumnValues) => {
      const obj = pick(memoColumns, prevColumnValues);
      for (const col of memoColumns) {
        const colIsNotInObjectAsKey = !(col in prevColumnValues);
        if (colIsNotInObjectAsKey) {
          obj[col] = "";
        }
      }
      return obj;
    });
  }, [memoColumns]);

  return (
    <TableRow>
      {memoColumns?.map((colName,key) => (
        <TableCell key={colName}>
          <SearchTextField
            placeholder="search here"
            onChange={onChangeText(colName)}
            id={`txtColumnFilter_${colName}`}
          //to show the previous filter values when duplicated the page
           defaultValue={columnFilterValues[colName]}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableColumnFilterRow;
