import styled from "@emotion/styled";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { Loading } from "../components/shared/loading";
import React from "react";
import { calculateArrayMegabytes } from "../lib/utils";

const ModalHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const style = {
  position: "absolute",
  overflow: "scroll",
  border: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};
const CloseModalButton = styled(Button)`
  background: none;
  color: black;
  font-weight: 700;
  font-size: 22px;
`;

const FooterButton = styled(Button)`
  background: #e60a17;
  border-radius: 34px;
  height: 40px;
  min-width: 180px;
  text-transform: none;
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
`;

const DetailedJSON = ({
  data,
  tableName,
  subTableName,
  fetchDataOnOpenPromise,
  isLoadingData,
}: {
  data: any[];
  tableName: string;
  subTableName?: string;
  fetchDataOnOpenPromise?: (filterResult?: boolean) => Promise<any[]>;
  isLoadingData?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [promiseData, setPromiseData] = useState<any>(null);

  const handleOpen = async () => {
    setIsOpen(true);

    if (fetchDataOnOpenPromise) {
      const responseData = await fetchDataOnOpenPromise(false);
      setPromiseData(responseData)  
    }
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  //tk73095, fullDataFetch in detailedJson
  const dataToConvert: any[] = promiseData != null ? promiseData : data;

  // Parse the sub table stringified object property
  const convertedData = dataToConvert && dataToConvert.map((row) => {
    //tk 84423
    //for some tables the response data structure for services data is sligthly different after FIBQ implementation
    // example kafka table response is like: data:[{kafkaNotificationServer:{dump_node, dump_timestamp, kafkaNotificationServer:"{gcpJson}"}}]
    //in others like CAOC is: data:[{Dump_node,Dump_Timestamp, CAOC:"{gcpJson}"}]
    if (row && row[subTableName] && row[subTableName][subTableName])
      return {
        ...row,
        [subTableName]:
          row[subTableName][subTableName] && JSON.parse(row[subTableName][subTableName]),
      };

    //default structure
    return { ...row, [subTableName]: row[subTableName] && JSON.parse(row[subTableName]) };
  });

  //tk73095, fullDataFetch in detailedJson, when data is too big the jsonviewer hangs, limited nesting in this case 
  const arraySizeTooBig:boolean = calculateArrayMegabytes(convertedData) > 5;

  return (
    <div>
      <FooterButton variant="contained" onClick={handleOpen}>
        Detailed JSON
      </FooterButton>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <ModalHeadWrapper>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={700}>
              {tableName} detailed JSON
            </Typography>
            <CloseModalButton onClick={handleClose}>X</CloseModalButton>
          </ModalHeadWrapper>

          {isLoadingData || !convertedData ? (
            <Loading />
          ) : (
            <React.Fragment>

              {!arraySizeTooBig ? (
                <JsonView
                  shouldExpandNode={allExpanded}
                  style={defaultStyles}
                  data={convertedData}
                />
              ) : (
                <React.Fragment>
                  <Typography variant="subtitle2" fontWeight={500}>
                    Dataset too big to fully display. Deeply nested objects are collapsed, use
                    export function if needed.
                  </Typography>
                  <JsonView
                    shouldExpandNode={(level) => level <= 2}
                    style={defaultStyles}
                    data={convertedData}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default DetailedJSON;
