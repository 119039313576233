import styled from "@emotion/styled";
import { colors } from "../../common/colors";
import BackArrowIcon from "../../icons/back-arrow-icon";
import { getRecordElementFormattedValue } from "./utils";

const Wrapper = styled.div`
  display:-webkit-box;
  width: 98%;
`;

const HeaderCell = styled.div`
  height: 40px;
  text-align: left;
  padding-left: 25px;
  &:last-child,
  &:first-child {
    border-radius: 34px;
  }
`;

const HeaderWrapper = styled.div`
  width: 250px;
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite}; 
`;

const InformationColumn = styled.div`
  background: ${colors.lightGreyBackground};
  padding-left: 25px;
`;

const InformationColumnWrapper = styled.div`
  background: lightgrey;
  overflow-wrap: break-word;
  max-width:85%;
  width: 100%;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${colors.lightBlackSubtitle};
  font: normal 14px Raleway;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const SingleRowDisplayer = ({
  cols,
  onBackClick,
}: {
  cols: { header: string; value: string | number | string[] }[];
  onBackClick: () => void;
}) => {
  const columns = cols.map(({ header, value }) => {
    let formattedValue = getRecordElementFormattedValue(value, header);
    return (
      <Wrapper key={`${header}-${value}`}>
        <HeaderWrapper>{value !== undefined && value !== null && <HeaderCell>{header}</HeaderCell>}</HeaderWrapper>
        <InformationColumnWrapper>
          <InformationColumn>{formattedValue}</InformationColumn>
        </InformationColumnWrapper>
      </Wrapper>
    );
  });

  return (
    <div>
      <ButtonRow>
        <BackButton onClick={onBackClick}>
          <BackArrowIcon /> Back
        </BackButton>
      </ButtonRow>
      {columns}
    </div>
  );
};
