import { configureStore } from "@reduxjs/toolkit";
import { symphonyApi } from "./services/symphony";
import authenticationReducer from "./slices/authentication-slice";
import logsReducer from "./slices/logs-slice";
import servicesDataReducer from "./slices/services-data-slice";
import lastCdrEdrReducer from "./slices/last-cdredr-slice";
import marketSelectionReducer from "./slices/market-slice";
import measurementsReducer from "./slices/measurements-slice";

// ...

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    [symphonyApi.reducerPath]: symphonyApi.reducer,
    logs: logsReducer,
    servicesData: servicesDataReducer,
    lastCdrEdr: lastCdrEdrReducer,
    market: marketSelectionReducer,
    measurements:measurementsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(symphonyApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
