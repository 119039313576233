import styled from "@emotion/styled";
import { Button, ButtonProps, TableCell, TableRow } from "@mui/material";
import { colors } from "../../common/colors";
import { ctrInsMap } from "./types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const EvenTableCell = styled(TableCell)`
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.open {
    white-space: normal;
    overflow: auto;
  }
  background: ${colors.fadedGreyBackground};
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 200px;
  :hover {
    cursor: pointer;
  }
`;

const OddTableCell = styled(TableCell)`
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.open {
    white-space: normal;
    overflow: auto;
  }
  text-align: center;
`;

const CustomCounterTableRow = ({
  cols,
  onClick,
}: {
  cols: any;
  onClick: (s: ctrInsMap) => void;
}) => {
  const [extendRow, setExtendRow] = useState(false);
  
 
  const handleExpandButtonClick: ButtonProps["onClick"] = (e) => {
    e.stopPropagation();
    setExtendRow((prevExtendRow) => !prevExtendRow);
  };

  const cellClassName = extendRow ? "open" : undefined;

  return (
    <>
      <TableRow
        onClick={() => onClick(cols)}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <OddTableCell className={cellClassName}>
          <Button onClick={handleExpandButtonClick} endIcon={<ExpandMoreIcon />}></Button>{" "}
          {cols.counterId}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>
          {cols.counterCurrentValue}
        </EvenTableCell>
        <EvenTableCell className={cellClassName}>{cols.counterInstanceId}</EvenTableCell>
        <OddTableCell className={cellClassName}>
          {cols.usageLimit}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>{cols.drnThrProfile}</EvenTableCell>
        <OddTableCell className={cellClassName}>{cols.lastUpdateTime}</OddTableCell>
        <EvenTableCell className={cellClassName}>
          {cols.lastResetTime}
        </EvenTableCell>
        <OddTableCell className={cellClassName}>{cols.defaultState}</OddTableCell>
        </TableRow>
    </>
  );
};

export default CustomCounterTableRow;
