import { MenuItem } from "@mui/material";
import { FunctionComponent } from "react";
import { useUserListQuery } from "../../lib/store/services/symphony";
import { RecipientDataDropdownForm } from "../../lib/types/export-types";
import { FormikInstance } from "../../lib/use-formik-return-type";
import CustomErrorMessage from "../custom-error-message";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { Loading } from "../shared/loading";

const RecipientsDataDropdown: FunctionComponent<{
  formik: FormikInstance<RecipientDataDropdownForm>;
  name: string;
}> = ({ formik }) => {
  const { data, isLoading, error } = useUserListQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <CustomErrorMessage error="Data can not be retreived." />;
  }

  return (
    <div>
      <FormikSelect multiple fullWidth name="recipients" formik={formik}>
        {data.data.map((r) => {
          return (
            <MenuItem key={r.id} value={r.id}>
              {r.email}
            </MenuItem>
          );
        })}
      </FormikSelect>
    </div>
  );
};

export default RecipientsDataDropdown;
