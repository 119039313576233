import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { adminSubroutes, routes } from "../../lib/routes";
import { useCreateRoleMutation } from "../../lib/store/services/symphony";
import { CreateRoleForm } from "../../lib/types/role-types";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { Page } from "../shared/Page";
import { retrieveMarketSelectOptions } from "../shared/markets";
import CustomErrorMessage from "../custom-error-message";

const AddNewRole = () => {
  const [createRole, { isLoading, data, error }] = useCreateRoleMutation();
  const navigate = useNavigate();
  const marketOptions = useMemo(() => {
    return retrieveMarketSelectOptions();
  }, []);

  // useEffect(() => {
  //   if (!isLoading && !error && data) {
  //     navigate(`${routes.admin}/${adminSubroutes.roles}`);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const formik = useFormik<Partial<CreateRoleForm>>({
    initialValues: {
      name: "",
      localMarket: "",
      actions: {
        create_role: false,
        edit_role: false,
        delete_role: false,
        view_role: false,
        create_export: false,
        view_export: false,
        edit_export: false,
        delete_export: false,
        show_subscribers: false,
        show_serviceData: false,
        show_logs: false,
        show_measurements: false,
        show_custom_query: false,
        create_user: false,
        edit_user: false,
        view_user: false,
        delete_user: false,
        valid_tables: [],
      },
      description: "",
    },
    validationSchema: yup.object({
      name: yup.string().max(30).required("Please input the new role"),
      localMarket: yup.string().required("Please select a local market"),
      actions: yup.object().shape({
        create_role: yup.boolean(),
        edit_role: yup.boolean(),
        delete_role: yup.boolean(),
        view_role: yup.boolean(),
        create_export: yup.boolean(),
        view_export: yup.boolean(),
        edit_export: yup.boolean(),
        delete_export: yup.boolean(),
        show_subscribers: yup.boolean(),
        show_serviceData: yup.boolean(),
        show_logs: yup.boolean(),
        show_measurements: yup.boolean(),
        show_custom_query: yup.boolean(),
        create_user: yup.boolean(),
        edit_user: yup.boolean(),
        view_user: yup.boolean(),
        delete_user: yup.boolean(),
        valid_tables: yup.array().of(yup.string()),
      }),
      description: yup.string().max(30),
    }),
    onSubmit: async (values) => {
     await createRole({ ...values, localMarket: [values.localMarket] })
      .then((result:any) => {
        if (result && !result.error)
        navigate(`${routes.admin}/${adminSubroutes.roles}`);
      });
      
    },
  });

  return (
    <Page title="Admin Console" subtitle="Create New Role" withBox>
      {error && (
        <CustomErrorMessage
          error={
            "Create role failed, data could not be saved." +
            (error["status"] === 403 ? error["data"]["details"] : "")
          }
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box m={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <FormikTextField fullWidth label="Role" name="name" formik={formik} />
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel id="local-market-label">Local market</InputLabel>
                <FormikSelect
                  labelId="local-market-label"
                  id="localMarket"
                  label="Local market"
                  name="localMarket"
                  formik={formik}
                >
                  {marketOptions.map((option) => (
                    <MenuItem value={option.value}>{option.text}</MenuItem>
                  ))}
                </FormikSelect>

                {formik.touched.localMarket && formik.errors.localMarket ? (
                  <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                    {formik.touched.localMarket && formik.errors.localMarket}
                  </FormHelperText>
                ) : null}
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormikTextField label="Description" name="description" multiline formik={formik} />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel component="legend">Export Access</FormLabel>
              </FormControl>
              <div>
                <FormControlLabel
                  label="View exports"
                  control={
                    <Checkbox
                      checked={formik.values.actions.view_export}
                      name="actions.view_export"
                      onChange={formik.handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Create Export"
                  control={
                    <Checkbox
                      checked={formik.values.actions.create_export}
                      name="actions.create_export"
                      onChange={formik.handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Edit Export"
                  control={
                    <Checkbox
                      value={formik.values.actions.edit_export}
                      checked={formik.values.actions.edit_export}
                      name="actions.edit_export"
                      onChange={formik.handleChange}
                    />
                  }
                />
                <FormControlLabel
                  label="Delete Export"
                  checked={formik.values.actions.delete_export}
                  control={<Checkbox onChange={formik.handleChange} name="actions.delete_export" />}
                />
              </div>

              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">User</FormLabel>
                <div>
                  <FormControlLabel
                    label="View user"
                    control={
                      <Checkbox
                        checked={formik.values.actions.view_user}
                        name="actions.view_user"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Create user"
                    control={
                      <Checkbox
                        checked={formik.values.actions.create_user}
                        name="actions.create_user"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Edit user"
                    control={
                      <Checkbox
                        checked={formik.values.actions.edit_user}
                        name="actions.edit_user"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Delete user"
                    control={
                      <Checkbox
                        checked={formik.values.actions.delete_user}
                        name="actions.delete_user"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </div>
              </FormControl>

              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Role</FormLabel>
                <div>
                  <FormControlLabel
                    label="Create role"
                    control={
                      <Checkbox
                        checked={formik.values.actions.create_role}
                        name="actions.create_role"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Edit role"
                    control={
                      <Checkbox
                        checked={formik.values.actions.edit_role}
                        name="actions.edit_role"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Delete role"
                    control={
                      <Checkbox
                        checked={formik.values.actions.delete_role}
                        name="actions.delete_role"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    label="View roles"
                    control={
                      <Checkbox
                        checked={formik.values.actions.view_role}
                        name="actions.view_role"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </div>
              </FormControl>

              <div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">Custom Query Access</FormLabel>
                  <FormControlLabel
                    label="Custom query"
                    control={
                      <Checkbox
                        checked={formik.values.actions.show_custom_query}
                        name="actions.show_custom_query"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">View Service data</FormLabel>
                  <FormControlLabel
                    label="Service data"
                    control={
                      <Checkbox
                        checked={formik.values.actions.show_serviceData}
                        name="actions.show_serviceData"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </FormControl>
              </div>

              <div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">View logs</FormLabel>
                  <FormControlLabel
                    label="Logs"
                    control={
                      <Checkbox
                        checked={formik.values.actions.show_logs}
                        name="actions.show_logs"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </FormControl>
              </div>

              <div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">View measurements</FormLabel>
                  <FormControlLabel
                    label="Measurements"
                    control={
                      <Checkbox
                        checked={formik.values.actions.show_measurements}
                        name="actions.show_measurements"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </FormControl>
              </div>

              <div>
                <FormControl component="fieldset" margin="normal" fullWidth>
                  <FormLabel component="legend">View subscribers</FormLabel>
                  <FormControlLabel
                    label="Subscribers"
                    control={
                      <Checkbox
                        checked={formik.values.actions.show_subscribers}
                        name="actions.show_subscribers"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </FormControl>
              </div>
            </Grid>
          </Grid>
          <FormControl>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Create
            </LoadingButton>
          </FormControl>
        </Box>
      </form>
    </Page>
  );
};

export default AddNewRole;
