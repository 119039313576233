import styled from "@emotion/styled";
import { Button, FormControl, InputLabel, MenuItem, TextField, Typography } from "@mui/material";
import { endOfDay, format, startOfDay } from "date-fns";
import { useFormik } from "formik";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import * as yup from "yup";
import { useLazyGetMeasurementsTableDataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { FormikDateTimePicker } from "../formik-fields/FormikDateTimePicker";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { Loading } from "../shared/loading";
import { GetLogsData, GetMeasurementsTableKPIData } from "../subscriber-profiles-cdr/types";
import MeasurementsCheckboxes from "./measurements-checkboxes";
import { MeasurementsTableDataType } from "./table-types";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { retrieveMarketSelectOptions } from "../shared/markets";
import { useSelector } from "react-redux";
import { RootState } from "../../lib/store/store";
import { selectLastMeasurementsSearch } from "../../lib/store/slices/measurements-slice";
import { colors } from "../../common/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 100%;
  margin-right: 25px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 7px;
  padding: 15px;
`;

const KpiTableList = styled.div`
  display: flex;
  flex-direction: column;
  width: 285px;
  margin-right: 25px;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 7px;
  padding: 15px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Devider = styled.hr`
  width: 100%;
`;

const DatePickerWrapper = styled.div`
  margin-bottom: 15px;
`;

const FilterButton = styled(Button)`
  margin-top: 15px;
  min-width: 150px;
`;

const SearchTextField = styled(TextField)`
  margin-top: 15px;
`;

const FromInputLabel = styled(InputLabel)`
  margin-top: 25px;
`;
const TitleLable = styled(InputLabel)`
  margin-bottom: 5px;
  margin-top: 15px;

  color: ${colors.lightBlackSubtitle};
  font-weight: 520;
  font: normal normal medium 14px/16px Raleway;
`;

const getFormattedDate = (dateString: string) => {
  return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
};

const MeasurementsKPIList: FunctionComponent<{
  handleChange: (name: string, value: boolean, children: string[]) => void;
  handleCheckChild: (parent: string, child: string, value: boolean) => void;
  checkedChildren: Record<string, Record<string, boolean>>;
  checked: Record<string, boolean>;
  state: {
    isUninitialized: boolean;
    isLoading?: boolean;
    isError?: boolean;
    data?: MeasurementsTableDataType;
  };
  dates: GetMeasurementsTableKPIData | undefined;
}> = ({
  state: { data, isUninitialized, isLoading, isError },
  checked,
  handleChange,
  handleCheckChild,
  checkedChildren,
  dates,
}) => {
  if (isUninitialized) {
    return <></>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    if (!dates.from || !dates.to) {
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;
    }

    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return (
    <KpiTableList>
      <Typography>KPIs</Typography>
      <SearchTextField placeholder="Search" size="small" variant="outlined" />
      <MeasurementsCheckboxes
        measurementsKpisTables={data}
        checked={checked}
        handleChange={handleChange}
        handleCheckChild={handleCheckChild}
        checkedChildren={checkedChildren}
      />
    </KpiTableList>
  );
};

const MeasurementsDatesForm: FunctionComponent<{
  onSearch: (value: GetMeasurementsTableKPIData) => void;
  filterWithToAndFrom: (filters: { from: string; to: string }) => void;
}> = ({ filterWithToAndFrom ,onSearch}) => {
  const marketOptions = useMemo(() => {
    return retrieveMarketSelectOptions();
  }, []);

  const searchDefaultValues: any =  {
          localMarket: "",
          from: startOfDay(new Date().setDate(new Date().getDate())).toString(),
          to: endOfDay(new Date().setDate(new Date().getDate())).toString(), 
        }  
  const searchLastMeasurements =  useSelector(selectLastMeasurementsSearch) ??  searchDefaultValues;

  const formik = useFormik({
    initialValues: {
      ...searchDefaultValues,
    },
    enableReinitialize:true,
    validationSchema: yup.object({
      from: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
      to: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
      localMarket: yup.string(),
    }),
    onSubmit: (values) => {
     let  { from, to } = values
      // Check if dates are valid before submitting
      const isFromDateValid = isDateTimeValid(from);
      const isToDateValid = isDateTimeValid(to);
      //isToDateValid ? getFormattedDate(to) : undefined;
      const FromDate = isFromDateValid ? getFormattedDate(from) : undefined;
      const ToDate = isToDateValid ? getFormattedDate(to) : undefined;
      filterWithToAndFrom({ from: FromDate, to: ToDate });
      onSearch(values);
      
    },
  });

  useEffect(() => {
    Object.entries(searchLastMeasurements).map(item=>{
      formik.setFieldValue(
        item[0],
          item[1],
        true,
      );
    })
  }, [])
  const handleResetForm = ()=>{
    localStorage.removeItem("searchMeasurements");
    onSearch(undefined)
    formik.initialValues = searchDefaultValues
    formik.resetForm({values:undefined})

  }


  return (
    <div>
      <Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <FormWrapper>
            <FromInputLabel>From</FromInputLabel>
            <FormControl>
              <DatePickerWrapper>
                <FormikDateTimePicker formik={formik} name="from" />
              </DatePickerWrapper>
            </FormControl>
            <InputLabel>To</InputLabel>
            <FormControl>
              <DatePickerWrapper>
                <FormikDateTimePicker formik={formik} name="to" />
              </DatePickerWrapper>
            </FormControl>
            <InputLabel>Local market</InputLabel>
            <FormControl>
              <FormikSelect size="small" formik={formik} name="localMarket">
                {marketOptions.map((option) => (
                  <MenuItem key = {option.opco} value={ option.opco.toLowerCase() }>{option.opco}</MenuItem>
                ))}
                {/* <MenuItem value="vf-it">VF-IT</MenuItem> */}
              </FormikSelect>
            </FormControl>
          </FormWrapper>
          <div>
            <TitleLable>Clear</TitleLable>
            <Button size="small" fullWidth type="reset"  onClick={ e => handleResetForm()} color="inherit" variant="contained">
            Clear 
            </Button>
          </div>
          <FilterButton type="submit" variant="contained">
            Filter
          </FilterButton>
          <Devider />
        </form>
      </Wrapper>
    </div>
  );
};

const MeasurementsForm: FunctionComponent<{
  onSearch: (value: GetMeasurementsTableKPIData) => void;
  handleChange: (name: string, value: boolean, children: string[]) => void;
  handleCheckChild: (parent: string, child: string, value: boolean) => void;
  setDates: (filters: GetMeasurementsTableKPIData) => void;
  checked: Record<string, boolean>;
  checkedChildren: Record<string, Record<string, boolean>>;
  dates: GetMeasurementsTableKPIData | undefined;
}> = ({onSearch, setDates, checked, handleChange, handleCheckChild, checkedChildren, dates, ...props }) => {
  const [triggerGet, state] = useLazyGetMeasurementsTableDataQuery({});


 
  const filterWithToAndFrom = (filters: GetMeasurementsTableKPIData) => {
    setDates(filters);
    triggerGet(filters);
  };


  return (
    <div>
      <MeasurementsDatesForm   onSearch = {onSearch} {...props} filterWithToAndFrom={filterWithToAndFrom} />
      <MeasurementsKPIList
        checked={checked}
        handleChange={handleChange}
        handleCheckChild={handleCheckChild}
        checkedChildren={checkedChildren}
        state={state}
        dates={dates}
      />
    </div>
  );
};

export default React.memo(MeasurementsForm);
