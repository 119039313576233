import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import CustomErrorMessage from "../../components/custom-error-message";
import { Loading } from "../../components/shared/loading";
import PauseIcon from "../../icons/pause-icon";
import PlayIcon from "../../icons/play-icon";
import StopIcon from "../../icons/stop-icon";
import { useExportGenerationActionsMutation } from "../store/services/symphony";

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ExportGenerationActionCell: FunctionComponent<{
  id: string;
  defaultValue: "running" | "paused" | "stopped";
}> = ({ id }) => {
  const [updateGenerationActionCell, { error, isLoading }] = useExportGenerationActionsMutation();

  const handleStartClick = () => {
    updateGenerationActionCell({ id, action: "running" });
  };
  const handlePauseClick = () => {
    updateGenerationActionCell({ id, action: "paused" });
  };
  const handleStopClick = () => {
    updateGenerationActionCell({ id, action: "stopped" });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return (
    <IconWrapper>
      <Button onClick={handleStartClick}>
        <PlayIcon />
      </Button>
      <Button onClick={handlePauseClick}>
        <PauseIcon />
      </Button>
      <Button onClick={handleStopClick}>
        <StopIcon />
      </Button>
    </IconWrapper>
  );
};

export default ExportGenerationActionCell;
