import { FunctionComponent, ReactNode, Fragment } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Subtitle } from "./subtitle";

const ActionButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const PageContainer = styled.div`
  padding-top: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

const BackButtonContainer = styled.div`
  margin-right: 18px;
`;

const PageBox = styled(Box)`
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 23px;
  padding: 32px;
`;

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <BackButtonContainer>
      <IconButton onClick={() => navigate(-1)} size="large">
        <ArrowBackIcon />
      </IconButton>
    </BackButtonContainer>
  );
};

export const Page: FunctionComponent<{
  title: string;
  actionButton?: JSX.Element;
  children: ReactNode;
  subtitle?: string;
  withBox?: boolean;
}> = ({ title, children, actionButton, withBox, subtitle }) => {
  const Wrapper = withBox ? PageBox : Fragment;
  return (
    <div style={{ marginTop: 20, marginLeft: 20 }}>
      <TitleContainer>
        <BackButton />
        <Typography variant="h4">{title}</Typography>
        {actionButton && <ActionButton>{actionButton}</ActionButton>}
      </TitleContainer>
      <Divider />
      <PageContainer>
        <Wrapper>
          {subtitle && (
            <Subtitle style={{ marginBottom: 10 }} variant="h5">
              {subtitle}
            </Subtitle>
          )}
          {children}
        </Wrapper>
      </PageContainer>
    </div>
  );
};
