import styled from "@emotion/styled";
import RemoveIcon from "@mui/icons-material/Remove";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Stack,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { routes } from "../../lib/routes";
import {
  useEditUserMutation,
  useGetUserQuery,
  useRoleListQuery,
} from "../../lib/store/services/symphony";
import { Role } from "../../lib/types/role-types";
import { EditUser, User } from "../../lib/types/user-types";
import CustomErrorMessage from "../custom-error-message";
import { FormikRadioGroup } from "../formik-fields/FormikRadioGroup";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { Page } from "../shared/Page";
import { Loading } from "../shared/loading";
import { retrieveMarketSelectOptions } from "../shared/markets";

const SaveChangesButton = styled(LoadingButton)`
  display: flex;
  flex-direction: row-reverse;
`;

const EditUserComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useGetUserQuery(id);
  const {
    isError: isErrorRoleList,
    isLoading: isLoadingRoleList,
    data: roleListData,
  } = useRoleListQuery();

  if (isLoading || isLoadingRoleList) {
    return <Loading />;
  }

  if (error || isErrorRoleList || !roleListData || !data) {
    return <CustomErrorMessage error="Data can not be loaded" />;
  }

  //rolelist is based on current user local markets, if he's editing a user which has set roles with other local markets,
  //those don't appear in the roles selection, algo below is to make sure the user's roles are in the rolesList select options
  const roleList = [...roleListData.data];
  const roleIds = new Map(roleList.map((obj) => [obj.id, obj]));

  for (const obj of data.data.roleId) {
    if (!roleIds.has(obj.id)) roleList.push(obj);
  }

  return <EditUserInner user={data.data} roleList={roleList} />;
};

const EditUserInner: FunctionComponent<{ user: User; roleList: Role[] }> = ({ user, roleList }) => {
  const navigate = useNavigate();
  const [editUser, { error, isLoading }] = useEditUserMutation();
  const marketOptions = useMemo(() => {
    return retrieveMarketSelectOptions();
  }, []);
  const roleNameList = user.roleId.map((u) => u.name).join(",");
  const formik = useFormik<EditUser>({
    initialValues: {
      email: user.email,
      localMarket: user.localMarket,
      isAdmin: user.isAdmin,
      description: user.description,
      status: user.status,
      id: user.id,
      roleId: user.roleId,
      roleName: user.roleName,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("The username should be an email")
        .matches(/^[aA-zZ\s]+./, "Only alphabets are allowed for this field ")
        .max(150)
        .required("Please input the user email"),
      localMarket: yup
        .array()
        .min(1, "Please select a local market")
        .required("Please select a local market"),
      isAdmin: yup.boolean().required(),
      description: yup.string().max(30),
      status: yup.string(),
      id: yup.number(),
      roleName: yup.string(),
      roleId: yup.array().min(1, "A minimum of one role is required").required(),
    }),
    onSubmit: async (values) => {
      await editUser({ ...values }).then((res: any) => {
        if (res && !res.error) navigate(routes.admin);
      });
    },
  });

  useEffect(() => {
    const roleIdList = user.roleId.map((u) => u.id);
    formik.setFieldValue("roleId", roleIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roleId]); // Update the dependency to only user.roleId to avoid infinit loop

  const handleRoleRemove = (roleIdToRemove) => {
    formik.setFieldValue(
      "roleId",
      formik.values.roleId.filter((roleId) => roleId !== roleIdToRemove),
    );
  };

  return (
    <Page title="Admin Console" withBox subtitle="Edit User">
      {error && (
        <CustomErrorMessage
          error={
            "Create user failed, data could not be saved." +
            (error["status"] === 403 ? error["data"]["details"] : "")
          }
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box m={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <FormikTextField fullWidth label="Username" name="email" formik={formik} disabled />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="local-market-label">Local Market</InputLabel>
                <FormikSelect
                  multiple
                  labelId="local-market-label"
                  id="localMarket"
                  label="Default Local market"
                  name="localMarket"
                  formik={formik}
                >
                  {marketOptions.map((option) => (
                    <MenuItem value={option.value}>{option.text}</MenuItem>
                  ))}
                </FormikSelect>
                {formik.touched.localMarket && formik.errors.localMarket ? (
                  <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                    {formik.touched.localMarket && formik.errors.localMarket}
                  </FormHelperText>
                ) : null}
                <FormHelperText>Default: {formik.values.localMarket[0]}</FormHelperText>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Roles</InputLabel>
                <FormikSelect
                  multiple
                  placeholder="Role"
                  labelId="role-label"
                  id="role-label"
                  label="Role"
                  name="roleId"
                  formik={formik}
                  value={formik.values.roleId}
                  renderValue={(selected: number[]) => (
                    <Stack spacing={1} direction={"row"}>
                      {selected.map((value) => {
                        return (
                          <Stack direction="row" spacing={1}>
                            <Typography>
                              {roleList.find((role) => role.id === value)?.name}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </Stack>
                  )}
                >
                  {roleList.map((r) => {
                    return (
                      <MenuItem key={r.id} value={r.id}>
                        <ListItemText>{r.name}</ListItemText>
                        <ListItemIcon>
                          <RemoveIcon onClick={() => handleRoleRemove(r.id)} />
                        </ListItemIcon>
                      </MenuItem>
                    );
                  })}
                </FormikSelect>
                {formik.touched.roleId && formik.errors.roleId ? (
                  <FormHelperText sx={{ color: "#bf3333", marginLeft: "16px !important" }}>
                    {formik.errors.roleId?.toString()}
                  </FormHelperText>
                ) : null}
                <FormHelperText>Roles: {user.roleId[0]?.name ? roleNameList : ""}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">User status</FormLabel>
                <FormikRadioGroup aria-label="user status" name="status" formik={formik} row>
                  <FormControlLabel
                    name="status"
                    value="active"
                    control={<Radio />}
                    label="Enable"
                  />
                  <FormControlLabel
                    name="status"
                    value="inactive"
                    control={<Radio />}
                    label="Disable"
                  />
                </FormikRadioGroup>
              </FormControl>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">System administration flag</FormLabel>
                <FormikRadioGroup
                  aria-label="system administration flag"
                  name="isAdmin"
                  formik={formik}
                  row
                >
                  <FormControlLabel value={true} control={<Radio />} label="Enable" />
                  <FormControlLabel value={false} control={<Radio />} label="Disable" />
                </FormikRadioGroup>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <FormikTextField label="Description" name="description" multiline formik={formik} />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl>
            <SaveChangesButton variant="contained" type="submit" loading={isLoading}>
              Save changes
            </SaveChangesButton>
          </FormControl>
        </Box>
      </form>
    </Page>
  );
};

export default EditUserComponent;
