import styled from "@emotion/styled";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import ButtonRouterLink from "../../common/button-router-link";
import { adminSubroutes, routes } from "../../lib/routes";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { useSelector } from "react-redux";

const AddUserButton = styled(Button)`
  background: #e60a17;
  border-radius: 34px;
  opacity: 1;
  height: 40px;
  width: 183px;
`;

const AddNewUserButton = () => {
  const { data, isLoading, error } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);
  
  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole = data?.data?.roles.find(
    (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
  );

  return currentRole && currentRole.create_user ? (
    <ButtonRouterLink to={`${routes.admin}/${adminSubroutes.addUser}`}>
      <AddUserButton variant="contained" startIcon={<Icon icon={plusFill} />}>
        Add New User
      </AddUserButton>
    </ButtonRouterLink>
  ) : (
    <AddUserButton disabled={true} variant="contained" startIcon={<Icon icon={plusFill} />}>
      Add New User
    </AddUserButton>
  );
};

export default AddNewUserButton;
