const LogoutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Group_92"
    data-name="Group 92"
    width="15.49"
    height="15.487"
    viewBox="0 0 15.49 15.487"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_111"
          data-name="Rectangle 111"
          width="15.49"
          height="15.487"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="Group_91" data-name="Group 91" clipPath="url(#clip-path)">
      <path
        id="Path_147"
        data-name="Path 147"
        d="M0,7.259c.039-.284.068-.569.118-.85A7.609,7.609,0,0,1,2.583,1.979,7.449,7.449,0,0,1,6.7.072a7.544,7.544,0,0,1,6.048,1.772,7.445,7.445,0,0,1,2.6,4.449A7.513,7.513,0,0,1,13.9,12.425a7.564,7.564,0,0,1-4.7,2.922c-.271.054-.548.075-.823.112-.049.007-.1.018-.146.027H7.26c-.189-.023-.378-.044-.567-.071A7.774,7.774,0,0,1,.121,9.092C.069,8.806.04,8.516,0,8.227V7.259m14.762.479a7.018,7.018,0,1,0-7.021,7.023,7.03,7.03,0,0,0,7.021-7.023"
        transform="translate(0 0)"
        fill="currentColor"
      />
      <path
        id="Path_148"
        data-name="Path 148"
        d="M72.946,90.413a4.262,4.262,0,0,1,3.73-4.222,4.136,4.136,0,0,1,3.536,1.2.366.366,0,0,1,.095.452.34.34,0,0,1-.394.18.667.667,0,0,1-.25-.158,3.485,3.485,0,0,0-2.569-.982,3.534,3.534,0,0,0-.372,7.03,3.446,3.446,0,0,0,2.922-.928.792.792,0,0,1,.258-.174.338.338,0,0,1,.4.168.361.361,0,0,1-.08.454,4.184,4.184,0,0,1-1.64,1.021A4.27,4.27,0,0,1,73,91.045c-.031-.209-.037-.421-.055-.632"
        transform="translate(-69.998 -82.672)"
        fill="currentColor"
      />
      <path
        id="Path_149"
        data-name="Path 149"
        d="M174.118,152.3c-.208-.2-.42-.4-.623-.612a.354.354,0,0,1,.026-.544.345.345,0,0,1,.469.022q.648.634,1.283,1.282a.341.341,0,0,1,0,.5q-.629.643-1.271,1.273a.362.362,0,1,1-.5-.524c.2-.2.4-.4.639-.638h-3.96a1.457,1.457,0,0,1-.151,0,.362.362,0,0,1,0-.72,1.483,1.483,0,0,1,.151,0H174.1l.02-.037"
        transform="translate(-162.847 -144.961)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default LogoutIcon;
