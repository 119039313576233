import styled from "@emotion/styled";
import { TableContainer, Typography } from "@mui/material";
import StatusTable from "../admin/status-table";
import { Loading } from "../shared/loading";
import CustomErrorMessage from "../custom-error-message";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";

const SectionTitle = styled(Typography)`
  margin: 15px 0 15px 0;
`;
const LatestFiles = () => {
  const { data, isLoading, error } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="User does not have permission to view ingestion" />;
  }
  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole = data?.data?.roles.find(
    (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
  );
  return (
    <div>
      {currentRole && currentRole.show_logs && (
        <TableContainer>
          <SectionTitle variant="h6">Latest Files</SectionTitle>
          <TableContainer>
            <StatusTable />
          </TableContainer>
        </TableContainer>
      )}
      {!currentRole && currentRole.show_logs && <div>You don't have permission to view logs.</div>}
    </div>
  );
};

export default LatestFiles;
