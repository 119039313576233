import React, { useEffect, useState } from "react";
import { Paper, Typography, Modal, Fade } from "@mui/material";
import { Navigate } from "react-router-dom";
import { routes } from "../../lib/routes";

interface NotAuthenticatedNoticeProps {
  originLocation: any;
  message: string;
}

const NotAuthenticatedNotice: React.FC<NotAuthenticatedNoticeProps> = ({ originLocation, message }) => {
  const [count, setCount] = useState(3); //3 seconds before automatic redirection to login

  const handleClose = () => {
    return <Navigate to={routes.login} state={{ from: originLocation }} replace />;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const countDownMessage =
    count >= 0 ? `You will be redirected to the login page in ${count} seconds` : handleClose();

  return (
    <Modal open={true} onClose={handleClose}>
      <Fade in={true}>
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            padding: "30px",
            backgroundColor: "#fff",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography variant="h4">Warning!</Typography>
          <Typography variant="h5" sx={{marginTop: '10px'}}>{message}</Typography>
          <Typography variant="h5" sx={{marginTop: '10px'}}>{countDownMessage}</Typography>

        </Paper>
      </Fade>
    </Modal>
  );
};

export default NotAuthenticatedNotice;