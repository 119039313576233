const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g id="noun_start_41125" transform="translate(-24 -24)">
      <path
        id="Path_478"
        data-name="Path 478"
        d="M33,24a9,9,0,1,0,9,9A9.026,9.026,0,0,0,33,24ZM30.638,36.863V29.25L37.2,33.038Z"
        transform="translate(0 0)"
        fill="#01860b"
      />
    </g>
  </svg>
);

export default PlayIcon;
