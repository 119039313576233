import styled from "@emotion/styled";
import { Button, FormControl, InputLabel, MenuItem } from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { colors } from "../../common/colors";
import { FormikDateTimePicker } from "../formik-fields/FormikDateTimePicker";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import {  ServiceDataFields } from "../subscriber-profiles-cdr/types";
import { inValues } from "./in-input-values";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { selectLastServiceSearch } from "../../lib/store/slices/services-data-slice";
import { FieldsInBqModal } from "../shared/fields-in-bq-modal";
import { FIBQConstants } from "../shared/FIBQ-utils";
import { clearTextInputsByCommonIdPrefix } from "../../lib/utils";

const Wrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
align-content: center;
& > div {
  margin-right: 20px;
}
`;

const TitleInputLabel = styled(InputLabel)`
  margin-bottom: 15px;
  font: normal normal medium 14px/16px Raleway;
`;

const SearchInputLabel = styled(InputLabel)`
margin-bottom: 15px;
color: ${colors.lightBlackSubtitle};
font-weight: 520;
font: normal normal medium 14px/16px Raleway;
`;

var lastSearchTable:string = "";

const ServicesDataForm = ({
  onSearch,
}: // onFieldsInBQChange,
{
  onSearch: (value: ServiceDataFields) => void;
  // onFieldsInBQChange: (fields: string[]) => void;
}) => {

  const searchDefaultValues: ServiceDataFields =  {
      searchFor: "",
      in: "",
      from: startOfDay(new Date().setDate(new Date().getDate() - 1)).toString(),
      to: endOfDay(new Date().setDate(new Date().getDate() - 1)).toString(),
      bqFieldsChangedCounter:0,
      pageNo: 0,
      perPage: 10,
      colFilters:{}
    };
 
  const searchResultField = useSelector(selectLastServiceSearch) ?? searchDefaultValues;
  const formik = useFormik({
    initialValues: {
      ...searchDefaultValues,
    },
    enableReinitialize:true,
    validationSchema: yup.object({
      searchFor: yup.string().required("Please fill in the search keyword"),
      in: yup.string().required(),
      from: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
      to: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
        bqFieldsChangedCounter: yup.number(),
    }),
    onSubmit: (val) => {
      const values = {...val};
      values.to = endOfDay(new Date(formik.values.from)).toString();

      //if table selection changed and we have previously applied column filters we reset them
      if (lastSearchTable !== values.in && Object.keys(searchResultField.colFilters).length > 0) {
        clearTextInputsByCommonIdPrefix("txtColumnFilter_");
        values.colFilters = {};
      }

      lastSearchTable = values.in;
      onSearch(values);
    },
  });

  useEffect(() => {
    Object.entries(searchResultField).map(item=>{
      formik.setFieldValue(
        item[0],
          item[1],
        true,
      );
    })
    lastSearchTable = searchResultField.in;

    if(searchResultField !== searchDefaultValues) onSearch(searchResultField);

  }, []);

  const handleResetForm = ()=>{
  onSearch(undefined);
  localStorage.removeItem("searchService");
    formik.resetForm()
  }

  const handleUpdateFIBQSelection = () => {
    formik.setFieldValue("bqFieldsChangedCounter", (formik.values.bqFieldsChangedCounter + 1));
    setTimeout(()=> formik.submitForm(), 1200);
    }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Wrapper>
          <div>
            <TitleInputLabel id="search">Search for</TitleInputLabel>
            <FormControl fullWidth>
              <FormikTextField
                style={{ width: 180, marginRight: 25 }}
                size="small"
                formik={formik}
                name="searchFor"
              />
            </FormControl>
          </div>
          <div>
            <TitleInputLabel>In</TitleInputLabel>
            <FormControl fullWidth>
              <FormikSelect
                style={{ width: 180, marginRight: 25 }}
                size="small"
                formik={formik}
                id="in"
                name="in"
              >
                {inValues.map((v) => (
                  <MenuItem key={v.name} value={v.name}>
                    {v.name}
                  </MenuItem>
                ))}
              </FormikSelect>
            </FormControl>
          </div>
          <div style={{ width: 180, marginRight: 25 }}>
            <TitleInputLabel>Date</TitleInputLabel>
            <FormControl fullWidth>
              <FormikDateTimePicker formik={formik} name="from" />
            </FormControl>
          </div>
          <div style={{marginRight: "20px"}}>
            <TitleInputLabel>Fields in BQ</TitleInputLabel>
            <FieldsInBqModal serviceDataTableSearched={formik.values.in} preselectedStreamTab={FIBQConstants.BQ_STREAM_SERVICE_DATA} 
            excludeStreams={[FIBQConstants.BQ_STREAM_SUBSCRIBER, FIBQConstants.BQ_STREAM_CDR, FIBQConstants.BQ_STREAM_EDR]}
            callbackUpdatedFIBQ={handleUpdateFIBQSelection}
            />
          </div>
          <div>
            <SearchInputLabel id="search">Search for</SearchInputLabel>

            <Button type={"submit"} variant="contained">
              Search
            </Button>
          </div>
          <div>
            <SearchInputLabel > Clear</SearchInputLabel>

            <Button type={"reset"} onClick={(e)=>{handleResetForm()}}  color="inherit" variant="contained">
            Clear
            </Button>
          </div>
        </Wrapper>
      </form>
    </div>
  );
};

 export default ServicesDataForm;
