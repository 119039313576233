import React, { useState } from 'react';
import styled from "@emotion/styled";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams } from "react-router";
import ButtonRouterLink from "../../common/button-router-link";
import { colors } from "../../common/colors";
import { exportsSubroutes, routes } from "../../lib/routes";
import { useGetPreviousExportQuery, useLazyDownloadExportQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Page } from "../shared/Page";
import { Loading } from "../shared/loading";
import { Link as RouterLink } from "react-router-dom";
import ViewExportIcon from "../../icons/view-export-icon";
import JSZip from 'jszip';
import gzip from 'gzip-js';

const UserTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const FirstTableCell = styled(TableCell)`
  border-radius: 20px 0 0 0;
`;
const LastTableCell = styled(TableCell)`
  border-radius: 0 20px 0 0;
  text-align: center;
`;

const ExportButton = styled(Button)`
  margin-left: 5px;
`;
const DownloadButton = styled(Button)`
  width: 81px;
  height: 28px;
  background: #ff5c5c 0% 0% no-repeat padding-box;
  border-radius: 7px;
  text-transform: none;
  color: ${colors.textWhite};
  &:hover {
    background: #ff5c5c 0% 0% no-repeat padding-box;
    color: ${colors.textWhite};
  }
`;

const PreviousExports = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useGetPreviousExportQuery(id);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadAll] = useLazyDownloadExportQuery()

  const downloadFile=(url:string, row)=>{
    fetch(url).then(response=>response.blob()).then(blob=>{
      const url2 = window.URL.createObjectURL(new Blob([blob], {type:"application/gzip"}));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download",`${row.name}.gz`)
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error)=>console.log({error}))
  }

  const handleExportAll = async () => {
    try {
      setDownloadInProgress(true);
      const fileUrls = [];
      for (const row of data.data) {
        const response = await downloadAll({id: `${btoa(`${row.storage_id}##${id}`)}`})
        if (response.data) {
          const responseData = await response.data;
          const fileUrl = responseData.url;
          downloadFile(fileUrl, row)
          fileUrls.push(fileUrl);
        } else {
          console.error('Failed to fetch file URL:', response.status);
        }
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    } finally {
      setDownloadInProgress(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return (
    <Page
      title="Exports"
      subtitle="Previous Exports"
      actionButton={
        <>
          <ButtonRouterLink to={`${routes.exports}/${exportsSubroutes.createNewExport}`}>
            <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
              New Export
            </Button>
          </ButtonRouterLink>
          <ExportButton variant="contained" onClick={handleExportAll} disabled={downloadInProgress}>
            {downloadInProgress ? 'Downloading...' : 'Download All'}
          </ExportButton>
        </>
      }
    >
      <TableContainer sx={{ minHeight: 400 }} component={Paper}>
        <Table stickyHeader size="small" aria-label="user table">
          <UserTableHead>
            <TableRow>
              <FirstTableCell>Id</FirstTableCell>
              <TableCell>Storage_id</TableCell>
              <TableCell>Error_description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>CreatedAt</TableCell>
              <TableCell>UpdatedAt</TableCell>
              <LastTableCell>Export</LastTableCell>
            </TableRow>
          </UserTableHead>
          <TableBody>
            {data.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.storage_id}</TableCell>
                <TableCell>{row.error_description}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.updatedAt}</TableCell>
                <TableCell>
                  <RouterLink
                    to={`${routes.exports}/${btoa(`${row.storage_id}##${id}`)}/${exportsSubroutes.download}?id=${id}&fileName=${btoa(`${row.storage_id}##${id}`)}`}
                    style={{ textDecoration: "none" }}
                  >
                    <DownloadButton>
                      <ViewExportIcon /> Download
                    </DownloadButton>
                  </RouterLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export default PreviousExports;