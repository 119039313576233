import { SingleRowDisplayer } from "./single-row-displayer";
import { Subscription } from "./types";
import { formatEpochTime } from "./utils";

const SelectedSingleProduct = ({
  subscription,
  onBackClick,
}: {
  subscription: Subscription;
  onBackClick: () => void;
}) => {
  const csDefRefList = subscription.csiList?.reduce((acc, { csDefRef }, i) => {
    if (i === 0) {
      return csDefRef;
    }
    return acc + ", " + csDefRef;
  }, "");

  const columns = [
    {
      header: "Bundle Name",
      value: subscription.bdlRef,
    },
    {
      header: "CAOC ID",
      value: subscription.description,
    },
    {
      header: "Subscription ID",
      value: subscription.id,
    },
    {
      header: "Activation Time",
      value: formatEpochTime(subscription.activationTime),
    },
    {
      header: "Bundle Entity State",
      value: subscription.states[0],
    },
    {
      header: "Creation Time",
      value: formatEpochTime(subscription.creationTime),
    },
    {
      header: "Last Reset Time",
      value: formatEpochTime(subscription.lastResetTime),
    },
    {
      header: "Input Date",
      value: formatEpochTime(subscription.inputDate),
    },
    {
      header: "Bucket Name",
      value: subscription.csiList?.[0]?.bktInfoList?.[0]?.bktDefName,
    },
    {
      header: "Counter Name",
      value:
        subscription.csiList?.[1]?.ctrInfoList?.[0]?.ctrDefId ??
        subscription.csiList?.[0]?.ctrInfoList?.[0]?.ctrDefId,
    },
    {
      header: "Cs Name",
      value: csDefRefList,
    },
    {
      header: "Bucket Initial Value",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.initialValue,
    },

    {
      header: "Bucket Original Initial Value",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.origInitialVal,
    },
    {
      header: "Bucket Unused Value",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.unusedValue,
    },
    {
      header: "Counter Current Value",
      value: String(subscription.entityCtrlnsts?.[0]?.value?.ctrInsMap?.[0]?.currentValue),
      // casting the value to String because the header would be displayed as "0" on the GUI due to some MUI feature
    },
    {
      header: "Bucket Prev Current Value",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.prevCurrentVal,
    },
    {
      header: "Bucket Current Charging Step",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.currentChgStep,
    },
    {
      header: "Bucket Charging Step Up Time",
      value: subscription.csiList?.[0]?.bcktInsts?.[0]?.chrgStepUpTime,
    },
  ];

  return <SingleRowDisplayer cols={columns} onBackClick={onBackClick} />;
};

export default SelectedSingleProduct;
