import styled from "@emotion/styled";
import { Button, Table, TableCell, TableRow, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { JsonView, allExpanded, defaultStyles } from "react-json-view-lite";
import { colors } from "../../common/colors";
import { LogsWithParsedLogs } from "./logs-types";
import ExportModal from "../shared/export-modal";
import { validateFunctionForExcelExport } from "../../lib/utils";
import { convertObjectToStringWithoutQuotes } from "./log-excel-utils";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

const FirstRowHeader = styled(TableCell)`
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite};
  width: 250px;
  border-top-left-radius: 30px;
  border: none;
`;
const RowHeader = styled(TableCell)`
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite};
  width: 140px;
  border: none;
`;

const LastRowHeader = styled(TableCell)`
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite};
  border-bottom-left-radius: 32px;
  width: 140px;
  border: none;
`;

const FooterButton = styled(Button)`
  background: #e60a17;
  border-radius: 34px;
  height: 40px;
  min-width: 180px;
  text-transform: none;
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
`;

const LightColoredTableCell = styled(TableCell)`
  background: ${colors.fadedGreyBackground};
`;

const DarkColoredLastTableCell = styled(TableCell)`
  background: ${colors.lightGreyBackground};
`;

const SingleLog: FunctionComponent<{ logs: LogsWithParsedLogs }> = ({ logs }) => {
  const logsInfo = logs.Logs;
  const formatedDumpTimestamp = logs.Dump_timestamp.value;
  const logsInfoJSON = logsInfo.syslogFields;
  const headers = [
    {
      label: "Dump Timestamp",
      mapping: (row) => row.Dump_timestamp?.value,
    },
    {
      label: "DumpNode",
      mapping: (row) => validateFunctionForExcelExport(row.DumpNode),
    },
    {
      label: "EventHeader_eventID",
      mapping: (row) => validateFunctionForExcelExport(row.Logs.commonEventHeader.eventId),
    },
    {
      label: "EventHeader_domain",
      mapping: (row) => validateFunctionForExcelExport(row.Logs.commonEventHeader?.domain),
    },
    {
      label: "EventHeader_eventName",
      mapping: (row) => validateFunctionForExcelExport(row.Logs.commonEventHeader?.eventName),
    },
    {
      label: "SysLogFields",
      mapping: (row) => {
        return `"${convertObjectToStringWithoutQuotes(row.Logs.syslogFields)}"`;
      },
    },
  ];
  return (
    <div>
      <Table>
        <TableRow>
          <FirstRowHeader variant="head" />
          <DarkColoredLastTableCell>
            <Typography variant="h5"> Single Log</Typography>
          </DarkColoredLastTableCell>
        </TableRow>
        <TableRow>
          <RowHeader variant="head">Dump Timestamp</RowHeader>
          <LightColoredTableCell>{formatedDumpTimestamp}</LightColoredTableCell>
        </TableRow>
        <TableRow>
          <RowHeader variant="head">DumpNode</RowHeader>
          <DarkColoredLastTableCell>{logs.DumpNode}</DarkColoredLastTableCell>
        </TableRow>
        <TableRow>
          <RowHeader variant="head">EventHeader_eventID</RowHeader>
          <LightColoredTableCell>{logsInfo.commonEventHeader.eventId}</LightColoredTableCell>
        </TableRow>
        <TableRow>
          <RowHeader variant="head">EventHeader_domain</RowHeader>
          <DarkColoredLastTableCell>{logsInfo.commonEventHeader.domain}</DarkColoredLastTableCell>
        </TableRow>
        <TableRow>
          <RowHeader variant="head">EventHeader_eventName</RowHeader>
          <LightColoredTableCell>{logsInfo.commonEventHeader.eventName}</LightColoredTableCell>
        </TableRow>
        <TableRow>
          <LastRowHeader variant="head">SysLogFields</LastRowHeader>
          <DarkColoredLastTableCell>
            <JsonView shouldExpandNode={allExpanded} style={defaultStyles} data={logsInfoJSON} />
          </DarkColoredLastTableCell>
        </TableRow>
      </Table>
      <TableFooter>
        <ExportModal data={[logs]} headers={headers} selectedTab={"singleLogs"} />
      </TableFooter>
    </div>
  );
};

export default SingleLog;
