const SubscriberIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Group_90"
    data-name="Group 90"
    width="20.01"
    height="18.195"
    viewBox="0 0 20.01 18.195"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_110"
          data-name="Rectangle 110"
          width="20.01"
          height="18.195"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="Group_89" data-name="Group 89" clipPath="url(#clip-path)">
      <path
        id="Path_136"
        data-name="Path 136"
        d="M0,88.413a4.7,4.7,0,0,1,.353-.833,1.672,1.672,0,0,1,1.438-.726c.306-.007.612,0,.918,0,.236,0,.377.121.375.307s-.143.3-.381.3c-.351.006-.7-.006-1.054.012a1.114,1.114,0,0,0-1.032,1.08.562.562,0,0,0,.015.083h.928c.091,0,.183,0,.273,0a.29.29,0,0,1,.286.306.284.284,0,0,1-.287.286c-.338.009-.677,0-1.016.005H.627v.655c.124,0,.251,0,.378,0,.24,0,.374.112.372.3s-.132.294-.377.3c-.122,0-.244,0-.392,0v.212q0,3.388,0,6.777a1.147,1.147,0,0,0,1.252,1.25h.781a1.165,1.165,0,0,1,.137,0,.317.317,0,0,1,.306.3.3.3,0,0,1-.313.308c-.364.008-.729.009-1.093-.006A1.746,1.746,0,0,1,.019,97.825c0-.012-.012-.022-.019-.034V88.413"
        transform="translate(0 -82.6)"
        fill="currentColor"
      />
      <path
        id="Path_137"
        data-name="Path 137"
        d="M82.289,31.466q0,3.351,0,6.7a1.69,1.69,0,0,1-1,1.67,2.036,2.036,0,0,1-.828.177q-4.523.018-9.047.006a1.718,1.718,0,0,1-1.764-1.312,2.046,2.046,0,0,1-.065-.54q-.006-4.054,0-8.108a.819.819,0,0,1,.023-.212.244.244,0,0,1,.286-.207.518.518,0,0,1,.271.2.5.5,0,0,1,.032.266q0,4.034,0,8.069a1.117,1.117,0,0,0,.859,1.192,1.512,1.512,0,0,0,.367.035q4.514,0,9.027,0a1.14,1.14,0,0,0,1.236-1.244q0-6.7,0-13.4a1.146,1.146,0,0,0-.868-1.214c-.219-.075-.307-.208-.258-.389s.214-.245.428-.189a1.7,1.7,0,0,1,1.29,1.6c.021.911.009,1.823.009,2.735q0,2.081,0,4.161"
        transform="translate(-66.18 -21.828)"
        fill="currentColor"
      />
      <path
        id="Path_138"
        data-name="Path 138"
        d="M103.952,4.68a4.67,4.67,0,1,1,4.685,4.659,4.7,4.7,0,0,1-4.685-4.659m8.74-.013a4.064,4.064,0,1,0-4.06,4.072,4.076,4.076,0,0,0,4.06-4.072"
        transform="translate(-98.865 0)"
        fill="currentColor"
      />
      <path
        id="Path_139"
        data-name="Path 139"
        d="M116.8,204.153q1.582,0,3.165,0a1,1,0,0,1,1.1,1.1q0,2.129,0,4.258a1,1,0,0,1-1.1,1.1c-.28,0-.56,0-.84,0-.231,0-.356-.114-.356-.3s.13-.3.355-.3c.293,0,.586,0,.879,0a.4.4,0,0,0,.453-.46q0-2.168,0-4.336c0-.331-.148-.463-.51-.464q-2.149,0-4.3,0H113.69c-.4,0-.532.131-.532.522q0,2.11,0,4.219c0,.4.13.52.533.52h4.2a1.046,1.046,0,0,1,.195.007.28.28,0,0,1,.257.294.274.274,0,0,1-.257.292,1.256,1.256,0,0,1-.195.01q-2.11,0-4.22,0a1,1,0,0,1-1.118-1.122q0-2.119,0-4.239a1,1,0,0,1,1.1-1.1q1.573,0,3.145,0"
        transform="translate(-107.046 -194.162)"
        fill="currentColor"
      />
      <path
        id="Path_140"
        data-name="Path 140"
        d="M348.327,96.074v-.213q0-3.488,0-6.975a1.141,1.141,0,0,0-.892-1.229,4.057,4.057,0,0,0-.7-.033c-.208-.006-.417,0-.624-.017a.27.27,0,0,1-.257-.293.265.265,0,0,1,.261-.289,7.383,7.383,0,0,1,1.477.058,1.689,1.689,0,0,1,1.343,1.755c0,1.062,0,2.123,0,3.185q0,2.814,0,5.627a1.783,1.783,0,0,1-.7,1.5,1.818,1.818,0,0,1-1.112.361c-.293.006-.586,0-.879,0-.252,0-.4-.12-.392-.311s.147-.3.4-.3c.319,0,.638,0,.957,0a1.122,1.122,0,0,0,1.091-.949c-.131,0-.257,0-.382,0-.238-.009-.368-.126-.361-.319s.137-.286.367-.292c.117,0,.234,0,.352-.006.006,0,.011-.005.033-.015V96.68h-.859c-.091,0-.182,0-.274,0-.241-.009-.369-.12-.363-.313s.137-.289.366-.291c.313,0,.625,0,.938,0h.208"
        transform="translate(-328.925 -82.758)"
        fill="currentColor"
      />
      <path
        id="Path_141"
        data-name="Path 141"
        d="M69.551,26.057c0-.67-.005-1.34,0-2.01a1.7,1.7,0,0,1,.982-1.64,2.584,2.584,0,0,1,.71-.18.282.282,0,0,1,.335.25c.023.177-.076.291-.268.342a2.455,2.455,0,0,0-.656.214,1.086,1.086,0,0,0-.494,1c.006,1.34,0,2.68,0,4.02a.487.487,0,0,1-.033.265.433.433,0,0,1-.275.188c-.094,0-.2-.107-.273-.192-.041-.05-.03-.149-.03-.226,0-.676,0-1.353,0-2.029"
        transform="translate(-66.146 -21.135)"
        fill="currentColor"
      />
      <path
        id="Path_142"
        data-name="Path 142"
        d="M155.25,235.573c-.625,0-1.249.006-1.874-.006a.51.51,0,0,1-.324-.128.28.28,0,0,1,.147-.45.981.981,0,0,1,.251-.022q1.805,0,3.611,0c.046,0,.091,0,.137,0,.205.016.338.14.332.31a.311.311,0,0,1-.348.293c-.579,0-1.158,0-1.737,0h-.2"
        transform="translate(-145.497 -223.468)"
        fill="currentColor"
      />
      <path
        id="Path_143"
        data-name="Path 143"
        d="M141.224,293.608c-.593,0-1.185,0-1.778,0-.077,0-.178.01-.226-.033a.486.486,0,0,1-.184-.281c0-.087.1-.2.181-.262a.441.441,0,0,1,.245-.035q1.768,0,3.537,0h.059c.25.006.394.123.389.315s-.144.3-.385.3c-.612,0-1.224,0-1.837,0"
        transform="translate(-132.232 -278.657)"
        fill="currentColor"
      />
      <path
        id="Path_144"
        data-name="Path 144"
        d="M140.61,264.632h-1.385a1.368,1.368,0,0,1-.195-.009.3.3,0,0,1,0-.585,2.3,2.3,0,0,1,.331-.008q1.365,0,2.731,0c.171,0,.314.042.363.224.058.213-.086.375-.343.377-.5,0-1,0-1.5,0"
        transform="translate(-131.983 -251.107)"
        fill="currentColor"
      />
      <path
        id="Path_145"
        data-name="Path 145"
        d="M223.677,264.045c.2,0,.39,0,.585,0a.306.306,0,0,1,.331.288.3.3,0,0,1-.328.309q-.595.01-1.19,0a.29.29,0,0,1-.317-.3c0-.178.126-.293.334-.3h.585"
        transform="translate(-211.857 -251.122)"
        fill="currentColor"
      />
      <path
        id="Path_146"
        data-name="Path 146"
        d="M137.477,47.737a1.768,1.768,0,0,1-1.392-1.333c0-.006-.006-.012-.007-.018-.071-.445-.117-.7.526-.682,1,.023,2.005-.006,3.007.026a1.667,1.667,0,0,1,1.57,1.541,18.4,18.4,0,0,1,0,1.852,1.849,1.849,0,0,1-3.635.156,5.134,5.134,0,0,1-.064-.989c-.008-.181,0-.363,0-.552m.619.031c0,.407-.012.791,0,1.174a1.247,1.247,0,0,0,2.338.524c.292-.545.109-1.13.169-1.7Zm-1.4-1.451a1.127,1.127,0,0,0,1.174.837c.84,0,1.68,0,2.52,0a1.319,1.319,0,0,0,.148-.017,1.08,1.08,0,0,0-1.018-.816c-.937-.017-1.875,0-2.824,0"
        transform="translate(-129.382 -43.467)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SubscriberIcon;
