import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO, setDefaultOptions } from "date-fns";
import { enGB } from "date-fns/locale";
import { FormikValues } from "formik";
import * as React from "react";
import { generateMuiFormikFields } from "../../lib/mui-formik-field-generator";
import { FormikInstance } from "../../lib/use-formik-return-type";

setDefaultOptions({ locale: enGB });

export const FormikDateTimePicker = <T extends FormikValues>({
  formik,
  name,
  ...rest
}: Omit<React.ComponentProps<typeof DateTimePicker>, "onChange" | "value" | "renderInput"> & {
  formik: FormikInstance<T>;
  name: string;
}) => {
  const formikFields = generateMuiFormikFields(name, formik);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        format="dd/MM/yyyy HH:mm:ss"
        {...rest}
        {...formikFields}
        value={new Date(formikFields.value)}
        ampm={false}
        onChange={(val) => {
          formik.setFieldValue(name, val);
        }}
        slotProps={{
          textField: {
            size: "small",
            name,
            error: formikFields.error,
            helperText: formikFields.helperText,
          },
        }}
      />
    </LocalizationProvider>
  );
};
