import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { useIsLoggedIn } from "../../lib/hooks/useIsLoggedIn";
import { routes } from "../../lib/routes";
import { removeUser } from "../../lib/store/slices/authentication-slice";
import { clearToken } from "../../lib/token-utils";
import { Loading } from "../shared/loading";
import { symphonyApi } from "../../lib/store/services/symphony";
import { Constants } from "../../lib/constants";

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
  /*   clearToken();
    localStorage.removeItem(Constants.LOCAL_STORAGE_USER_KEY); */
   
    //to remove all the localStorage key and values which inlcudes searchvalues and  filtersvalues of all the pages in Gui
    localStorage.clear()
    sessionStorage.removeItem(Constants.SESSION_STORAGE_SELECTED_MARKET);
    dispatch(removeUser());
    
    dispatch(symphonyApi.util.resetApiState());
    window.location.reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isLoggedIn = useIsLoggedIn();
  if (isLoggedIn) {
    return <Loading />;
  }

  return <Navigate to={routes.login} />;
};
