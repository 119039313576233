import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  ChartType,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import { groupBy, max, orderBy } from "lodash";
import { FunctionComponent, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { GetMeasurementsData } from "../subscriber-profiles-cdr/types";
import { randomColors } from "./random-colors";
import { MeasurementTableValues } from "./table-types";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
);

const getColorIndex = (i: number, j: number) => {
  const nr = i * 80 + j * 30;
  if (nr > 550) {
    return (nr + 1) % 550;
  }
  return nr;
};

const useGraphData = (
  tables: MeasurementTableValues[],
  kpiTables: GetMeasurementsData["tables"],
) => {
  const graphData = useMemo(() => {
    const labels = tables.flatMap((t) => t.data.map((d) => parseISO(d.Start_time.value)));
    const datasets = kpiTables.flatMap((kpi, i) =>
      kpi.fields.map((field, j) => {
        const data = tables
          .find((t) => t.table === kpi.table)
          .data.map((d) => ({ x: d.Start_time.value, y: d[field] }));
        const datasetsGrouped = groupBy(data, (g) => g.x);

        const result = orderBy(
          Object.entries(datasetsGrouped).map(([startTime, datapoints]) => ({
            x: startTime,
            y: max(datapoints.map((d) => d.y)),
          })),
          (r) => parseISO(r.x),
        );
        return {
          label: `${kpi.table}-${field}`,
          data: result,
          backgroundColor: randomColors[getColorIndex(i, j)].code.hex,
          borderColor: randomColors[getColorIndex(i, j)].code.hex,
        };
      }),
    );

    return {
      labels,
      datasets,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tables]);
  return graphData;
};

type TimeOption = {
  stepSize: number;
  unit: string;
};

const getTimeScaleChartOptions = (time: TimeOption): ChartOptions<ChartType> => ({
  scales: {
    x: {
      type: "time",
      time: time as any,
      title: {
        display: true,
        text: "Date",
      },
      adapters: {
        date: {
          locale: enGB,
        },
      },
    },
    y: {
      title: {
        display: true,
        text: "value",
      },
    },
  },
});

export const LineGraphCreation: FunctionComponent<{
  tables: MeasurementTableValues[];
  kpiTables: GetMeasurementsData["tables"];
  timeOption: TimeOption;
}> = ({ tables, kpiTables, timeOption }) => {
  const lineData = useGraphData(tables, kpiTables);
  return (
    <div>
      <Line
        options={getTimeScaleChartOptions(timeOption) as ChartOptions<"line">}
        data={lineData}
      />
    </div>
  );
};

// export const PieGraphCreation: FunctionComponent<{ tables: MeasurementTableValues[] }> = ({
//   tables,
// }) => {
//   const pieData = useGraphData(tables);
//   return (
//     <div>
//       <Pie data={pieData} />
//     </div>
//   );
// };

// export const ScatterGraphCreation: FunctionComponent<{ tables: MeasurementTableValues[] }> = ({
//   tables,
// }) => {
//   const scatterData = useGraphData(tables);
//   return (
//     <div>
//       <Scatter options={timeScaleChartOptions as ChartOptions<"scatter">} data={scatterData} />
//     </div>
//   );
// };
