import { serverBaseUrl } from "./env-variables";

export const getExportDownloadUrl = (exportId: string) =>
  `${serverBaseUrl}/export/${exportId}/download`;

export const validateFunctionForExcelExport = (value) => {
  if (value == null || value === undefined) {
    return "";
  }
  return `"${value}"`;
};

export const calculateArrayMegabytes = (objects: object[]): number => {
  if (!objects || objects.length === 0) return 0;

  const bytesPerMegabyte = 1024 * 1024;
  const totalBytes = objects.reduce((acc, obj) => acc + JSON.stringify(obj).length, 0);
  const megabytes = totalBytes / bytesPerMegabyte;
  return Math.floor(megabytes * 10) / 10; // rounding down to 1 decimal place
};

export const clearTextInputsByCommonIdPrefix = (txtIdPrefix: string): void => {
  const allTxtInputs = document.querySelectorAll(`[id^=${txtIdPrefix}]`);

  allTxtInputs.forEach((input: any) => (input.value = ""));
};

export const parseColFilterParam = (columnFilters: Record<string, string>): Record<string, string> => {
  let finalColFilters = {};

  Object.keys(columnFilters).forEach((columnName) => {
    const colFilterValue = columnFilters[columnName];
    if (colFilterValue !== null && colFilterValue !== undefined && colFilterValue.trim().length > 1)
      finalColFilters[columnName] = colFilterValue;
  });

  return finalColFilters;
};

export const invertSortType = (type: string): string => {
  if (type === "asc") return "desc";

  return "asc";
};
