import styled from "@emotion/styled";
import { Alert, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import * as yup from "yup";
import { colors } from "../../common/colors";
import loginImage from "../../images/loginImage.png";
import loginLogo from "../../images/loginLogo.png";
import { symphonyApi, useAuthenticateMutation } from "../../lib/store/services/symphony";
import { removeUser, setUser } from "../../lib/store/slices/authentication-slice";
import { clearToken, setToken } from "../../lib/token-utils";
import { LoginError } from "../../lib/types/user-types";
import CustomErrorMessage from "../custom-error-message";
import { FormikTextField } from "../formik-fields/FormikTextField";
import SecurityNotice from "./SecurityNotice";
import { useIsLoggedIn } from "../../lib/hooks/useIsLoggedIn";
import { setMarket } from "../../lib/store/slices/market-slice";
import { Constants } from "../../lib/constants";

interface LoginShape {
  email: string;
  password: string;
}

const theme = createTheme();

const FormWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ImageWrapper = styled(Box)`
  width: 250px;
  height: 90px;
  margin-bottom: 25px;
`;

const PageWrapper = styled.div`
  min-height: 80%;
  background-image: url(${loginImage});
  background-attachment: fixed;
  background-size: cover;
  opacity: 1;
`;

const LoginColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 290px;
  margin: 120px 0 0 140px;
`;

const WelcomeMessage = styled(Typography)`
  font-size: 14px;
  text-align: center;
  font: normal normal medium 14px/16px Raleway;
  letter-spacing: 0px;
  color: #4a4a4a;
  text-transform: uppercase;
`;

const LoginButton = styled(Button)`
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite};
  border-radius: 34px;
  width: 180px;
  &:hover :active {
    background-color: ${colors.vodafoneRed};
  }
`;

const RoundedFormikTextField = styled(FormikTextField)`
  border-radius: 35px;
  border: 1px red;
`;

let message = (
  <>
    <p>
      ************************************************************************************************************************************************************************************************
    </p>
    <p>
      This system allows the viewing and export of data to authorized users only in accordance with
      Vodafone security policies and procedures.
    </p>
    <p> Please read the following terms carefully:</p>
    <ul>
      <li>
        Only Vodafone issued laptops should be used for the download and access of the reports.
      </li>
      <li>
        Reports containing personal data are not to be shared without personal data attributes being
        first removed or anonymized.
      </li>
      <li>
        Downloaded reports must be deleted once they are no longer required or after 30 days as per
        the retention policy set for the data.
      </li>
      <li>
        The system and the generated reports are only approved for use in the reporting and
        troubleshooting of subscriber billing. New use cases must be communicated to the admin team
        to undergo a separate privacy assessment.
      </li>
    </ul>
    <p>
      **************************************************************************************************************************************************************************************************
    </p>
  </>
);

export const Login = () => {
  const [authenticate, { error, isLoading, data }] = useAuthenticateMutation();
  const errorDetails = (error as { data: LoginError } | undefined)?.data;
  const serverErrorMessage = errorDetails
    ? errorDetails?.detsils?.error ?? errorDetails?.message
    : undefined;

  const [errorMessage, setErrorMessage] = useState(serverErrorMessage);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state as { from: Location } | undefined;
  const from = state?.from?.pathname || "/";
  const formik = useFormik<LoginShape>({
    onSubmit: (values: LoginShape) => {
      authenticate(values);
    },
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().required("Email can not be empty"),
      password: yup
        .string()
        .required("Please enter a password")
        .min(8, "Password is should be at least 8 characters long."),
    }),
  });

  const isUserLogged = useIsLoggedIn();
  useEffect(() => {
    if (!isUserLogged) //clear all user related states and localstorage
    {
      clearToken();
      localStorage.removeItem(Constants.LOCAL_STORAGE_USER_KEY);
      sessionStorage.removeItem(Constants.SESSION_STORAGE_SELECTED_MARKET);
      dispatch(removeUser());
      dispatch(symphonyApi.util.resetApiState());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && !error && data?.success === true) {
      const { access_token: token, isDeleted, ...user } = data.data;
      if (isDeleted) {
        setErrorMessage("This user was deleted");
        return;
      }

      setToken(token);
      localStorage.setItem(Constants.LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
      dispatch(setUser({ ...user }));
      dispatch(setMarket(user.localMarket));//default market selected upon login
      navigate(from, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error, data]);

  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <form onSubmit={formik.handleSubmit}>
          <Container>
            <LoginColumn>
              <ImageWrapper>
                <img alt="" src={loginLogo} />
              </ImageWrapper>
              <WelcomeMessage>Welcome to Symphony</WelcomeMessage>
              <FormWrapper>
                <RoundedFormikTextField
                  placeholder="Username"
                  margin="normal"
                  id="email"
                  name="email"
                  autoComplete="email"
                  formik={formik}
                />
                <FormikTextField
                  placeholder="Password"
                  margin="normal"
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  formik={formik}
                />
                {!errorMessage && error && (
                  <Alert sx={{ mt: 1, mb: 2 }} severity="error">
                    Something went wrong!
                  </Alert>
                )}
                <LoginButton type="submit" variant="contained">
                  Login
                </LoginButton>
                {errorMessage && <CustomErrorMessage error={errorMessage} />}
              </FormWrapper>
            </LoginColumn>
          </Container>
        </form>
        <SecurityNotice message={message} />
      </PageWrapper>
    </ThemeProvider>
  );
};
