const GraphTypeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="14.629" viewBox="0 0 22.5 14.629">
    <g id="Group_934" data-name="Group 934" transform="translate(747 -5584)">
      <g id="Group_932" data-name="Group 932" transform="translate(-732.564 5584)">
        <path
          id="Path_505"
          data-name="Path 505"
          d="M92.438,35.8a4.032,4.032,0,1,1,0-8.064.173.173,0,0,1,.173.173V31.6H96.3a.173.173,0,0,1,.173.173A4.037,4.037,0,0,1,92.438,35.8Zm-.173-7.714a3.686,3.686,0,1,0,3.855,3.855H92.438a.173.173,0,0,1-.173-.173Z"
          transform="translate(-88.406 -27.738)"
          fill="#fff"
        />
      </g>
      <g id="Group_933" data-name="Group 933" transform="translate(-728.705 5584)">
        <path
          id="Path_506"
          data-name="Path 506"
          d="M114.735,31.944h-3.859a.173.173,0,0,1-.173-.173V27.911a.173.173,0,0,1,.173-.173,4.037,4.037,0,0,1,4.032,4.032A.173.173,0,0,1,114.735,31.944Zm-3.686-.346h3.509a3.691,3.691,0,0,0-3.509-3.509Z"
          transform="translate(-110.703 -27.738)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_507"
        data-name="Path 507"
        d="M62.035,91.929A2.128,2.128,0,1,1,64.163,89.8,2.13,2.13,0,0,1,62.035,91.929Zm0-3.91A1.782,1.782,0,1,0,63.816,89.8,1.784,1.784,0,0,0,62.035,88.019Z"
        transform="translate(-797.404 5506.7)"
        fill="#fff"
      />
      <path
        id="Path_508"
        data-name="Path 508"
        d="M34.4,63.172a2.128,2.128,0,1,1,2.128-2.128A2.13,2.13,0,0,1,34.4,63.172Zm0-3.91a1.782,1.782,0,1,0,1.782,1.782A1.784,1.784,0,0,0,34.4,59.262Z"
        transform="translate(-774.554 5530.48)"
        fill="#fff"
      />
      <path
        id="Path_509"
        data-name="Path 509"
        d="M7.128,88.971a2.128,2.128,0,1,1,2.128-2.128A2.13,2.13,0,0,1,7.128,88.971Zm0-3.91A1.782,1.782,0,1,0,8.91,86.843,1.784,1.784,0,0,0,7.128,85.061Z"
        transform="translate(-752 5509.146)"
        fill="#fff"
      />
      <path
        id="Path_510"
        data-name="Path 510"
        d="M80.209,74.317a.173.173,0,0,1-.124-.294l3.662-3.76A.173.173,0,1,1,84,70.5l-3.662,3.76A.172.172,0,0,1,80.209,74.317Z"
        transform="translate(-814.049 5521.14)"
        fill="#fff"
      />
      <path
        id="Path_511"
        data-name="Path 511"
        d="M54.6,79.926a.172.172,0,0,1-.116-.045l-2.264-2.049a.173.173,0,0,1,.232-.257l2.264,2.049a.173.173,0,0,1-.116.3Z"
        transform="translate(-791.002 5515.087)"
        fill="#fff"
      />
      <path
        id="Path_512"
        data-name="Path 512"
        d="M24.081,79a.173.173,0,0,1-.125-.293l1.812-1.888a.173.173,0,1,1,.25.24l-1.812,1.888A.172.172,0,0,1,24.081,79Z"
        transform="translate(-767.636 5515.72)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default GraphTypeIcon;
