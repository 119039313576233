import styled from "@emotion/styled";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FunctionComponent, useState } from "react";
import ViewExportIcon from "../../icons/view-export-icon";
import { Export } from "../../lib/types/export-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 500,
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};
const ModalButton = styled(Button)`
  color: black;
  width: 16px;
  height: 16px;
  &.MuiButton-root {
    :hover {
      background: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
const ExportInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageBox = styled(Box)`
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 23px;
  padding: 32px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExportInformationModal: FunctionComponent<{ exportInfo: Export }> = ({ exportInfo }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const { data } = useUserListQuery();

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Button onClick={handleOpen}>
        <ViewExportIcon />
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <ButtonContainer>
            <ModalButton onClick={handleClose}>X</ModalButton>
          </ButtonContainer>
          <ExportInformationWrapper>
            <Typography variant="h6">View Export</Typography>
            <PageBox>
              <Column>
                <div>
                  Export name: <br />
                  {exportInfo.name}
                </div>
                <br />
                <div>
                  Last Generation Date: <br />
                  {exportInfo.retentionPeriod}
                </div>
                <br />
                <div>
                  End point: <br />
                  {exportInfo.endpoint}
                </div>
                <br />
                <div>
                  Export file name rules: <br />
                  {exportInfo.fileNameRules}
                </div>
              </Column>
              {/* <div>Reacurrency {exportInfo.exectutionOptions}</div> */}
              <Column>
                <div>
                  First execution: <br />
                  {exportInfo.firstExecution}
                </div>
                {/* <br />
                <div>
                  Publish method: <br />
                  {exportInfo.publishMethod}
                </div> */}
                <br />
                <br />
                <div>
                  Retention period on Symphony: <br />
                  {exportInfo.retentionPeriod}
                </div>
              </Column>
            </PageBox>
          </ExportInformationWrapper>
        </Box>
      </Modal>
    </div>
  );
};

export default ExportInformationModal;
