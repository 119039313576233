import styled from "@emotion/styled";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import { startOfDay } from "date-fns";
import endOfDay from "date-fns/endOfDay";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { colors } from "../../common/colors";
import { selectLastCdrEdrSearch, setClear } from "../../lib/store/slices/last-cdredr-slice";
import { FormikDatePicker } from "../formik-fields/FormikDatePicker";
import { FormikDateTimePicker } from "../formik-fields/FormikDateTimePicker";
import { FormikRadioGroup } from "../formik-fields/FormikRadioGroup";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { FieldsInBqModal } from "../shared/fields-in-bq-modal";
import { SearchFields } from "./types";
import { isDateTimeValid } from "./utils";
import { FIBQConstants } from "../shared/FIBQ-utils";
import { useAppDispatch } from "../../lib/store/hooks";

const FormGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  & > div {
    margin-right: 20px;
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SpecificDateFormControlLabel = styled(FormControlLabel)`
  color: ${colors.lightBlackSubtitle};
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const TitleLable = styled(InputLabel)`
  margin-bottom: 15px;
  color: ${colors.lightBlackSubtitle};
  font-weight: 520;
  font: normal normal medium 14px/16px Raleway;
`;

const SearchResultTitle = styled(Typography)`
  margin-top: 25px;
  font: normal normal bold 18px/21px;
  font-weight: 550;
  color: ${colors.lightBlackSubtitle};
`;

const WhenWrapper = styled.div`
  display: flex;
`;

const searchDefaultValues: SearchFields = {
  msisdn: "",
  types: "",
  when: "",
  fromDate: startOfDay(new Date().setDate(new Date().getDate()-1)).toString(),
  toDate: endOfDay(new Date().setDate(new Date().getDate()-1)).toString(),
  fromTime: "",
  toTime: "",
  perPage: 50,
  pageNo:0,
  colFilters:{},
};

const CdrForm = ({ onSearch }: { onSearch: (value: SearchFields) => void }) => {
  const dispatch = useAppDispatch();

  const [resultTitle, setResultTitle] = useState(false);

  const searchSelectorValue = useSelector(selectLastCdrEdrSearch) ?? searchDefaultValues;

  const formik = useFormik({
    initialValues: {
      ...searchDefaultValues,
    },
    enableReinitialize:true,
    validationSchema: yup.object({
      msisdn: yup.string().required("MSISDN is a required field"),
      types: yup.string(),
      when: yup.string().required("You must select one of the alternatives of When"),
      fromDate: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),

      fromTime: yup.string(),
      toDate: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),

      toTime: yup.string(),
      fieldsInBQ: yup.array(),
    }),
    onSubmit: (values) => {
      onSearch(values);
      setResultTitle(true);
    },
  });


  useEffect(() => {
    if (formik.values.types === "subscriber" && formik.values.when !== "now") {
      formik.setFieldValue(
        "toDate",
        endOfDay(new Date(formik.values.fromDate)),
        true,
      )
    }
  }, [formik.values.fromDate])
  

  useEffect(() => { 

    if(formik.values && searchSelectorValue && formik.values !== searchSelectorValue){
    if(formik.values.types === "cdr" && formik.values.when !== "now"){

      formik.setFieldValue(
        "fromDate",
        startOfDay(new Date().setDate(new Date().getDate())),
        true,
      );
      
      formik.setFieldValue(
        "toDate",
        endOfDay(new Date().setDate(new Date().getDate())),
        true,
      );
    }else if (formik.values.types === "subscriber" && formik.values.when !== "now") {
      formik.setFieldValue(
        "fromDate",
        startOfDay(new Date().setDate(new Date().getDate()-1)),
        true,
      );
      
      formik.setFieldValue(
        "toDate",
        endOfDay(new Date().setDate(new Date().getDate()-1)),
        true,
      );
   
    }else if (formik.values.types === "subscriber" && formik.values.when === "now") {
      formik.setFieldValue("fromDate", new Date(), true);
      formik.setFieldValue("toDate", endOfDay(new Date()), true);
    }}
  }, [ formik.values.types, formik.values.when ])

  useEffect(() => {
    Object.entries(searchSelectorValue).map((item) => {
      formik.setFieldValue(item[0], item[1], true);
    });

  }, [searchSelectorValue]);

  const typesOptions = [
    { id: "cdr", name: "CDR/EDR" },
    { id: "subscriber", name: "Subscriber Profiles" },
  ];
  const handleResetForm = () => {
    dispatch(setClear(undefined));
    formik.resetForm();
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormGrid>
          <div>
            <TitleLable>MSISDN</TitleLable>
            <FormControl fullWidth>
              <FormikTextField size="small" name="msisdn" formik={formik} />
            </FormControl>
          </div>
          <div>
            <TitleLable>Types</TitleLable>
            <FormControl fullWidth>
              <FormikSelect
                style={{ minWidth: 200 }}
                size="small"
                id="types"
                name="types"
                formik={formik}
              >
                {typesOptions.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.name}
                  </MenuItem>
                ))}
              </FormikSelect>
            </FormControl>
          </div>
          <div>
            <TitleLable>When</TitleLable>
            <FormControl>
              <FormikRadioGroup aria-label="when" name="when" row formik={formik}>
                <WhenWrapper>
                  {formik.values.types === "subscriber" && (
                    <FormControlLabel value={"now"} control={<Radio />} label="Now" />
                  )}
                  {formik.values.types === "cdr" && (
                    <SpecificDateFormControlLabel
                      value={"specificDate"}
                      control={<Radio />}
                      label="Specific Date"
                    />
                  )}
                  {formik.values.types === "subscriber" && (
                    <SpecificDateFormControlLabel
                      value={"specificDate"}
                      control={<Radio />}
                      label="Specific Date"
                    />
                  )}
                </WhenWrapper>
              </FormikRadioGroup>
            </FormControl>
          </div>
          <div>
            {formik.values.types === "subscriber" && formik.values.when === "specificDate" && (
              <div>
                <DatePickerWrapper>
                  <TitleLable>Date</TitleLable>
                  <FormikDatePicker formik={formik} name="fromDate" />
                </DatePickerWrapper>
              </div>
            )}
            {formik.values.types === "cdr" && formik.values.when === "specificDate" && (
              <DatePickerWrapper>
                <DateTimeWrapper>
                  <FormikDateTimePicker
                    disabled={formik.values.when !== "specificDate"}
                    label="From date"
                    formik={formik}
                    name="fromDate"
                  />
                </DateTimeWrapper>
                <DateTimeWrapper>
                  <FormikDateTimePicker
                    disabled={formik.values.when !== "specificDate"}
                    label="To date"
                    formik={formik}
                    name="toDate"
                  />
                </DateTimeWrapper>
              </DatePickerWrapper>
            )}
          </div>
          <div>
            <TitleLable>Fields in BQ</TitleLable>
            <FormControl fullWidth>
              <FieldsInBqModal preselectedStreamTab={FIBQConstants.BQ_STREAM_SUBSCRIBER} excludeStreams={[FIBQConstants.BQ_STREAM_SERVICE_DATA]}/>
            </FormControl>
          </div>
          <div>
            <TitleLable>Search</TitleLable>
            <Button size="small" type="submit" variant="contained">
              Search
            </Button>
          </div>
          <div>
            <TitleLable>Clear</TitleLable>
            <Button
              type="reset"
              onClick={(e) => {
                handleResetForm();
              }}
              size="small"
              color="inherit"
              variant="contained"
            >
              Clear
            </Button>
          </div>
        </FormGrid>
      </form>
      {resultTitle && <SearchResultTitle variant="h6">Search Result</SearchResultTitle>}
    </div>
  );
};

export default React.memo(CdrForm);
