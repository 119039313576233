export const colors = {
  vodafoneRed: "#e60000",
  brightRed: "#FF5C5C",
  textWhite: "#fff",
  textBlack: "#000000",
  lightBlackSubtitle: "#4A4A4A",
  fadedBlackSubtitle: "#909BA8",
  greyBackground: "#E5E5E5",
  lightGreyBackground: "#F1F1F1",
  fadedGreyBackground: "#F8F8F8",
  green: "#01860B",
  orange: "#F48D00",
  lightBlue: "#9AB8FF",
  fadedBlue: "#E9EDF7",
};
