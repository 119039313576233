import styled from "@emotion/styled";
import { FormControl, MenuItem, Select, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { path, uniqBy } from "ramda";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { colors } from "../../common/colors";
import DetailedJSON from "../../common/detailed-JSON";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import { getFormattedDate } from "../../lib/date-utils";
import { useLazyGetFullSubscribersQuery } from "../../lib/store/services/symphony";
import ExportModal from "../shared/export-modal";
import ProductsTableRow from "./products-table-row";
import SelectedSingleProduct from "./selected-single-product";
import { ProfileInfoTableData } from "./table-types";
import { SearchFields, Subscription } from "./types";
import { formatEpochTime } from "./utils";
import { orderBy } from "lodash";
import { validateFunctionForExcelExport } from "../../lib/utils";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { CdrDashboardTabs } from "./cdrDashboardTabs";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const ProductsTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  text-align: center;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const HeaderTableCell = styled(TableCell)`
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 250px;
  white-space: nowrap;
`;

const converterFunctionForCSName = (item) => {
  return item?.reduce((acc, { csDefRef }, i) => {
    if (i === 0) {
      return csDefRef;
    }
    return `${acc + " ," + csDefRef}`;
  }, "");
};

const headers = [
  {
    label: "Dump Node",
    mapping: (row) => row?.dumpNode,
  },
  {
    label: "Bundle Name",
    mapping: (row) => validateFunctionForExcelExport(row.bdlRef),
  },
  {
    label: "CAOC ID",
    mapping: (row) => validateFunctionForExcelExport(row.description),
  },
  {
    label: "Subscription ID",
    mapping: (row) => validateFunctionForExcelExport(row.id),
  },
  {
    label: "Activation Time",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.activationTime);
      return validateFunctionForExcelExport(rowDate);
    },
  },
  {
    label: "Bundle Entity State",
    mapping: (row) => validateFunctionForExcelExport(row.states[0]),
  },
  {
    label: "Creation Time",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.creationTime);
      return validateFunctionForExcelExport(rowDate);
    },
  },
  {
    label: "Last reset Time",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.lastResetTime);
      return validateFunctionForExcelExport(rowDate);
    },
  },
  {
    label: "Input Date",
    mapping: (row) => {
      const rowDate = formatEpochTime(row.inputDate);
      return validateFunctionForExcelExport(rowDate);
    },
  },
  {
    label: "Bucket Name",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bktInfoList?.[0]?.bktDefName),
  },
  {
    label: "Counter Name",
    mapping: (row) => {
      return validateFunctionForExcelExport(
        row.csiList?.[1]?.ctrInfoList?.[0]?.ctrDefId ??
          row.csiList?.[0]?.ctrInfoList?.[0]?.ctrDefId,
      );
    },
  },
  {
    label: "Cs Name",
    mapping: (row) => validateFunctionForExcelExport(converterFunctionForCSName(row.csiList)),
  },
  {
    label: "Bucket Initial Value",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.initialValue),
  },
  {
    label: "Bucket Original Initial Value",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.origInitialVal),
  },
  {
    label: "Bucket Unused Value",
    mapping: (row) => validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.unusedValue),
  },
  {
    label: "Counter Current Value",
    mapping: (row) =>
      validateFunctionForExcelExport(row.entityCtrlnsts?.[0].value.ctrInsMap?.[0].currentValue),
  },
  {
    label: "Bucket Prev Current Value",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.prevCurrentVal),
  },
  {
    label: "Bucket Current Charging Step",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.currentChgStep),
  },
  {
    label: "Bucket Charging Step Up Time",
    mapping: (row) =>
      validateFunctionForExcelExport(row.csiList?.[0]?.bcktInsts?.[0]?.chrgStepUpTime),
  },
];
type ProductsProps = {
  tableData: ProfileInfoTableData;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  handleChangeRowsPerPage: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  page: number;
  rowsPerPage: number;
  search?: SearchFields;
};

const Products = ({
  tableData,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  search,
}: ProductsProps) => {
  const [selectedRow, setSelectedRow] = useState<Subscription | undefined>();
  const handleRowClick = (row: Subscription) => {
    setSelectedRow(row);
  };
  const [columnValues, setColumnValues] = useState<Record<string, string>>({});

  const handleColumnValuesChange = useCallback((columnValues: Record<string, string>) => {
    setColumnValues(columnValues);
  }, []);

  const columnsToDisplay = useMemo(
    () => [
      "bdlRef",
      "description",
      "id",
      "activationTime",
      "states.0",
      "creationTime",
      "lastResetTime",
      "inputDate",
      "csiList.0.bktInfoList.0.bktDefName",
      "csiList.1.ctrInfoList.0.ctrDefId",
      "csiList.0.csDefRef",
      "csiList.0.bcktInsts.0.initialValue",
      "csiList.0.bcktInsts.0.origInitialVal",
      "csiList.0.bcktInsts.0.unusedValue",
      "entityCtrlnsts.0.value.ctrInsMap.0.currentValue",
      "csiList.0.bcktInsts.0.prevCurrentVal",
      "csiList.0.bcktInsts.0.currentChgStep",
      "csiList.0.bcktInsts.0.chrgStepUpTime",
    ],
    [],
  );

  const datesColumnsNames = ["creationTime", "lastResetTime", "inputDate", "activationTime"];

  const sortedNotes = useMemo(() => {
    if (!tableData?.products?.length) return []; // Handle empty data

    return orderBy(
      tableData?.products.slice(0, 2).map((p, i) => ({ index: i, name: p.DumpNode })),
      (n) => n?.name,
    );
  }, [tableData]);

  const [selectedNode, setSelectedNode] = useState<number>(sortedNotes[0]?.index || 0); // Handle potential empty sortedNotes

  const uniqueSubscription = useMemo(() => {
    if (!tableData?.products?.length) return []; // Handle empty data

    const subscriptions = tableData?.products[selectedNode]?.subscriptions || [];

    // Add dumpNode name to each subscription
    const subscriptionsWithDumpNode = subscriptions.map((subscription) => {
      const dumpNode = tableData?.products?.[selectedNode]?.DumpNode;
      return {
        ...subscription,
        dumpNode: dumpNode,
      };
    });

    return uniqBy((s) => s?.id, subscriptionsWithDumpNode);
  }, [tableData, selectedNode]);

  const columns = useMemo(() => columnsToDisplay, [columnsToDisplay]);
  const handleBack = () => {
    setSelectedRow(undefined);
  };

  const filteredTableData: Subscription[] = uniqueSubscription?.filter((sub) => {
    return Object.entries(columnValues)
      .filter(([, val]) => val)
      .every(([key, value]) => {
        const keyPath = key.split(".");
        //special implementation for cs name
        let csListFlag = false;
        if (key === "csiList.0.csDefRef") {
          const csKeyPath = "csiList.1.csDefRef".split(".");
          const csValueAtPath = path(csKeyPath, sub);
          const lowerCaseCSValue =
            typeof csValueAtPath === "object"
              ? JSON.stringify(csValueAtPath).toLowerCase()
              : (csValueAtPath ?? "").toString().toLowerCase();

          csListFlag = lowerCaseCSValue.includes(value?.toLowerCase() ?? "");
        }
        const valueAtPath = path(keyPath, sub);
        const lowerCaseValue =
          typeof valueAtPath === "object"
            ? JSON.stringify(valueAtPath).toLowerCase()
            : (valueAtPath ?? "").toString().toLowerCase();
        // Will convert the lowerCaseValue to date string if it's a timestamp
        if (datesColumnsNames.includes(keyPath[0])) {
          return formatEpochTime(Number(lowerCaseValue)).includes(value?.toLowerCase() ?? "");
        }
        return lowerCaseValue.includes(value?.toLowerCase() ?? "") || csListFlag;
      });
  });

  const selectedMarket: string = useSelector(selectMarket);
  const opco: string = useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  const [getFullSubscriberData, { isLoading }] = useLazyGetFullSubscribersQuery();
  const fetchFullData = async (filterResult: boolean) => {
    const res = await getFullSubscriberData(
      {
        msisdn: search?.msisdn,
        from: search?.fromDate ? getFormattedDate(search.fromDate) : undefined,
        to: search?.toDate ? getFormattedDate(search.toDate) : undefined,
        pageNo: 0,
        opco: opco,
        exportFlag: true,
      },
      true,
    );
    // Get all subscriptions with corresponding dumpNode names
    const allSubscriptions = [];
    for (const note of sortedNotes) {
      const subscriptions = tableData?.products?.[note.index]?.subscriptions || [];

      // Add dumpNode name to each subscription object
      subscriptions.forEach((subscription) => {
        const dumpNode = tableData?.products?.[note.index]?.DumpNode;
        if (dumpNode) {
          allSubscriptions.push({
            ...subscription,
            dumpNode: dumpNode,
          });
        }
      });
    }

    //tk73095, show full data in detailedJson aswell, added boolean to keep export function with filters
    if (!filterResult) return res?.data?.data?.products;

    return allSubscriptions;
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTableData.length) : 0;

  return (
    <div>
      {selectedRow && <SelectedSingleProduct subscription={selectedRow} onBackClick={handleBack} />}
      <div style={{ display: selectedRow ? "none" : undefined }}>
        {search.types === "subscriber" && (
          <FormControl style={{ marginBottom: 15 }} size="small">
            <Select
              onChange={(e) => {
                setSelectedNode(Number(e.target.value));
              }}
              value={selectedNode}
            >
              {sortedNotes.map((node) => (
                <MenuItem key={node.index + node.name} value={node.index}>
                  {node.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {tableData && (
          <>
            <TableContainer
              style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "50px" }}
              sx={{ maxHeight: "50vh" }}
              component={Paper}
            >
              <Table
                style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
                stickyHeader
                size="small"
                aria-label="user table"
              >
                <TableColumnFilterRow
                  columns={columns}
                  onChange={handleColumnValuesChange}
                  charsToTriggerOnChange={1}
                  columnFilterValues={search.colFilters}
                />
                <ProductsTableHead>
                  <TableRow>
                    <HeaderTableCell>Bundle Name</HeaderTableCell>
                    <HeaderTableCell>CAOC ID</HeaderTableCell>
                    <HeaderTableCell>Subscription ID</HeaderTableCell>
                    <HeaderTableCell>Activation Time</HeaderTableCell>
                    <HeaderTableCell>Bundle Entity State</HeaderTableCell>
                    <HeaderTableCell>Creation Time</HeaderTableCell>
                    <HeaderTableCell>Last Reset Time</HeaderTableCell>
                    <HeaderTableCell>Input Date</HeaderTableCell>
                    <HeaderTableCell>Bucket Name</HeaderTableCell>
                    <HeaderTableCell>Counter Name </HeaderTableCell>
                    <HeaderTableCell>Cs Name</HeaderTableCell>
                    <HeaderTableCell>Bucket Initial Value</HeaderTableCell>
                    <HeaderTableCell>Bucket Original Initial Value</HeaderTableCell>
                    <HeaderTableCell>Bucket Unused Value</HeaderTableCell>
                    <HeaderTableCell>Counter Current Value</HeaderTableCell>
                    <HeaderTableCell>Bucket Prev Current Value</HeaderTableCell>
                    <HeaderTableCell>Bucket Current Charging Step</HeaderTableCell>
                    <HeaderTableCell>Bucket Charging Step Up Time</HeaderTableCell>
                  </TableRow>
                </ProductsTableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? filteredTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : filteredTableData
                  ).map((subscription, index) => (
                    <ProductsTableRow
                      onClick={handleRowClick}
                      cols={subscription}
                      key={subscription?.id + index.toString()}
                    />
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TableFooter>
              <TablePagination
                component="div"
                count={filteredTableData.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <ButtonsWrapper>
                <ExportModal
                  selectedTab={CdrDashboardTabs.products}
                  data={filteredTableData}
                  fetchFullData={fetchFullData}
                  headers={headers}
                  isFetching={isLoading}
                />
                <DetailedJSON
                  data={[]}
                  tableName="Products"
                  fetchDataOnOpenPromise={fetchFullData}
                  isLoadingData={isLoading}
                />
              </ButtonsWrapper>
            </TableFooter>
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
