import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useGetServiceDataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import { ServiceDataFields } from "../subscriber-profiles-cdr/types";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { RenderTable } from "./render-table";
import { ServiceDataResponse } from "./table-data-types";
import { invertSortType, parseColFilterParam } from "../../lib/utils";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { useAppDispatch } from "../../lib/store/hooks";
import { setSearchResultField } from "../../lib/store/slices/services-data-slice";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import Box from "@mui/material/Box";
import { tableSortLabelStyle } from "../../theme";
import { getFormattedDate } from "../../lib/date-utils";

const StyledHeaderTableCell = styled(TableCell)`
  text-align: center;
`;

const ServiceDataTable: FunctionComponent<{ filters: ServiceDataFields }> = ({ filters }) => {
  const dispatch = useAppDispatch();
  const [representData, setRepresentData] = useState<ServiceDataResponse | undefined>();

  const handleColumnValuesChange = (columnValues: Record<string, string>) => {
    //remove focus from column filter textInput to stop the user from typing while performing API call
    (document.activeElement as HTMLElement).blur();
    let newSearchValue = { ...filters };
    newSearchValue.colFilters = columnValues;
    dispatch(setSearchResultField(newSearchValue));
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    let newSearchValue = { ...filters };
    newSearchValue.pageNo = newPage;
    dispatch(setSearchResultField(newSearchValue));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newSearchValue = { ...filters };

    newSearchValue.perPage = parseInt(event.target.value, 10);
    if (representData.total <= parseInt(event.target.value, 10)) newSearchValue.pageNo = 0;
    dispatch(setSearchResultField(newSearchValue));
  };

  const [orderColumn, setSortColumn] = useState<string>("Dump_Timestamp");
  const [orderType, setSortType] = useState<string>("desc");

  const handleChangeSort = (orderCol: string) => {
    if (orderColumn === orderCol) setSortType(invertSortType(orderType));
    else setSortColumn(orderCol);
  };

  useEffect(() => {
    setSortColumn("Dump_Timestamp");
    setSortType("desc");
  }, [filters.in]);

  // Check if dates are valid before making the API call
  const isFromDateValid = isDateTimeValid(filters?.from);
  const isToDateValid = isDateTimeValid(filters?.to);
  const selectedMarket: string = useSelector(selectMarket);
  const opco: string = React.useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  const { data, isFetching, error } = useGetServiceDataQuery({
    table: filters?.in,
    searchKeyword: filters?.searchFor,
    from: isFromDateValid ? getFormattedDate(filters.from) : undefined,
    to: isToDateValid ? getFormattedDate(filters.to) : undefined,
    perPage: filters.perPage,
    pageNo: filters.pageNo,
    sortColumn: orderColumn,
    sortType: orderType,
    colFilters: parseColFilterParam(filters.colFilters),
    opco: opco,
  });

  useEffect(() => {
    if (data) {
      setRepresentData(data);
    }
  }, [data]);

  const tableHeaders = useMemo(() => {
    if (isFetching) {
      return parseAndGetTableHeaders(representData);
    }
    return parseAndGetTableHeaders(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.table, filters.bqFieldsChangedCounter]);

  if ((error || !data) && !isFetching) {
    if (!isFromDateValid || !isToDateValid) {
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;
    }

    if (error) return <CustomErrorMessage error="Data could not be loaded" />;
  }

  const tableHeaderCells = tableHeaders.map((h) => (
    <StyledHeaderTableCell key={h}>
      <TableSortLabel
        active={orderColumn === h}
        direction={orderType === "desc" ? "desc" : "asc"}
        onClick={() => handleChangeSort(h)}
        sx={tableSortLabelStyle}
      >
        {h}
        {orderColumn === h ? (
          <Box component="span" sx={visuallyHidden}>
            {orderType === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </StyledHeaderTableCell>
  ));

  return (
    <>
      {isFetching ? <Loading /> : null}
      {representData && (
        <React.Fragment>
          {representData.data.length === 0 ? (
            <CustomErrorMessage
              error="No data found with the applied filters."
              notShowTitle={true}
            />
          ) : null}
          <div className={isFetching ? "disabled" : ""} style={{ marginTop: "10px" }}>
            <RenderTable
              tableHeaderCells={tableHeaderCells}
              tableHeaders={tableHeaders}
              handleColumnValuesChange={handleColumnValuesChange}
              data={representData}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              filters={filters}
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

const parseAndGetTableHeaders = (serviceData: ServiceDataResponse): string[] => {
  if (!serviceData?.data?.length) {
    return [];
  }
  const table = serviceData.table;
  //adding dump node and timestamp manually as they are not present in gcp json field, but they have a dedicated field
  //for some tables the response data structure is sligthly different after FIBQ implementation
  //tk 84423, example kafka table response is like: data:[{kafkaNotificationServer:{dump_node, dump_timestamp, kafkaNotificationServer:"{gcpJson}"}}]
  //in others like CAOC is: data:[{Dump_node,Dump_Timestamp, CAOC:"{gcpJson}"}]
  if (serviceData.data[0][serviceData.table][serviceData.table]) {
    return [
      ...Object.keys(serviceData.data[0][table]).filter((header) => header !== table),
      ...Object.keys(JSON.parse(serviceData?.data[0][table][table])),
    ];
  }
  if (serviceData.data[0][table]) {
    if (typeof serviceData.data[0][table] === "string")
      return [
        ...Object.keys(serviceData.data[0]).filter((header) => header !== table),
        ...Object.keys(JSON.parse(serviceData.data[0][table])),
      ];

    return [...Object.keys(serviceData.data[0][table]).filter((header) => header !== table)];
  }
};

export default ServiceDataTable;
