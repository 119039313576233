import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ButtonProps, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { colors } from "../../common/colors";
import { CdrEdrTableRow } from "./table-types";
import { getRecordElementFormattedValue } from "./utils";

const ColoredTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => (prop === "isColored" ? false : true),
})<{ isColored?: boolean }>`
  background: ${({ isColored }) =>
    isColored ? colors.lightGreyBackground : colors.fadedGreyBackground};
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  text-align: center;
  width: 200px;
  &.open {
    white-space: normal;
    overflow: auto;
  }
  :hover {
    cursor: pointer;
  }
`;

const CdrTableRow = ({
  cdrEdrTblRow,
  onClick,
  filteredColumns,
}: {
  cdrEdrTblRow: CdrEdrTableRow;
  filteredColumns: string[];
  onClick: (row: CdrEdrTableRow) => void;
}) => {
  const [extendRow, setExtendRow] = useState(false);

  const handleExpandButtonClick: ButtonProps["onClick"] = (e) => {
    e.stopPropagation();
    setExtendRow((prevExtendRow) => !prevExtendRow);
  };

  const cellClassName = extendRow ? "open" : undefined;
  // Create a copy of the recordElementsObject with the updated Dump_Timestamp date format
  const updatedRow = {
    ...cdrEdrTblRow,
  };

  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
      onClick={() => onClick(updatedRow)} // Pass the updated recordElementsObject to onClick
    >
      {filteredColumns?.map((col, i) => {
        const formattedValue = getRecordElementFormattedValue(cdrEdrTblRow.recordElement[col], col);
        return i === 0 ? (
          <ColoredTableCell className={cellClassName} key={col} isColored={i % 2 === 0}>
            <Button onClick={handleExpandButtonClick} endIcon={<ExpandMoreIcon />} />
            {formattedValue}
          </ColoredTableCell>
        ) : (
          <ColoredTableCell className={cellClassName} key={col} isColored={i % 2 === 0}>
            {formattedValue}
          </ColoredTableCell>
        );
      })}
      
    </TableRow>
  );
};

export default CdrTableRow;
