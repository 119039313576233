import { useDispatch } from "react-redux";
import { useAppSelector } from "../store/hooks";
import { selectUser, setUser } from "../store/slices/authentication-slice";
import { getToken, isAccessTokenExpired } from "../token-utils";
import { setMarket } from "../store/slices/market-slice";
import { Constants } from "../constants";

export const useIsLoggedIn = () => {
  const dispatch = useDispatch();
  let user = useAppSelector(selectUser);
  const token = getToken();

  if (!token) //no access token found, so no matter what you are not loggedIn
    return false;
  
  if (!user) {//maybe user refreshed the page, so we check if there is a session opened before
    const loggedUserJSON = localStorage.getItem(Constants.LOCAL_STORAGE_USER_KEY);
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON);
      dispatch(setUser(user));

      let selectedMarket = sessionStorage.getItem(Constants.SESSION_STORAGE_SELECTED_MARKET);
      if (!selectedMarket)
      selectedMarket = user.localMarket; //setting default market for app

      dispatch(setMarket(selectedMarket));
    }
  }
  
  //tk 50354
  if (isAccessTokenExpired())
      return false;

  return Boolean(user);
};
