import styled from "@emotion/styled";
import { TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { colors } from "../../common/colors";
import { BundleDetailsData, BundleDetailsResponseData } from "./table-types";
import { SearchFields } from "./types";
import { isDateTimeValid } from "./utils";
import { parseColFilterParam, validateFunctionForExcelExport } from "../../lib/utils";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import ExportModal from "../shared/export-modal";
import { useLazyGetBundleDetailsQuery, useLazyGetFullBundleDetailsQuery } from "../../lib/store/services/symphony";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { Loading } from "../shared/loading";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import { RootState } from "../../lib/store/store";
import { setBundleSearch } from "../../lib/store/slices/last-cdredr-slice";
import { useAppDispatch } from "../../lib/store/hooks";
import { getFormattedDate } from "../../lib/date-utils";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;



const BundleDetailsTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  text-align: center;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const HeaderTableCell = styled(TableCell)`
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 250px;
  white-space: nowrap;
`;


type BundleDetailsProps = {
  search?: SearchFields;
};

const BundleDetailsTable = ({
  search,
}: BundleDetailsProps) => {

  const dispatch = useAppDispatch();
  const searchBundle = useSelector((state: RootState) => state.lastCdrEdr.searchBundle);
  const isFromDateValid = isDateTimeValid(search?.fromDate);
  const isToDateValid = isDateTimeValid(search?.toDate);
  const selectedMarket: string = useSelector(selectMarket);
  const [tableData, setTableData] = React.useState<BundleDetailsData[]>()
  const [bundleDetailsData, setBundleDetailsData] = useState<BundleDetailsResponseData>({});

  const opco: string = useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  const [
    getBundleDetailsData,
    { isLoading, isFetching},
  ] = useLazyGetBundleDetailsQuery();
  const fetchBunldeDetailsData = async () => {
    const getFullDataQueryParams = {
      msisdn: search?.msisdn,
      from: isFromDateValid ? getFormattedDate(search.fromDate) : undefined,
      to: isToDateValid ? getFormattedDate(search.toDate) : undefined,
      pageNo: searchBundle.pageNo,
      perPage: searchBundle.perPage,
      opco: opco,
      colFilters: parseColFilterParam(searchBundle.colFilters),
      exportFlag: false,
    };

    let response;
    response = await getBundleDetailsData(getFullDataQueryParams, true);
    setBundleDetailsData(response?.data);
    setTableData(response.data.data)
  };

  React.useEffect(() => {
      fetchBunldeDetailsData();
  }, [searchBundle]);
  
  const [headers, setHeaders] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (tableData?.length >= 0 && bundleDetailsData.total != 0) {
      let allColumnNames: string[] = tableData.reduce(
        (columns: string[], row: BundleDetailsData) => {
          // Extract all unique column names from each row
          Object.keys(row).forEach((columnName) => {
            if (!columns.includes(columnName)) {
              columns.push(columnName);
            }
          });
          return columns;
        },
        [],
      );

      setHeaders(allColumnNames);
    }
  }, [headers.length, tableData]);
  const headersTable = headers.map((property) => {
    return {
      label: property,
      mapping: (row) => validateFunctionForExcelExport(row[property]),
    };
  });
 

  const [getFullBundleDetailsData, { isFetching: isFetchingFullBundleDetails }] =
    useLazyGetFullBundleDetailsQuery();

  const fetchFullData = async () => {
    const getFullDataQueryParams = {
      msisdn: search?.msisdn,
      from: search?.fromDate ? getFormattedDate(search.fromDate) : undefined,
      to: search?.toDate ? getFormattedDate(search.toDate) : undefined,
      pageNo: 0,
      perPage: 5000,
      opco: opco,
      exportFlag: true,
    };

      let response;
      response = await getFullBundleDetailsData(getFullDataQueryParams, true);
      return response?.data?.data;
  };

  


  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    let newSearchValue ={...searchBundle}
      newSearchValue.pageNo = newPage
      dispatch(setBundleSearch(newSearchValue));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
      let newSearchValue ={...searchBundle}
      newSearchValue.perPage = parseInt(event.target.value, 10)
      dispatch(setBundleSearch(newSearchValue));
    
  };

  const tableHeaders = useMemo(() => {
    return  bundleDetailsData?.data &&  bundleDetailsData?.data?.length && parseAndGetTableHeaders(bundleDetailsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundleDetailsData?.data]);
  const handleColumnValuesChange = useCallback((columnValues: Record<string, string>) => {
    //remove focus from column filter textInput to stop the user from typing while performing API call
    (document.activeElement as HTMLElement).blur();

    let newSearchValue ={...searchBundle}
    newSearchValue.colFilters = columnValues
    dispatch(setBundleSearch(newSearchValue));
 
  }, []);

  const showLoader: boolean = isFetching || isLoading;

  return (
    <div>
      {showLoader && <Loading />}
      <div className={showLoader ? "disabled" : ""}>
        <TableContainer
          style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "50px" }}
          sx={{ maxHeight: "50vh" }}
          component={Paper}
        >
          {tableData && tableData.length && (
            <Table
              className={isLoading ? "disabled" : ""}
              style={{ borderCollapse: "collapse", tableLayout: "fixed" }}
              stickyHeader
              size="small"
              aria-label="user table"
            >
              <BundleDetailsTableHead>
                <TableRow>
                  {bundleDetailsData.total != 0 &&
                    Object.keys(tableData[0])?.map((tableHeader) => {
                      return <HeaderTableCell>{tableHeader}</HeaderTableCell>;
                    })}
                </TableRow>
              </BundleDetailsTableHead>
              <TableBody>
                <TableColumnFilterRow
                  columns={tableHeaders}
                  onChange={handleColumnValuesChange}
                  charsToTriggerOnChange={1}
                  columnFilterValues={searchBundle.colFilters}
                />
                {tableData instanceof Array &&
                  bundleDetailsData.total != 0 &&
                  tableData?.map((row: Object) => {
                    return (
                      <TableRow>
                        {Object.entries(row).map((cellValue: object) => {
                          return <TableCell align="center">{cellValue[1]}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TableFooter>
          <TablePagination
            component="div"
            count={bundleDetailsData.total ? bundleDetailsData.total : 0}
            page={searchBundle.pageNo}
            onPageChange={handleChangePage}
            rowsPerPage={searchBundle.perPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ExportModal
            headers={headersTable}
            data={tableData}
            selectedTab={CdrDashboardTabs.bundleDetails}
            fetchFullData={fetchFullData}
            isFetching={isFetchingFullBundleDetails}
          />
        </TableFooter>
      </div>
    </div>
  );
};

export default React.memo(BundleDetailsTable);
const parseAndGetTableHeaders = (bundleDetailsData: BundleDetailsResponseData): string[] => {
  if (!bundleDetailsData?.data?.length) {
    return [];
  } else {
    console.log("[...Object.keys(bundleDetailsData)]", Object.keys(bundleDetailsData.data[0]));
    return Object.keys(bundleDetailsData.data[0]);
  }
};
