import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import NotAuthenticatedNotice from "./NotAuthenticatedNotice";

interface ProtectedRouteProps {
  userLoggedIn: boolean;
  tokenExpired: boolean;
  children?: JSX.Element; 
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ userLoggedIn, tokenExpired, children }) => {
  const location = useLocation();

  if (!userLoggedIn || tokenExpired) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page. ---> this currently does not work,todo (investigated during ticket 50354)
    const noticeMessage = tokenExpired ? "Your session expired, you need to be authenticated again." 
    : "You need to be authenticated to access this resource.";
    return <NotAuthenticatedNotice originLocation={location} message={noticeMessage}/>;
  }

  return children;
};
