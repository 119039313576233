const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Group_85"
    data-name="Group 85"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_108" data-name="Rectangle 108" width="15" height="15" fill="#e60000" />
      </clipPath>
    </defs>
    <g id="Group_84" data-name="Group 84" clipPath="url(#clip-path)">
      <path
        id="Path_132"
        data-name="Path 132"
        d="M1.61,8.886c-.177-.015-.339-.016-.5-.045A1.377,1.377,0,0,1,.328,6.6c.035-.041.072-.08.11-.118Q3.465,3.453,6.495.429a1.739,1.739,0,0,1,.411-.3A1.438,1.438,0,0,1,8.448.353c.216.226.443.442.664.663q2.717,2.715,5.43,5.433a1.5,1.5,0,0,1,.341.469,1.37,1.37,0,0,1-1.217,1.95c-.082,0-.164,0-.261,0,0,.074,0,.131,0,.188,0,1.432,0,2.863,0,4.295a1.6,1.6,0,0,1-1.251,1.6,1.962,1.962,0,0,1-.436.044c-.743,0-1.486,0-2.229,0-.372,0-.539-.164-.539-.533q0-1.678,0-3.357a.747.747,0,0,0-.828-.823c-.42,0-.84,0-1.261,0a.738.738,0,0,0-.8.8q0,1.686,0,3.371c0,.383-.162.546-.542.546-.733,0-1.467-.016-2.2,0A1.663,1.663,0,0,1,1.6,13.276c.025-1.4.006-2.8.006-4.207V8.886m8.22,5.231h1.856a.749.749,0,0,0,.839-.84q0-2.389,0-4.779a.454.454,0,0,1,.507-.507c.2,0,.4,0,.6,0a.5.5,0,0,0,.491-.4.586.586,0,0,0-.232-.552Q10.92,4.069,7.951,1.1a.532.532,0,0,0-.889,0l-5.3,5.3c-.242.242-.485.482-.726.726a.5.5,0,0,0,.36.867h.543c.388,0,.549.162.549.554q0,2.389,0,4.779a.738.738,0,0,0,.8.792H5.033c.047,0,.094,0,.152-.008,0-.069,0-.127,0-.185,0-.992-.008-1.984.006-2.976A1.558,1.558,0,0,1,6.608,9.428c.6-.04,1.2-.008,1.8-.007a.669.669,0,0,1,.144.022,1.6,1.6,0,0,1,1.274,1.595c.006.963,0,1.925,0,2.888v.19"
        transform="translate(0 0)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default HomeIcon;
