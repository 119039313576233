import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { colors } from "../../common/colors";
import RefreshIcon from "../../icons/refresh-icon";
import { Page } from "../shared/Page";
import { GetMeasurementsTableKPIData } from "../subscriber-profiles-cdr/types";
import MeasurementsForm from "./measurements-form";
import MeasurementsGraph from "./measurements-graph";
import { setSearchResultField } from "../../lib/store/slices/measurements-slice";
import { useAppDispatch } from "../../lib/store/hooks";

const RefreshButton = styled(Button)`
  width: 145px;
  height: 32px;
  background: ${colors.brightRed};
  text-transform: none;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const MeasurementsDashboard = () => {
  const [checked, setChecked] = useState<Record<string, boolean | undefined>>({});
  const [checkedChildren, setCheckedChildren] = useState<
    Record<string, Record<string, boolean | undefined>>
  >({});
  const [dates, setDates] = useState<GetMeasurementsTableKPIData | undefined>();

  const dispatch = useAppDispatch();

  const handleCheckChild = (parent: string, child: string, value: boolean) => {
    setCheckedChildren((children) => ({
      ...children,
      [parent]: { ...children[parent], [child]: value },
    }));
  };
  const handleSetSearchResultField = (value) => {
    dispatch(setSearchResultField(value));
  };

  const handleChecked = (name: string, value: boolean, children: string[]) => {
    setChecked((prev) => ({ ...prev, [name]: value }));
    setCheckedChildren((prevChildren) => ({
      ...prevChildren,
      [name]: value
        ? children.reduce<Record<string, boolean>>((acc, ch) => {
            acc[ch] = true;
            return acc;
          }, {})
        : undefined,
    }));
  };

  const checkedKeysList = useMemo(
    () =>
      Object.entries(checked)
        .filter(([, value]) => Boolean(value))
        .map(([key]) => {
          const fields = Object.entries(checkedChildren[key])
            .filter(([, value]) => Boolean(value))
            .map(([childName]) => childName);
          return { table: key, fields };
        }),
    [checked, checkedChildren],
  );

  return (
    <Page
      title="Measurements"
      actionButton={
        <RefreshButton onClick={() => window.location.reload()} variant="contained">
          <RefreshIcon />
          Refresh
        </RefreshButton>
      }
    >
      <Wrapper>
        <MeasurementsForm
        onSearch={handleSetSearchResultField}
          setDates={setDates}
          checked={checked}
          checkedChildren={checkedChildren}
          handleChange={handleChecked}
          handleCheckChild={handleCheckChild}
          dates={dates}
        />
        {dates && checkedKeysList && (
          <MeasurementsGraph from={dates.from} to={dates.to} kpiTables={checkedKeysList} />
        )}
      </Wrapper>
    </Page>
  );
};

export default MeasurementsDashboard;
