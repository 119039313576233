import styled from "@emotion/styled";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { colors } from "../../common/colors";

const ButtonsContainer = styled.div`
  display: flex;
  min-width: 300px;
  border: solid 3px #00000029;
  border-radius: 34px;
  margin: 0 20px 20px 0;
  height: 55px;
  align-items: center;
`;

const hoverButtonStyles = `
  color: ${colors.textWhite};
  background: ${colors.greyBackground};
`;

const DropDownButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  margin-right: 15px;

  min-width: 100px;
  height: 50px;
  color: #000000;
  background: ${colors.greyBackground};
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 36px;
  opacity: 1;
  ${({ isActive }) => (isActive ? hoverButtonStyles : "")}
  &:hover {
    ${hoverButtonStyles}
  }
`;

const Input = styled.input`
  max-width: 160px;
  border: none;
  min-height: 50px;
  outline: 0;
`;

const AdminSearchButton = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleSelectUsersFilterClick = () => {
    setSelectedFilter("User");
    setOpen(false);
  };

  const handleSelectRolesFilterClick = () => {
    setSelectedFilter("Roles");
    setOpen(false);
  };

  const handleSelectLocalMarketFilterClick = () => {
    setSelectedFilter("Local Market");
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <ButtonsContainer>
      <Stack direction="row" spacing={2}>
        <div>
          <DropDownButton
            isActive={
              selectedFilter === "Users" ||
              selectedFilter === "Roles" ||
              selectedFilter === "Local Market"
            }
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            All
          </DropDownButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleSelectUsersFilterClick}>Users</MenuItem>
                      <MenuItem onClick={handleSelectRolesFilterClick}>Roles</MenuItem>
                      <MenuItem onClick={handleSelectLocalMarketFilterClick}>Local Market</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
      <Input type="text" placeholder="Text..." />
    </ButtonsContainer>
  );
};

export default AdminSearchButton;
