export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const tableSortLabelStyle = {
  "&.MuiTableSortLabel-root": {
    color: "#fff",
  },
  "&.MuiTableSortLabel-root:hover": {
    color: "#fff",
  },
  "&.Mui-active": {
    color: "#fff",
  },
  "& .MuiTableSortLabel-icon": {
    color: "#fff !important",
  },
};