import styled from "@emotion/styled";
import { FormControl, MenuItem, Select } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../common/colors";
import CustomErrorMessage from "../custom-error-message";
import ExportModal from "../shared/export-modal";
import ProfileDetailedJSONModal from "./profile-detailed-json-modal";
import { ProfileInfoTableData } from "./table-types";
import { orderBy } from "lodash";
import { SearchFields } from "./types";
import { getFormattedDate } from "../../lib/date-utils";
import React from "react";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const HeaderTableCell = styled.div`
  height: 55px;
  text-align: left;
  padding: 15px 0 0 25px;
`;

const OddInformationTableCell = styled.div`
  height: 55px;
  text-align: left;
  padding: 15px 0 0 25px;
  background: ${colors.fadedGreyBackground};
  overflow-y: auto;
  overflow-x: hidden;
`;
const EvenInformationTableCell = styled.div`
  height: 55px;
  text-align: left;
  padding: 15px 0 0 25px;
  background: ${colors.lightGreyBackground};
  overflow-y: auto;
  overflow-x: hidden;
`;

const HeaderWrapper = styled.div`
  border-radius: 20px 0px 0px 20px;
  width: 230px;
  background: ${colors.vodafoneRed};
  color: ${colors.textWhite};
  height: 100%;
  width: 20%;
`;

const InformationColumnWrapper = styled.div`
  background: lightgrey;
  width: 100%;
  height: 100%;
  width: 80%;
`;

const InformationColumn = styled.div`
  background: ${colors.textWhite};
`;

const Wrapper = styled.div`
  display: flex;
  max-height: 50vh;
  height: 100%;
  overflow-y: auto; /* Add this to enable scrolling if the content overflows */
`;

const ProfileInfo: FunctionComponent<{
  tableData: ProfileInfoTableData;
  selectedTab?: string;
  search?: SearchFields;
}> = ({ tableData, selectedTab, search }) => {
  const sortedNodes = orderBy(
    tableData?.products?.slice(0, 2).map((p, i) => ({ index: i, name: p.DumpNode })),
    (n) => n.name,
  );
  const [selectedNode, setSelectedNode] = useState<number>(sortedNodes && sortedNodes[0] ? sortedNodes[0].index : 0);

  const parsedAccount =
    tableData.profiles.accounts.length > 0 ? tableData.profiles.accounts[selectedNode] : undefined;

  const dynamicData: { label: string; value: string }[] = parsedAccount && Object.keys(parsedAccount).map(
    (property) => {
      return {
        label: property,
        //some properties can be json objects, show them as string.
        //dates are returned as an object in a .value property
        value:
          typeof parsedAccount[property] === "object"
            ? parsedAccount[property]?.value
              ? getFormattedDate(parsedAccount[property].value)
              : JSON.stringify(parsedAccount[property])
            : parsedAccount[property],
      };
    },
  );

  // Create an object to represent the table data
  const tableDataObject = {};

  dynamicData?.forEach((header) => {
    tableDataObject[header.label] = header.value;
  });

  //tk 73095, all detailed json buttons must show the whole dataset in the json viewer
  //fixed in the backend
  //const [getFullSubscriberData, { isFetching }] = useLazyGetFullSubscribersQuery();

  // const selectedMarket: string = useSelector(selectMarket);
  // const opco: string = React.useMemo(() => {
  //   return retrieveOpCoFromMarket(selectedMarket);
  // }, [selectedMarket]);

  // const fetchFullData = async () => {
  //   const result = await getFullSubscriberData(
  //     {
  //       msisdn: search?.msisdn,
  //       from: search?.fromDate ? getFormattedDate(search.fromDate) : undefined,
  //       to: search?.toDate ? getFormattedDate(search.toDate) : undefined,
  //       pageNo: 0,
  //       opco: opco,
  //       exportFlag: true,
  //     },
  //     true,
  //   );

  //   return result.data.data;
  // };

  return (
    <div>
      <FormControl style={{ marginBottom: 15 }} size="small">
        {sortedNodes && sortedNodes.length > 0 && (
          <Select
            onChange={(e) => {
              setSelectedNode(Number(e.target.value));
            }}
            value={selectedNode}
          >
            {sortedNodes.map((node) => (
              <MenuItem key={node.index + node.name} value={node.index}>
                {node.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <Wrapper>
        {!dynamicData && (
          <CustomErrorMessage
            error={"No data found with the applied filters."}
            notShowTitle={true}
          />
        )}
        <HeaderWrapper>
          {dynamicData?.map((cell) => (
            <HeaderTableCell>{cell.label}</HeaderTableCell>
          ))}
        </HeaderWrapper>
        <InformationColumnWrapper>
          <InformationColumn>
            {/* remove start and end quotes from displayed values as requested */}
            {dynamicData?.map((cell, index) => {
              return index % 2 === 0 ? (
                <OddInformationTableCell>
                  {cell.value.startsWith('"') && cell.value.endsWith('"')
                    ? cell.value.slice(0, -1).replace('"', "")
                    : cell.value}
                </OddInformationTableCell>
              ) : (
                <EvenInformationTableCell>
                  {cell.value.startsWith('"') && cell.value.endsWith('"')
                    ? cell.value.slice(0, -1).replace('"', "").replace('"', "")
                    : cell.value}
                </EvenInformationTableCell>
              );
            })}
          </InformationColumn>
        </InformationColumnWrapper>
      </Wrapper>
      <TableFooter>
        {dynamicData && (
          <React.Fragment>
            <ProfileDetailedJSONModal
              accountsData={tableData.profiles.fullAccountsJson}
              devicesData={tableData.products}
              tableName="Profile info"
            />
            <ExportModal headers={dynamicData} data={tableDataObject} selectedTab={selectedTab} />
          </React.Fragment>
        )}
      </TableFooter>
    </div>
  );
};

export default ProfileInfo;
