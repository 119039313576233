import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";
import LogoImg from "../../images/logo.png";

const LogoBox = styled(Box)`
  margin: 5% 20% 5% 12%;
`;

const Logo = ({ sx }: BoxProps) => (
  <LogoBox
    component="img"
    // @ts-ignore
    src={LogoImg}
    sx={{ width: 116, height: 40, ...sx }}
  />
);

export default Logo;
