import styled from "@emotion/styled";
import { Tab, Tabs, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Route, Routes, useNavigate } from "react-router";
import { colors } from "../../common/colors";
import { adminSubroutes, routes } from "../../lib/routes";
import AddNewRole from "../roles/add-new-role";
import { Page } from "../shared/Page";
import { CreateUserComponent } from "../user/create-user";
import UserTable from "../user/user-table";
import AdminRoles from "./admin-roles";
import AdminSearchButton from "./admin-search-button";
import EditRoleComponent from "./edit-role";
import EditUserComponent from "./edit-user-component";
import StatusTable from "./status-table";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import { useAppSelector } from "../../lib/store/hooks";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { MarketRole } from "../subscriber-profiles-cdr/types";

const TabsWrapper = styled(Tabs)({
  marginLeft: "2%",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const UserTab = styled(Tab)`
  background-color: ${colors.lightGreyBackground};
  width: 322px;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 34px;
  &.Mui-selected {
    background: ${colors.vodafoneRed};
    color: white;
  }
`;

const UserTitle = styled(Typography)`
  margin-bottom: 2%;
`;

const TabsBox = styled(Box)`
  margin-top: 15px;
`;

const TabPanel = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <Box sx={{ p: 4 }}>{children}</Box>;
};

const tabProp = (index: number) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
});

const subroutes = [
  adminSubroutes.users,
  adminSubroutes.roles,
  adminSubroutes.status,
  adminSubroutes.configuartion,
];

const AdminDashboardInner = ({ selectedTab }: { selectedTab: number }) => {
  const navigate = useNavigate();

  const { data } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useAppSelector(selectMarket);

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole:MarketRole = data?.data?.roles.find(role => role.market.toLowerCase() === userSelectedMarket.toLowerCase());
  
  const handleChange = (_event: any, newSelectedTab: number) => {
    navigate(`${routes.admin}/${subroutes[newSelectedTab]}`);
  };

  return (
    <Page title="Admin Console">
      <TabsBox>
        <TabsWrapper value={selectedTab} onChange={handleChange} aria-label="tabs">
          {currentRole && currentRole.view_user && <UserTab label="Users" {...tabProp(0)} />}
          {currentRole && currentRole.view_role && <UserTab label="Roles" {...tabProp(1)} />}       
          <UserTab style={{ marginRight: "2%" }} label="Status" {...tabProp(2)} />
          {/* <UserTab label="Configurations" {...tabProp(3)} /> */}
        </TabsWrapper>
      </TabsBox>

      {selectedTab === 0 && (
        <TabPanel>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <UserTitle variant="h4">Users</UserTitle>
              <div style={{ display: "flex" }}>
                <AdminSearchButton />
              </div>
            </div>
            <UserTable />
          </div>
        </TabPanel>
      )}

      {selectedTab === 1 && (
        <TabPanel>
          <UserTitle variant="h4">Roles</UserTitle>
          <AdminRoles />
        </TabPanel>
      )}

      {selectedTab === 2 && (
        <TabPanel>
          <UserTitle variant="h4">Latest Files</UserTitle>
          <StatusTable />
        </TabPanel>
      )}
      {/* {selectedTab === 3 && (
        <TabPanel>
          <UserTitle variant="h4">Configurations</UserTitle>
          <AdminConfig />
        </TabPanel>
      )} */}
    </Page>
  );
};

const AdminDashboard = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminDashboardInner selectedTab={0} />} />
      <Route path={adminSubroutes.users} element={<AdminDashboardInner selectedTab={0} />} />
      <Route path={adminSubroutes.roles} element={<AdminDashboardInner selectedTab={1} />} />
      <Route path={adminSubroutes.status} element={<AdminDashboardInner selectedTab={2} />} />
      {/* <Route
        path={adminSubroutes.configuartion}
        element={<AdminDashboardInner selectedTab={3} />}
      /> */}
      <Route path={adminSubroutes.addUser} element={<CreateUserComponent />} />
      <Route path={`${adminSubroutes.editUser}/:id`} element={<EditUserComponent />} />
      <Route path={adminSubroutes.addRole} element={<AddNewRole />} />
      <Route path={`${adminSubroutes.editRole}/:id`} element={<EditRoleComponent />} />
    </Routes>
  );
};

export default AdminDashboard;
