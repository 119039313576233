import styled from "@emotion/styled";
import { MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FunctionComponent, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { routes } from "../../lib/routes";
import { useEditExportMutation, useGetExportQuery,useExportQueriesQuery } from "../../lib/store/services/symphony";
import { EditExportForm, Export } from "../../lib/types/export-types";
import CustomErrorMessage from "../custom-error-message";
import { FormikRadioGroup } from "../formik-fields/FormikRadioGroup";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { Loading } from "../shared/loading";
import { Page } from "../shared/Page";
import { ExecutionType } from "./export-utilities";
import RecipientsDataDropdown from "./recipients-data-dropdown";
import { FormikDateTimePicker } from "../formik-fields/FormikDateTimePicker";

const InputTitle = styled(Typography)`
  margin-bottom: 10px;
`;

const CyclicContainer = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const EditExportComponent = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading, error } = useGetExportQuery(id);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <CustomErrorMessage error="Data can not be loaded" />;
  }

  return <EditExportInner exportData={data.data} />;
};

const EditExportInner: FunctionComponent<{ exportData: Export }> = ({ exportData }) => {
  const [editExport, { isLoading, error, data }] = useEditExportMutation();
  const {
    data: exportQueriesData,
    isLoading: exportQueriesIsLoading,
    error: exportQueriesError,
  } = useExportQueriesQuery(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (!error && !isLoading && data) {
      navigate(`${routes.exports}`);
    }
  }, [data, error, isLoading, navigate]);
  const formik = useFormik<EditExportForm>({
    initialValues: {
      name: exportData.name,
      endpoint: exportData.endpoint,
      description: exportData.description,
      retentionPeriod: exportData.retentionPeriod,
      fileNameRules: exportData.fileNameRules,
      predefinedQueries: exportData.predefinedQueries,
      executionType: exportData.exectutionOptions.type,
      executionHoursSchedule:
        exportData.exectutionOptions.type === ExecutionType.CyclicHrs
          ? exportData.exectutionOptions.schedule
          : "",
      executionDaysSchedule:
        exportData.exectutionOptions.type === ExecutionType.CyclicDays
          ? exportData.exectutionOptions.schedule
          : "",
      firstExecution: exportData.firstExecution,
      recipients: exportData.recipients && exportData.recipients.length>0 && exportData.recipients.user >0 ? exportData.recipients.map((r) => r.id):[],
    },
    validationSchema: yup.object({
      name: yup.string().min(4),
      endpoint: yup.string().min(4).required("Please select a date"),
      retentionPeriod: yup.string().required("Please select a retention period"),
      fileNameRules: yup.string().required("Please select file name rule"),

      executionType: yup.string().required("Please select one of the options"),
      executionHoursSchedule: yup.number().when("executionType", {
        is: ExecutionType.CyclicHrs,
        then: yup.number().required("Please insert number of hours"),
      }),
      executionDaysSchedule: yup.number().when("executionType", {
        is: ExecutionType.CyclicDays,
        then: yup.number().required("Please insert number of days"),
      }),
      firstExecution: yup.date().required("Please select the first execution date"),
      description: yup.string().required().min(4),
      recipients: yup
        .array(yup.string())
    }),
    onSubmit: (values) => {
      
      const { executionType, executionDaysSchedule, executionHoursSchedule, ...rest } = values;
      let schedule: string;
      switch (executionType) {
        case ExecutionType.CyclicDays:
          schedule = executionDaysSchedule;
          break;
        case ExecutionType.CyclicHrs:
          schedule = executionHoursSchedule;
          break;
        default:
          schedule = "";
      }

      editExport({
        ...rest,
        id: exportData.id,
        exectuionOptions: { type: executionType, schedule },
      });
      navigate(`${routes.exports}`);
    },
  });

  useEffect(() => {
    // Reset value on change of execution type changes to different from hrs
    if (
      formik.values.executionType !== ExecutionType.CyclicHrs &&
      formik.values.executionHoursSchedule !== ""
    ) {
      formik.setFieldValue("executionHoursSchedule", "", true);
    }

    // Reset value on change of execution type to different from days
    if (
      formik.values.executionType !== ExecutionType.CyclicDays &&
      formik.values.executionDaysSchedule !== ""
    ) {
      formik.setFieldValue("executionDaysSchedule", "", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.executionType,
    formik.values.executionHoursSchedule,
    formik.values.executionDaysSchedule,
  ]);

  const queryDetailsList = ["select * from devices"];

  return (
    <Page title="Exports" subtitle="Edit export" withBox>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputTitle>Export Name</InputTitle>
                <FormikTextField disabled fullWidth name="name" formik={formik} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputTitle>Description</InputTitle>
                <FormikTextField fullWidth name="description" multiline formik={formik} />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputTitle>End Point</InputTitle>
                <FormikTextField
                  disabled
                  fullWidth
                  variant="outlined"
                  name="endpoint"
                  formik={formik}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputTitle>Retention period on Symphony</InputTitle>
                <FormikTextField
                  fullWidth
                  variant="outlined"
                  name="retentionPeriod"
                  formik={formik}
                />
              </FormControl>
              {/* <FormControl fullWidth margin="normal">
                <InputTitle>Export file name rules</InputTitle>
                <FormikTextField
                  disabled
                  fullWidth
                  variant="outlined"
                  name="fileNameRules"
                  formik={formik}
                />
              </FormControl> */}

              {exportData.type !== "legacy" ? (
              <FormControl fullWidth margin="normal">
              <InputTitle>Query details</InputTitle>
              <FormikSelect
                placeholder="Query details"
                fullWidth
                variant="outlined"
                name="predefinedQueries"
                formik={formik}
              >
                {!exportQueriesIsLoading &&
                  exportQueriesData.data &&
                  exportQueriesData.data.map((ql) => (
                    <MenuItem key={ql.id} value={ql.id}>
                      {ql.query}
                    </MenuItem>
                  ))}
              </FormikSelect>
            </FormControl>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Recurrency</FormLabel>
                <FormikRadioGroup aria-label="Execution" name="executionType" formik={formik}>
                  <FormControlLabel value={ExecutionType.Now} control={<Radio />} label="Now" />
                  <FormControlLabel
                    value={ExecutionType.OneShot}
                    control={<Radio />}
                    label="One Shot"
                  />
                  <CyclicContainer>
                    <FormControlLabel
                      value={ExecutionType.CyclicHrs}
                      control={<Radio />}
                      label="Cyclic hrs"
                    />
                    <FormikTextField
                      type="number"
                      disabled={formik.values.executionType !== "cyclichrs"}
                      name="executionHoursSchedule"
                      formik={formik}
                    />
                  </CyclicContainer>
                  <CyclicContainer>
                    <FormControlLabel
                      name="executionType"
                      value={ExecutionType.CyclicDays}
                      control={<Radio />}
                      label="Cyclic days"
                    />
                    <FormikTextField
                      type="number"
                      disabled={formik.values.executionType !== "cyclicdays"}
                      name="executionDaysSchedule"
                      formik={formik}
                    />
                  </CyclicContainer>
                </FormikRadioGroup>
                <FormControl fullWidth margin="normal">
                  <InputTitle>First execution</InputTitle>
                  <FormikDateTimePicker name="firstExecution" formik={formik} />
                </FormControl>
                {exportData.type !== "legacy" ? (
                  <FormControl fullWidth margin="normal">
                    <InputTitle>Recipients</InputTitle>
                    <RecipientsDataDropdown name="recipients" formik={formik} />
                  </FormControl>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item sm={4}>
              <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Output File Format</FormLabel>
                <FormikRadioGroup
                  aria-disabled
                  aria-label="Publish method"
                  name="outputFileFormat"
                  formik={formik}
                  row
                >
                  <FormControlLabel value={"json"} control={<Radio />} label="Json" />
                  <FormControlLabel value={"csv"} control={<Radio />} label="CSV" />
                  {/* <FormControlLabel value={"xml"} control={<Radio />} label="Xml" /> */}
                </FormikRadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid xs={12} sm={4}></Grid>
          <FormControl>
            <LoadingButton variant="contained" type="submit" loading={isLoading}>
              Save changes
            </LoadingButton>
          </FormControl>
        </Box>
      </form>
    </Page>
  );
};

export default EditExportComponent;
