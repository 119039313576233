import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import DeleteIcon from "../../icons/delete-icon";
import EditIcon from "../../icons/edit-icon";
import { adminSubroutes, routes } from "../../lib/routes";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import { User } from "../../lib/types/user-types";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import DeleteUserComponent from "./delete-users-modal";
import UserActivationToggle from "./user-action-toggle";
import UserInformationModal from "./user-information-modal";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UsersActionCell: FunctionComponent<{ user: User }> = ({ user }) => {
  const { data, isLoading, error } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);
  
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

    //UK-onboarding one role object for each market, retrieve role for user's selected market
    const currentRole = data?.data?.roles.find(
      (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
    );
  
  return (
    <IconWrapper>
      <div>
        <UserInformationModal user={user} />
      </div>
      {currentRole && currentRole.edit_user ? (
        <div>
          <Button
            variant="text"
            component={RouterLink}
            to={`${routes.admin}/${adminSubroutes.editUser}/${user.id}`}
          >
            <EditIcon />
          </Button>
        </div>
      ) : (
        <div>
          <Button
            onClick={() => {
              alert("You do not have this access for this app");
            }}
            variant="text"
          >
            <EditIcon />
          </Button>
        </div>
      )}
      {currentRole && currentRole.delete_user ? (
        <DeleteUserComponent id={user.id} />
      ) : (
        <Button
          onClick={() => {
            alert("You do not have this access for this app");
          }}
        >
          <DeleteIcon />
        </Button>
      )}
      <UserActivationToggle id={user.id} isEnabled={user.status === "active"} />
    </IconWrapper>
  );
};

export default UsersActionCell;
