
import React from "react";
import { Route, Routes } from "react-router";
import {  subscriberProfilesCdrSubroutes } from "../../lib/routes";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import { SearchFields } from "./types";
import CdrDashboardInner from "./cdr-inner-dashboard";

const CdrDashboard = ({ search }: { search: SearchFields; }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          search.types === "subscriber" ? (
            <CdrDashboardInner
              search={search}
              selectedTab={CdrDashboardTabs.profileInfo}
            />
          ) : (
            <CdrDashboardInner
              search={search}
              selectedTab={CdrDashboardTabs.lastCdrs}
            />
          )
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.lastCdrs}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.lastCdrs}
          />
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.lastEdrs}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.lastEdrs}
          />
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.products}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.products}
          />
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.customCounter}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.customCounter}
          />
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.bundleDetails}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.bundleDetails}
          />
        }
      />
      <Route
        path={subscriberProfilesCdrSubroutes.profileInfo}
        element={
          <CdrDashboardInner
            search={search}
            selectedTab={CdrDashboardTabs.profileInfo}
          />
        }
      />
    </Routes>
  );
};
export default React.memo(CdrDashboard);


