const PauseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g id="noun_pause_1738025" transform="translate(-16 -16)">
      <g id="Group_867" data-name="Group 867" transform="translate(16 16)">
        <path
          id="Path_477"
          data-name="Path 477"
          d="M16,24.989A9,9,0,1,1,24.989,34,8.983,8.983,0,0,1,16,24.989ZM21.875,28.5v-7a.523.523,0,0,1,.53-.53h1.3a.523.523,0,0,1,.53.53v7a.537.537,0,0,1-.53.53H22.4A.537.537,0,0,1,21.875,28.5Zm3.887,0v-7a.523.523,0,0,1,.53-.53h1.281a.51.51,0,0,1,.53.53v7a.523.523,0,0,1-.53.53H26.292A.537.537,0,0,1,25.762,28.5Z"
          transform="translate(-16 -16)"
          fill="#f48d00"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export default PauseIcon;
