const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="Group_1181" data-name="Group 1181" transform="translate(-1088 -311)">
      <circle
        id="Ellipse_48"
        data-name="Ellipse 48"
        cx="14"
        cy="14"
        r="14"
        transform="translate(1088 311)"
        fill="#ff5c5c"
      />
      <g id="noun_edit_2953917" transform="translate(1074.555 297.557)">
        <path
          id="Path_329"
          data-name="Path 329"
          d="M21.885,34.358A.44.44,0,0,0,22,34.341l3.037-.851a.44.44,0,0,0,.193-.113l9-9a.44.44,0,0,0,0-.623l-2.186-2.186a.44.44,0,0,0-.623,0l-9,9a.44.44,0,0,0-.113.193L21.461,33.8a.44.44,0,0,0,.424.559Zm1.068-2.619,1.124,1.107-1.557.436Zm1.97.7-1.576-1.551,6.866-6.866,1.6,1.526Zm6.809-9.936L33.3,24.07l-.859.858-1.6-1.526Z"
          transform="translate(0 0)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export default EditIcon;
