import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const measurementsSlice = createSlice({
  name: "measurements",
  initialState: {
    searchResultField: undefined,
  },
  reducers: {
    setSearchResultField: (state, action) => {
      state.searchResultField = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchResultField)
      setLocalStorageSearch && localStorage.removeItem("searchMeasurements")
      setLocalStorageSearch &&  localStorage.setItem("searchMeasurements",setLocalStorageSearch);
    },
  },
});

export const { setSearchResultField } = measurementsSlice.actions;
export const selectLastMeasurementsSearch = (state: RootState) => {
const searchValue:string = localStorage.getItem("searchMeasurements");

  if (state.measurements.searchResultField) {
    return state.measurements.searchResultField
  } else {
    return JSON.parse(searchValue)
  }
}
export default measurementsSlice.reducer;
