import styled from "@emotion/styled";
import avatar from "../../images/avatar.png";
import { useAppDispatch, useAppSelector } from "../../lib/store/hooks";
import { selectUser } from "../../lib/store/slices/authentication-slice";
import { MarketRole } from "../subscriber-profiles-cdr/types";
import { selectMarket, setMarket } from "../../lib/store/slices/market-slice";
import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps } from "@mui/material";

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  align-content: center;
`;

const selectStyle: SxProps = {
  color: "white",
  ":before": { borderBottomColor: "white" },
  // underline when selected
  ":after": { borderBottomColor: "white" },
  "&:hover:not(.Mui-disabled):before": {
    borderColor: "white",
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent",
},
};

const UsernameContainer = styled.div`
  margin: 0 auto;
  margin-top: 10px;
`;

interface UserInfoProps {
  userRoles: MarketRole[];
}

const UserInfo = (props: UserInfoProps) => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const userSelectedMarket: string = useAppSelector(selectMarket);

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setMarket(event.target.value));
  };

  const roles: MarketRole[] = props.userRoles;
  //if user multiple has multiple roles, means he has access to different markets, show market select option
  const showMarketSelection: boolean = roles?.length > 1;

  return (
    <UserContainer>
      <img alt="" src={avatar} />
      <UsernameContainer>{user.email}</UsernameContainer>

      {showMarketSelection && (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={userSelectedMarket}
            sx={selectStyle}
            onChange={handleChange}
            labelId="marketLabel"
            id="marketSelect"
            label="Market"
          >
            {roles.map((role) => (
              <MenuItem value={role.market}>{role.market.toUpperCase()}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </UserContainer>
  );
};

export default UserInfo;
