import { ServiceDataResponse } from "./table-data-types";

type status = {
  data: ServiceDataResponse[];
};

type Action = { type: "setData"; payload: ServiceDataResponse[] };

export const initialStatus: status = {
  data: [],
};

export const serviceDataReducer = (state: status, action: Action) => {
  switch (action.type) {
    case "setData":
      return { data: action.payload };
    default:
      return state;
  }
};
