import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const logsSlice = createSlice({
  name: "logs",
  initialState: {
    showLogsTable: false,
    searchResultField: undefined,
  },
  reducers: {
    setShowLogsTable: (state, action) => {
      state.showLogsTable = action.payload;
      
    },
    setSearchResultField: (state, action) => {
      state.searchResultField = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchResultField)
      setLocalStorageSearch && localStorage.removeItem("searchLogs")
      setLocalStorageSearch &&  localStorage.setItem("searchLogs",setLocalStorageSearch);
    },
  },
});

export const { setShowLogsTable, setSearchResultField } = logsSlice.actions;
export const selectLastLogsSearch = (state: RootState) => {
const searchValue:string = localStorage.getItem("searchLogs");

  if (state.logs.searchResultField) {
    return state.logs.searchResultField
  } else {
    return JSON.parse(searchValue)
  }
}
export default logsSlice.reducer;
