import React, { ReactNode, useState } from "react";
import { Paper, Typography, IconButton, Modal, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SecurityNoticeProps {
  message: ReactNode;
}

const SecurityNotice: React.FC<SecurityNoticeProps> = ({ message }) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClose} closeAfterTransition>
      <Fade in={isOpen}>
        <Paper
          elevation={3}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80vw",
            padding: "20px",
            backgroundColor: "#fff",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography variant="body1">{message}</Typography>
          <IconButton
            aria-label="close"
            size="small"
            style={{ position: "absolute", top: "10px", right: "5px" }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SecurityNotice;
