import { createSlice } from "@reduxjs/toolkit";
import { Constants } from "../../constants";

export const marketSlice = createSlice({
  name: "market",
  initialState:{
    value:""
  },
  reducers: {
    setMarket: (state, action) => {
      state.value = action.payload;
      sessionStorage.setItem(Constants.SESSION_STORAGE_SELECTED_MARKET, action.payload);
    },
  },
});

export const { setMarket } = marketSlice.actions;

export const selectMarket = state=> state.market.value;

const marketSelectionReducer = marketSlice.reducer;
export default marketSelectionReducer;
