import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { colors } from "../../common/colors";
import { useUserListQuery } from "../../lib/store/services/symphony";
import Error from "../shared/error";
import { Loading } from "../shared/loading";
import AddNewUserButton from "./add-new-user-button";
import { UserRow } from "./user-row";
import { uniqBy } from "ramda";

const TableFooter = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const UserTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;
const UserTable = () => {
  const { error, isLoading, data } = useUserListQuery(undefined);

  const users = uniqBy((u) => u.id, data?.data ?? []);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  return (
    <div>
      <TableContainer
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
        sx={{ maxHeight: 400 }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="user table">
          <UserTableHead>
            <TableRow>
              <CenteredTableCell>Users</CenteredTableCell>
              <CenteredTableCell>Local Market</CenteredTableCell>
              <CenteredTableCell>Admin</CenteredTableCell>
              <CenteredTableCell>User Status</CenteredTableCell>
              <CenteredTableCell>Actions</CenteredTableCell>
            </TableRow>
          </UserTableHead>
          <TableBody>
            {users.map((user) => (
              <UserRow key={user.id} user={user} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableFooter>
        <AddNewUserButton />
      </TableFooter>
    </div>
  );
};

export default UserTable;
