import styled from "@emotion/styled";
import { Button, FormControl, MenuItem, Modal, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { colors } from "../../common/colors";
import {
  useGetAllBQFieldsQuery,
  useGetUserSelectedBQFieldsQuery,
} from "../../lib/store/services/symphony";
import { Loading } from "./loading";
import { BQField, UserSelectedBQFields } from "../../lib/types/bqfields-types";
import { SelectFIBQForm } from "./fields-in-bq-form";
import { Constants } from "../../lib/constants";
import { FIBQConstants, getFIBQStreamsTabs } from "./FIBQ-utils";

const style = {
  position: "fixed",
  top: "5%",
  left: "5%",
  height: "90%",
  width: "90%",
  opacity: 1,
  borderRadius: "20px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
  display: "block",
  overflow: "hidden",
};

const ModalBox = styled(Box)`
  text-align: center;
  padding-top: 30px;
  padding-bottom: 0px;
`;

const FieldsInBqButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 34px;
  text-transform: none;
  color: #000;
  min-width: 100px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const TitleTypography = styled(Typography)`
  font-weight: 600;
  color: ${colors.lightBlackSubtitle};
`;

const TabButton = styled(Button)`
  margin-right: 10px;
`;

interface FieldsInBQModalProps {
  excludeStreams?: string[];
  serviceDataTableSearched?: string;
  preselectedStreamTab: string;
  callbackUpdatedFIBQ?: () => void;
}

export function FieldsInBqModal(props: FieldsInBQModalProps) {
  const [selectedTab, setSelectedTab] = React.useState<string>(props.preselectedStreamTab);
  const [open, setOpen] = React.useState(false);
  const [selectedSdataTable, setSelectedSdataTable] = React.useState<string>(
    props.serviceDataTableSearched,
  );
  const previousSDataTableProp = React.useRef<string>(props.serviceDataTableSearched);

  const changeTab = (stream: string) => {
    setSelectedTab(stream);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data: userSelectedBQFields, isFetching: fetchingUserBQFields } =
    useGetUserSelectedBQFieldsQuery();
  const { data: allBqFieldsData, isFetching: fetchingAllBQFields } = useGetAllBQFieldsQuery();

  const showLoader: boolean = fetchingUserBQFields || fetchingAllBQFields;

  //to implement the service data table selection, the "stream" for service data has this naming convention : ServiceData-Tablename
  const serviceDataTables: string[] = React.useMemo(() => {
    return [
      ...new Set(
        allBqFieldsData?.data.bqFields
          .filter((field) => field.stream.includes(FIBQConstants.BQ_STREAM_SERVICE_DATA))
          .map((field) => field.stream.split("-")[1]),
      ),
    ];
  }, [allBqFieldsData]);

  React.useEffect(() => {
    //is selected table in form not configured?
    if (!serviceDataTables.includes(props.serviceDataTableSearched)) {
      //I do not reset selection if the previous is configured
      if (serviceDataTables.includes(previousSDataTableProp.current)) 
        setSelectedSdataTable(previousSDataTableProp.current);
       else 
        setSelectedSdataTable(serviceDataTables[0]); //reset to first
    } else {
      setSelectedSdataTable(props.serviceDataTableSearched); //set current selected table
      previousSDataTableProp.current = props.serviceDataTableSearched; //update ref
    }
  }, [props.serviceDataTableSearched, serviceDataTables]);

  //dynamic bq fields streams tabs, based on getAllBQFieldsQuery
  const bqStreamsTabs: string[] = React.useMemo(() => {
    return getFIBQStreamsTabs(allBqFieldsData, props.excludeStreams, serviceDataTables.length > 0);
  }, [allBqFieldsData, props.excludeStreams, serviceDataTables]);

  const allBQfieldsForStream: BQField[] = React.useMemo(() => {
    if (!allBqFieldsData || allBqFieldsData.data.bqFields.length === 0) return [];

    if (selectedTab === FIBQConstants.BQ_STREAM_SERVICE_DATA)
      return allBqFieldsData.data.bqFields.filter(
        (field) => field.stream === `${selectedTab}-${selectedSdataTable}`,
      );

    return allBqFieldsData.data.bqFields.filter((field) => field.stream === selectedTab);
  }, [allBqFieldsData, selectedTab, selectedSdataTable]);

  const userSelectedFieldsForStream: UserSelectedBQFields = React.useMemo(() => {
    if (!userSelectedBQFields || userSelectedBQFields.data.allUserSelectedBQFields.length === 0)
      return null;

    if (selectedTab === FIBQConstants.BQ_STREAM_SERVICE_DATA)
      return userSelectedBQFields.data.allUserSelectedBQFields.find(
        (field) => field.stream === `${selectedTab}-${selectedSdataTable}`,
      );

    return userSelectedBQFields.data.allUserSelectedBQFields.find(
      (field) => field.stream === selectedTab,
    );
  }, [userSelectedBQFields, selectedTab, selectedSdataTable]);

  return (
    <div>
      {showLoader ? <Loading /> : <FieldsInBqButton onClick={handleOpen}>Select</FieldsInBqButton>}

      <Modal open={open} onClose={handleClose}>
        <ModalBox sx={style}>
          <HeaderWrapper>
            <div>
              <TitleTypography id="modal-modal-title" variant="h6">
                Fields in BQ - {sessionStorage.getItem(Constants.SESSION_STORAGE_SELECTED_MARKET)}
              </TitleTypography>
            </div>
            <ButtonWrapper>
              {bqStreamsTabs.map((stream) => (
                <TabButton
                  variant={selectedTab === stream ? "contained" : "outlined"}
                  onClick={() => changeTab(stream)}
                >
                  {stream.toUpperCase()}
                </TabButton>
              ))}
            </ButtonWrapper>
          </HeaderWrapper>
          {/* serviceData table selection */}
          {selectedTab === FIBQConstants.BQ_STREAM_SERVICE_DATA && (
            <div style={{ textAlign: "left", paddingBottom: "15px" }}>
              <FormControl size="small">
                <Select
                  onChange={(e) => {
                    setSelectedSdataTable(e.target.value as string);
                  }}
                  id="in"
                  name="in"
                  defaultValue={selectedSdataTable}
                  value={selectedSdataTable}
                >
                  {serviceDataTables.map((tb) => (
                    <MenuItem key={tb} value={tb}>
                      {tb}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <SelectFIBQForm
            onAfterUpdate={props.callbackUpdatedFIBQ}
            stream={selectedTab}
            serviceDataTable={selectedSdataTable}
            allBQFields={allBQfieldsForStream}
            userSelectedFields={userSelectedFieldsForStream}
          />
        </ModalBox>
      </Modal>
    </div>
  );
}
