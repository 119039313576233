import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "../../icons/edit-icon";
import { adminSubroutes, routes } from "../../lib/routes";
import { Role } from "../../lib/types/role-types";
import RoleInfomrationModal from "../roles/role-information-modal";
import DeleteRoleComponent from "./delete-role-component";

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UsersActionCell: FunctionComponent<{ role: Role }> = ({ role }) => (
  <IconWrapper>
    <div>
      <RoleInfomrationModal role={role} />
    </div>
    <div>
      <Button
        variant="text"
        component={RouterLink}
        to={`${routes.admin}/${adminSubroutes.editRole}/${role.id}`}
      >
        <EditIcon />
      </Button>
    </div>
    <DeleteRoleComponent id={role.id} />
  </IconWrapper>
);

export default UsersActionCell;
