import styled from "@emotion/styled";
import { Box, TablePagination, TableSortLabel } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { colors } from "../../common/colors";
import TableColumnFilterRow from "../../common/table-filterer/table-column-filter-row";
import {
  useLazyGetCdrsQuery,
  useLazyGetFullCdrsQuery
} from "../../lib/store/services/symphony";
import ExportModal from "../shared/export-modal";
import CdrTableRow from "./cdr-table-row";
import { SelectedCdrEdrSingleRow } from "./selected-cdr-edr-single-row";
import { CdrEdrTableRow, CdrOrEdr, CdrsResponseData } from "./table-types";
import { GetCdrEdrFilters, SearchFields } from "./types";
import { formatEpochTime, isDateTimeValid } from "./utils";
import { getFormattedDate } from "../../lib/date-utils";
import {
  invertSortType,
  parseColFilterParam,
  validateFunctionForExcelExport,
} from "../../lib/utils";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import DetailedJSON from "../../common/detailed-JSON";
import { retrieveOpCoFromMarket } from "../shared/markets";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { setCdrSearch } from "../../lib/store/slices/last-cdredr-slice";
import { useAppDispatch } from "../../lib/store/hooks";
import { Loading } from "../shared/loading";
import { RootState } from "../../lib/store/store";
import { tableSortLabelStyle } from "../../theme";
import { visuallyHidden } from "@mui/utils";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const LastCdrEdrTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;

    background: ${colors.vodafoneRed};
  }
`;

const SingleRowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
`;

const HeaderTableCell = styled(TableCell)`
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 250px;
  white-space: nowrap;
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LastCdrTable: React.FunctionComponent<{
  selectedTab: string;
  search?: SearchFields;
}> = ({ search, selectedTab }) => {
  const dispatch = useAppDispatch();

  const searchCdr = useSelector((state: RootState) => state.lastCdrEdr.searchCdr);
  const [cdrsData, setCdrsData] = React.useState<CdrsResponseData>({});
  const [selectedRow, setSelectedRow] = React.useState<CdrEdrTableRow | undefined>();
  const [orderColumn, setSortColumn] = React.useState<string>("Dump_Timestamp");
  const [orderType, setSortType] = React.useState<string>("desc");
  const isFromDateValid = isDateTimeValid(search?.fromDate);
  const isToDateValid = isDateTimeValid(search?.toDate);
  const selectedMarket: string = useSelector(selectMarket);

  const opco: string = React.useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let newSearchCdrValue = { ...searchCdr };
    newSearchCdrValue.perPage = parseInt(event.target.value, 10);
    // very important If I select page number 2 and number of page is 25 then I converted to 100 per page it returns 0 ;
    newSearchCdrValue.pageNo = 0;
    dispatch(setCdrSearch(newSearchCdrValue));
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    let newSearchCdrValue = { ...searchCdr };
    newSearchCdrValue.pageNo = newPage;
    dispatch(setCdrSearch(newSearchCdrValue));
  };
  const [tableData, setTableData] = React.useState<CdrOrEdr[]>();

  const [getCdrsData, { isFetching: isFetchingCdrs }] = useLazyGetCdrsQuery();

  const fetchCdrsData = async () => {
    const getFullDataQueryParams:GetCdrEdrFilters = {
      msisdn: search?.msisdn,
      from: isFromDateValid ? getFormattedDate(search.fromDate) : undefined,
      to: isToDateValid ? getFormattedDate(search.toDate) : undefined,
      pageNo: searchCdr.pageNo,
      perPage: searchCdr.perPage,
      opco: opco,
      colFilters: parseColFilterParam(searchCdr.colFilters),
      exportFlag: false,
      sortType: orderType,
      sortColumn: orderColumn
    };

    const response = await getCdrsData(getFullDataQueryParams, true);
    setCdrsData(response.data.data);
    setTableData(response.data.data.cdrs);
  };

  React.useEffect(() => {
    fetchCdrsData();
  }, [searchCdr,orderType, orderColumn]);

  const handleColumnFiltersChange = React.useCallback((columnValues: Record<string, string>) => {
    //remove focus from column filter textInput to stop the user from typing while performing API call
    (document.activeElement as HTMLElement).blur();

    //  setColumnValues(columnValues);
    let newSearchEdrValue = { ...searchCdr };
    newSearchEdrValue.colFilters = columnValues;
    dispatch(setCdrSearch(newSearchEdrValue));
  }, []);

  const handleChangeSort = (orderCol: string) => {
    if (orderColumn === orderCol) setSortType(invertSortType(orderType));
    else setSortColumn(orderCol);
  };

  const handleRowClick = (row: CdrEdrTableRow) => {
    setSelectedRow(row);
  };

  const handleBack = () => {
    setSelectedRow(undefined);
  };

  //createRows from getCdrs / getEdrs response passed down in the properties
  const tableRows: CdrEdrTableRow[] = React.useMemo(
    () => tableData?.length && createTableDataFromResponse(tableData, selectedTab),
    [tableData],
  );

  const updatedTableRows =
    tableRows &&
    tableRows?.map((tblRow: CdrEdrTableRow) => {
      const updatedRecordElementsObject = {
        ...tblRow.recordElement,
        Dump_Timestamp: formatEpochTime(tblRow.recordElement.Dump_Timestamp?.value),
        lastResetTime: formatEpochTime(Number(tblRow.recordElement.lastResetTime)),
        subscriptionLastResetTime: formatEpochTime(
          Number(tblRow.recordElement.subscriptionLastResetTime),
        ),
      };

      return updatedRecordElementsObject;
    });

  const [getFullCdrData, { isFetching: isFetchingFullCdrs }] = useLazyGetFullCdrsQuery();

  const fetchFullData = async (includeColFilters: boolean) => {
    const getFullDataQueryParams:GetCdrEdrFilters = {
      msisdn: search?.msisdn,
      from: search?.fromDate ? getFormattedDate(search.fromDate) : undefined,
      to: search?.toDate ? getFormattedDate(search.toDate) : undefined,
      pageNo: 0,
      colFilters: includeColFilters ? parseColFilterParam(searchCdr.colFilters) : {},
      opco: opco,
      exportFlag: true,
      sortType: orderType,
      sortColumn: orderColumn
    };

     const response = await getFullCdrData(getFullDataQueryParams, true);
     const cdrsOrEdrs: CdrOrEdr[] = response.data.data.cdrs;

    const tableDataFromResponse: CdrEdrTableRow[] =
      "data" in response && createTableDataFromResponse(cdrsOrEdrs, selectedTab);

    const updatedTableRows = tableDataFromResponse.map((tblRow: CdrEdrTableRow) => {
      const updatedRecordElementsObject = {
        ...tblRow.recordElement,
        Dump_Timestamp: formatEpochTime(tblRow.recordElement.Dump_Timestamp?.value),
      };

      return updatedRecordElementsObject;
    });
    return updatedTableRows;
  };

  const [headers, setHeaders] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (tableRows?.length) {
      let allColumnNames: string[] = tableRows.reduce((columns: string[], row: CdrEdrTableRow) => {
        // Extract all unique column names from each row
        Object.keys(row.recordElement).forEach((columnName) => {
          if (!columns.includes(columnName)) {
            columns.push(columnName);
          }
        });
        return columns;
      }, []);

      setHeaders(allColumnNames);
    }
  }, [headers.length, tableRows]);

  const headersTable = headers.map((property) => {
    return {
      label: property,
      mapping: (row) => validateFunctionForExcelExport(row[property]),
    };
  });

  const isLoadingFullData = isFetchingFullCdrs;

  let selectedRowHeaders;

  if (selectedRow) {
    selectedRowHeaders = Object.entries(selectedRow.recordElement)?.map(([key, value]) => ({
      label: key,
      mapping: (row) => `"${selectedRow.recordElement[key]}"`,
    }));
  }

  const headerTableCells = headers.map((th) => (
    <HeaderTableCell key={th}>
      <TableSortLabel
        active={orderColumn === th}
        direction={orderType === "desc" ? "desc" : "asc"}
        onClick={() => handleChangeSort(th)}
        sx={tableSortLabelStyle}
      >
        {th}
        {orderColumn === th ? (
          <Box component="span" sx={visuallyHidden}>
            {orderType === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </HeaderTableCell>
  ));

  const showLoader: boolean = isFetchingCdrs;

  return (
    <div>
      {showLoader && <Loading />}

          <div
            style={{ display: selectedRow ? "none" : undefined }}
            className={showLoader ? "disabled" : ""}
          >
            <TableContainer
              style={{ borderTopLeftRadius: "30px", borderTopRightRadius: "45px" }}
              sx={{ maxHeight: "55vh" }}
              component={Paper}
            >
              <Table stickyHeader size="small" aria-label="user table">
                <TableColumnFilterRow
                  columns={headers}
                  columnFilterValues={searchCdr.colFilters}
                  onChange={handleColumnFiltersChange}
                  charsToTriggerOnChange={1}
                />

                <LastCdrEdrTableHead>
                  <TableRow>{headerTableCells}</TableRow>
                </LastCdrEdrTableHead>

                <TableBody>
                  {Array.isArray(tableRows) &&
                    tableRows.length &&
                    tableRows?.map((row, i) => (
                      <CdrTableRow
                        onClick={handleRowClick}
                        key={i}
                        cdrEdrTblRow={row}
                        filteredColumns={headers}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <FooterWrapper>
              <TablePagination
                component="div"
                count={cdrsData.total ? cdrsData.total : 0}
                page={searchCdr.pageNo}
                onPageChange={handleChangePage}
                rowsPerPage={searchCdr.perPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              <TableFooter>
                <ExportModal
                  headers={headersTable}
                  data={updatedTableRows}
                  fetchFullData={fetchFullData}
                  selectedTab={selectedTab}
                  isFetching={isLoadingFullData}
                />
              </TableFooter>
            </FooterWrapper>
          </div>

          {selectedRow && (
            <div>
              <div>
                <SelectedCdrEdrSingleRow
                  onBackClick={handleBack}
                  cols={selectedRow.recordElement}
                />
              </div>
              {/* single row export happens here  */}
              <SingleRowButtonWrapper>
                <ExportModal
                  data={selectedRow.fullDetailedJson}
                  selectedTab="profileInfo"
                  headers={selectedRowHeaders}
                />
                <DetailedJSON data={[selectedRow.fullDetailedJson]} tableName="" />
              </SingleRowButtonWrapper>
            </div>
          )}
    </div>
  );
};

const createTableDataFromResponse = (responseRows: CdrOrEdr[], selectedTab: string) => {
  const tableData: CdrEdrTableRow[] = responseRows.map((tr) => {
    let cdrEdrRow: CdrEdrTableRow = { recordElement: {}, fullDetailedJson: null }; //init empty

    if (!tr.Detail_CDRS && !tr.Detail_EDRS) return cdrEdrRow;

    //tk86806 - dumpnode and stamp are inside recordElement with new structure -- moving them as first columns.
    if (tr.Detail_CDRS && selectedTab === CdrDashboardTabs.lastCdrs) {
      if (tr.cdrRecordElement.Dump_Timestamp)
        cdrEdrRow.recordElement = {
          ...tr.cdrRecordElement,
          Dump_Timestamp: tr?.cdrRecordElement.Dump_Timestamp?.value,
        };
      else cdrEdrRow.recordElement = { ...tr.cdrRecordElement };
      cdrEdrRow.fullDetailedJson = tr.Detail_CDRS;
    }

    if (tr.Detail_EDRS && selectedTab === CdrDashboardTabs.lastEdrs) {
      if (tr.edrRecordElement.Dump_Timestamp)
        cdrEdrRow.recordElement = {
          ...tr.edrRecordElement,
          Dump_Timestamp: tr?.edrRecordElement.Dump_Timestamp?.value,
        };
      else cdrEdrRow.recordElement = { ...tr.edrRecordElement };

      cdrEdrRow.fullDetailedJson = tr.Detail_EDRS;
    }
    return cdrEdrRow;
  }); //column filters moved to backend API
  //remove all rows that do not match the text filter typed in each table column
  // .filter((cdrEdrRow: CdrEdrTableRow) => {
  //   return Object.entries(columnTextFilters).every(
  //     ([key, value]) =>
  //       // ignore the columns with empty value
  //       value === "" ||
  //       value == null ||
  //       (cdrEdrRow.recordElement[key]
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(value.toString().toLowerCase()) ??
  //         false),
  //   );
  // });

  return tableData;
};

export default LastCdrTable;