import { RadioGroup, RadioGroupProps } from "@mui/material";
import { FormikValues } from "formik";
import { generateMuiFormikFields } from "../../lib/mui-formik-field-generator";
import { FormikInstance } from "../../lib/use-formik-return-type";

export const FormikRadioGroup = <T extends FormikValues>({
  formik,
  name,
  ...rest
}: RadioGroupProps & { formik: FormikInstance<T>; name: string }) => {
  const formikFields = generateMuiFormikFields(name, formik);
  return <RadioGroup {...rest} {...formikFields} />;
};
