import styled from "@emotion/styled";

const RightIframeChart = () => {
  const Wrapper = styled.div`
    margin: 0 auto;
  `;
  return (
    <div>
      <Wrapper>
        <iframe
          width="100%"
          title="embedChart"
          height="450"
          src="https://lookerstudio.google.com/embed/reporting/0a31c978-54a0-4fb7-9872-715148be16a5/page/tEnnC"
          style={{ border: 0 }}
          allowFullScreen
        ></iframe>
      </Wrapper>
    </div>
  );
};

export default RightIframeChart;
