import styled from "@emotion/styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, ButtonProps, TableCell, TableRow } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../common/colors";
import { LogsWithParsedLogs } from "./logs-types";

const ColoredTableCell = styled(TableCell)`
  text-align: center;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  background: ${colors.lightGreyBackground};
  &.open {
    white-space: normal;
    overflow: auto;
  }
  :hover {
    cursor: pointer;
  }
`;

const ColoredLastTableCell = styled(TableCell)`
  text-align: center;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  background: ${colors.lightGreyBackground};
  &.open {
    white-space: normal;
    overflow: auto;
  }
  :hover {
    cursor: pointer;
  }
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  background: ${colors.fadedGreyBackground};
  &.open {
    white-space: normal;
    overflow: auto;
  }
  :hover {
    cursor: pointer;
  }
`;

const LogsRow: FunctionComponent<{
  logs: LogsWithParsedLogs;
  onClick: (r: LogsWithParsedLogs) => void;
}> = ({ logs, onClick }) => {
  const [extendRow, setExtendRow] = useState(false);

  const handleExpandButtonClick: ButtonProps["onClick"] = (e) => {
    e.stopPropagation();
    setExtendRow((prevExtendRow) => !prevExtendRow);
  };

  const formattedProcessingTimestamp = logs?.Dump_timestamp?.value;
  const cellClassName = extendRow ? "open" : undefined;
  const logsRow = logs.Logs;

  return (
    <TableRow onClick={() => onClick(logs)}>
      <CenteredTableCell className={cellClassName}>
        <Button onClick={handleExpandButtonClick} endIcon={<ExpandMoreIcon />}></Button>
        {formattedProcessingTimestamp}
      </CenteredTableCell>
      <ColoredTableCell className={cellClassName}>{logs.DumpNode ?? ""}</ColoredTableCell>
      <CenteredTableCell className={cellClassName}>
        {logsRow && logsRow.commonEventHeader && logsRow.commonEventHeader.eventId
          ? logsRow.commonEventHeader.eventId
          : ""}
      </CenteredTableCell>
      <ColoredTableCell className={cellClassName}>
        {logsRow && logsRow.commonEventHeader && logsRow.commonEventHeader.domain
          ? logsRow.commonEventHeader.domain
          : ""}
      </ColoredTableCell>
      <CenteredTableCell className={cellClassName}>
        {logsRow && logsRow.commonEventHeader && logsRow.commonEventHeader.eventName
          ? logsRow.commonEventHeader.eventName
          : ""}
      </CenteredTableCell>
      <ColoredLastTableCell className={cellClassName}>
        {logsRow && logsRow.syslogFields ? JSON.stringify(logsRow.syslogFields) : ""}
      </ColoredLastTableCell>
    </TableRow>
  );
};

export default LogsRow;
