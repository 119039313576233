const BackArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="10.901"
    height="8"
    viewBox="0 0 10.901 8"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_617"
          data-name="Rectangle 617"
          width="10.901"
          height="8"
          fill="#e60000"
        />
      </clipPath>
    </defs>
    <g id="Group_1153" data-name="Group 1153" clip-path="url(#clip-path)">
      <path
        id="Path_563"
        data-name="Path 563"
        d="M6.178.3A.429.429,0,0,0,5.709,0C5.046.005,4.382,0,3.719,0a.509.509,0,0,0-.41.181Q1.73,1.944.144,3.7a.382.382,0,0,0,0,.6q1.584,1.759,3.165,3.52A.509.509,0,0,0,3.719,8c.663,0,1.326-.005,1.989,0a.429.429,0,0,0,.469-.3V7.555a2.124,2.124,0,0,0-.165-.242Q4.579,5.714,3.14,4.119L3.035,4l.1-.111Q4.577,2.284,6.02.679A1.993,1.993,0,0,0,6.178.445V.3"
        transform="translate(4.723 0)"
        fill="#e60000"
      />
      <path
        id="Path_564"
        data-name="Path 564"
        d="M169.149,4.046l.745-.828q1.135-1.262,2.27-2.523a.36.36,0,0,0-.1-.612.559.559,0,0,0-.2-.033c-.688,0-1.376,0-2.064,0a.45.45,0,0,0-.356.158q-1.6,1.777-3.2,3.551a.377.377,0,0,0,0,.582q1.594,1.772,3.188,3.544a.46.46,0,0,0,.365.163c.688,0,1.376,0,2.064,0,.305,0,.494-.234.395-.489a.6.6,0,0,0-.118-.174Q170.725,5.8,169.3,4.216l-.152-.17"
        transform="translate(-166.114 -0.047)"
        fill="#e60000"
      />
    </g>
  </svg>
);

export default BackArrowIcon;
