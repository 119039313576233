import * as React from "react";
import { BQField, UserSelectedBQFields } from "../../lib/types/bqfields-types";
import CustomErrorMessage, { CustomAlert, customAlertProps } from "../custom-error-message";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import styled from "@emotion/styled";
import { Loading } from "./loading";
import { useSaveUserSelectedBQFieldsMutation } from "../../lib/store/services/symphony";
import { FIBQConstants } from "./FIBQ-utils";

const Footer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  height: 65px;
  width: 96%;
  padding-right: 10px;
  background: #fff;
  padding-top: 5px;
  z-index: 10;
  justify-content: space-between;
`;

const ChangesMadeMessage = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  text-align: right;
`;

const SubmitBtn = styled(Button)`
  min-width: 150px;
`;

const SectionTitle = styled(Typography)`
  font-weight: 600;
`;

const BQSelection = styled.div`
  max-height: 73vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SelectedFieldsBox = styled(Box)`
  position: sticky;
  max-height: 73vh;
  overflow-y: auto;
  top: 0;
`;

export interface FIBQFormProps {
  stream: string;
  allBQFields: BQField[];
  userSelectedFields: UserSelectedBQFields;
  serviceDataTable?: string;
  onAfterUpdate: () => void;
}

const successAlert: customAlertProps = {
  msg: "Selected FIBQ updated.",
  type: "success",
  showTitle: false,
};

const warningAlert: customAlertProps = {
  msg: "Save your changes before closing or switching tabs.",
  type: "warning",
  showTitle: false,
};

const errorAlert: customAlertProps = {
  msg: "An error occured while saving your preferences.",
  type: "error",
  showTitle: false,
};

export function SelectFIBQForm(props: FIBQFormProps) {
  //default selected ones if the user has never saved his FIBQ preferences
  const userSelectedFields: BQField[] = !props.userSelectedFields
    ? props.allBQFields.filter((field) => field.default) //default fields
    : //retrieve the BQFields the user previously chose by alias
      props.allBQFields.filter((field) =>
        props.userSelectedFields.selectedFields.includes(field.alias),
      );

  const [selectedFields, setSelectedFIBQ] = React.useState<BQField[]>(userSelectedFields);
  const [hasMadeAnyChange, setHasMadeAnyChange] = React.useState<boolean>(false);
  const [alertProps, setAlertProps] = React.useState<customAlertProps>(null);
  const [updateSelectedBQFields, { isLoading }] = useSaveUserSelectedBQFieldsMutation();

  const handleSave = async () => {
    //for service data the stream will be saved like "ServiceData-Tablename"
    const stream: string =
      props.stream === FIBQConstants.BQ_STREAM_SERVICE_DATA
        ? `${props.stream}-${props.serviceDataTable}`
        : props.stream;

    await updateSelectedBQFields({
      stream: stream,
      selectedFields: [...selectedFields.map((field) => field.alias)],
    })
      .then((result: any) => {
        if (result?.data.success) {
          setHasMadeAnyChange(false);
          setAlertProps(successAlert);
          if (props.onAfterUpdate)
          props.onAfterUpdate();
        } else setAlertProps(errorAlert);
      })
      .catch((err) => {
        setAlertProps(errorAlert);
      });
  };

  const addOrRemoveField = (clickedField: BQField) => {
    let fieldsInState = [...selectedFields];

    if (fieldsInState.includes(clickedField)) {
      //remove
      fieldsInState = fieldsInState.filter((field) => field.id !== clickedField.id);
      setSelectedFIBQ(fieldsInState.filter((field) => field.id !== clickedField.id));
    } else {
      //add
      fieldsInState.push(clickedField);
      setSelectedFIBQ(fieldsInState);
    }

    //if there are only alwaysSelecteAliases in the state, don't show the save button
    // if (fieldsInState.every((field) => alwaysSelectedAliases.includes(field.alias))) {
    //   setHasMadeAnyChange(false);
    //   setAlertProps(null);
    // } else {
      setHasMadeAnyChange(true);
      setAlertProps(warningAlert);
    //}
  };

  React.useEffect(() => {
    //reset selected fields to proper stream after changing it
    setSelectedFIBQ(userSelectedFields);
    setHasMadeAnyChange(false);
    setAlertProps(null);
  }, [props.stream, props.serviceDataTable]);

  if (props.allBQFields.length === 0)
    return (
      <CustomErrorMessage
        notShowTitle={true}
        error={`No fields in big query configured for ${props.stream}.`}
      />
    );

  const allFieldsList: JSX.Element = (
    <List dense sx={{ bgcolor: "background.paper" }}>
      {props.allBQFields.map((field) => {
        return (
          <ListItem key={field.id} disablePadding>
            <ListItemButton
              // disabled={alwaysSelectedAliases.includes(field.alias)}
              selected={selectedFields.includes(field)}
              onClick={() => addOrRemoveField(field)}
            >
              <ListItemIcon>
                <ViewColumnIcon />
              </ListItemIcon>
              <Box sx={{ textAlign: "right", paddingRight: "3px" }}>{field.alias}</Box>
              <ListItemText
                secondary={field.url}
                secondaryTypographyProps={{ align: "left", fontSize: "0.79rem" }}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );

  const selectedFieldsGrid: JSX.Element = (
    <Grid container spacing={1}>
      {selectedFields.map((field, idx) => (
        <Grid item sx={{ textAlign: "left", fontSize: "0.95rem" }} xs={6} sm={6} xl={4} key={idx}>
          {`${idx + 1}. ${field.alias}`}
          <ListItemText
            sx={{ display: "inline-block !important", paddingRight: "3px" }}
            secondary={field.default ? "(default)" : ""}
            secondaryTypographyProps={{ fontSize: "0.79rem" }}
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <div>
      <BQSelection>
        <Grid container spacing={3}>
          <Grid item lg={6} direction={"column"} sm={12}>
            <SelectedFieldsBox>
              <SectionTitle variant="subtitle1" marginBottom={"8px"} textAlign={"left"}>
                Selected Fields - {props.stream}{" "}
                {props.serviceDataTable &&
                  props.stream === FIBQConstants.BQ_STREAM_SERVICE_DATA &&
                  " - " + props.serviceDataTable}
              </SectionTitle>
              {selectedFieldsGrid}
            </SelectedFieldsBox>
          </Grid>
          <Grid item lg={6} direction={"column"} sm={12}>
            <SectionTitle variant="subtitle1" textAlign={"left"}>
              BQ Fields - {props.stream}{" "}
              {props.serviceDataTable &&
                props.stream === FIBQConstants.BQ_STREAM_SERVICE_DATA &&
                " - " + props.serviceDataTable}
            </SectionTitle>
            {allFieldsList}
          </Grid>
        </Grid>
      </BQSelection>

      <Footer>
        {alertProps && (
          <ChangesMadeMessage>
            <CustomAlert {...alertProps} />
          </ChangesMadeMessage>
        )}
        {selectedFields.length > 0 && hasMadeAnyChange && (
          <React.Fragment>
            <ButtonWrapper>
              {isLoading ? (
                <Loading />
              ) : (
                <SubmitBtn variant="contained" onClick={() => handleSave()}>
                  Save
                </SubmitBtn>
              )}
            </ButtonWrapper>
          </React.Fragment>
        )}
      </Footer>
    </div>
  );
}
