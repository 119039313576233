import styled from "@emotion/styled";
import { Button, ButtonProps, TableCell, TableRow } from "@mui/material";
import { colors } from "../../common/colors";
import { Subscription } from "./types";
import { formatEpochTime } from "./utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const EvenTableCell = styled(TableCell)`
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.open {
    white-space: normal;
    overflow: auto;
  }
  background: ${colors.fadedGreyBackground};
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
  width: 200px;
  :hover {
    cursor: pointer;
  }
`;

const OddTableCell = styled(TableCell)`
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
  &.open {
    white-space: normal;
    overflow: auto;
  }
  text-align: center;
`;

const ProductsTableRow = ({
  cols,
  onClick,
}: {
  cols: Subscription;
  onClick: (s: Subscription) => void;
}) => {
  const [extendRow, setExtendRow] = useState(false);
  
  const bckInstsFirstRow = cols.csiList?.[0]?.bcktInsts?.[0];
  const csiListFirstRow = cols.csiList?.[0];
  const handleExpandButtonClick: ButtonProps["onClick"] = (e) => {
    e.stopPropagation();
    setExtendRow((prevExtendRow) => !prevExtendRow);
  };

  const cellClassName = extendRow ? "open" : undefined;

  const csNameList = cols.csiList?.reduce((acc, { csDefRef }, i) => {
    if (i === 0) {
      return csDefRef;
    }
    return acc + ", " + csDefRef;
  }, "");

  return (
    <>
      <TableRow
        onClick={() => onClick(cols)}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <OddTableCell className={cellClassName}>
          <Button onClick={handleExpandButtonClick} endIcon={<ExpandMoreIcon />}></Button>{" "}
          {cols.bdlRef}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>
          {cols.description ?? cols.bundle?.description}
        </EvenTableCell>
        <EvenTableCell className={cellClassName}>{cols.id}</EvenTableCell>
        <OddTableCell className={cellClassName}>
          {formatEpochTime(cols.activationTime)}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>{cols.states[0]}</EvenTableCell>
        <OddTableCell className={cellClassName}>{formatEpochTime(cols.creationTime)}</OddTableCell>
        <EvenTableCell className={cellClassName}>
          {formatEpochTime(cols.lastResetTime)}
        </EvenTableCell>
        <OddTableCell className={cellClassName}>{formatEpochTime(cols.inputDate)}</OddTableCell>
        <OddTableCell className={cellClassName}>
          {csiListFirstRow?.bktInfoList?.[0]?.bktDefName}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>
          {cols.csiList?.[1]?.ctrInfoList?.[0]?.ctrDefId ??
            cols.csiList?.[0]?.ctrInfoList?.[0]?.ctrDefId}
        </EvenTableCell>
        <OddTableCell className={cellClassName}>{csNameList}</OddTableCell>
        <EvenTableCell className={cellClassName}>{bckInstsFirstRow?.initialValue}</EvenTableCell>
        <OddTableCell className={cellClassName}>{bckInstsFirstRow?.origInitialVal}</OddTableCell>
        <EvenTableCell>{bckInstsFirstRow?.unusedValue}</EvenTableCell>
        <OddTableCell className={cellClassName}>
          {cols?.entityCtrlnsts?.[0]?.value.ctrInsMap?.[0]?.currentValue}
        </OddTableCell>
        <EvenTableCell className={cellClassName}>{bckInstsFirstRow?.prevCurrentVal}</EvenTableCell>
        <OddTableCell className={cellClassName}>{bckInstsFirstRow?.currentChgStep}</OddTableCell>
        <EvenTableCell className={cellClassName}>{bckInstsFirstRow?.chrgStepUpTime}</EvenTableCell>
      </TableRow>
    </>
  );
};

export default ProductsTableRow;
