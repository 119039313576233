import styled from "@emotion/styled";
import { Box, List, ListItemButton, ListItemIcon } from "@mui/material";
import { FunctionComponent } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import ButtonRouterLink from "../../common/button-router-link";
import { colors } from "../../common/colors";
import ExportIcon from "../../icons/exportIcon";
import HomeIcon from "../../icons/home-icon";
import LogoutIcon from "../../icons/logoutIcon";
import LogsIcon from "../../icons/logsIcon";
import MeasurementsIcon from "../../icons/measurementsIcon";
import ServicesIcon from "../../icons/services-icon";
import SubscriberIcon from "../../icons/subscriber-icon";
import AdminButtonImg from "../../images/adminButton.png";
import { routes } from "../../lib/routes";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import { breakpoints } from "../../theme";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import Logo from "../shared/logo";
import UserInfo from "./user-info";
import { useSelector } from "react-redux";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { MarketRole } from "../subscriber-profiles-cdr/types";

const Link = styled(RouterNavLink)`
  text-decoration: none;
`;

const Sider = styled.div`
  border-radius: 0 30px 30px 0;
  background: ${colors.vodafoneRed};
  color: white;
  height: 100%;
  width: 200px;
  min-width: 200px;
  padding-left: 16px;
  font: normal normal 300 14px/16px Raleway;
`;
const UserContainer = styled(Box)`
  margin-top: 5%;
  align-items: center;
  align-content: center;
  width: 100%;
`;

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10%;
`;

const AdminButtonText = styled.span`
  margin-top: -25px;
  color: ${colors.vodafoneRed};
  font-size: 12px;
`;

const AdminButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 112px;
  align-items: center;
`;

const SiderIcon = styled(ListItemIcon)`
  min-width: 40px;
  width: 40px;
  padding-right: 16px;
`;

const StyledListItem = styled(ListItemButton)`
  font-size: 16px;
  font-family: Raleway, ligh;
  line-height: 18px;
  text-align: left;
  position: relative;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #ffffff;
  & ${SiderIcon.name} {
    svg {
      color: ${colors.textWhite};
    }
  }
  border-radius: 30px 0 0 30px;
  opacity: 1;
  &:hover,
  &:active,
  &.Mui-selected,
  &.Mui-selected:hover {
    background-color: white;
    color: ${colors.vodafoneRed};
    & ${SiderIcon.name} {
      svg {
        color: ${colors.vodafoneRed};
      }
    }
  }
  margin-top: 5px;
`;

const LogoContainer = styled(Box)`
  width: 100%;
  align-items: center;
`;

const LogoutList = styled(List)`
  bottom: 20px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    bottom: 4px;
  }
`;

const SidebarNavigationContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 145px);
  justify-content: space-between;
`;

const LogoutWrapper = styled.div`
  display: flex;
  max-height: 300px;
  height: 300px;
  flex-direction: column;
  justify-content: space-between;
`;

const NavLink: FunctionComponent<{
  to: string;
  children: React.ReactNode;
}> = (props) => {
  return (
    <Link to={props.to}>
      {(navLink) => <StyledListItem selected={navLink.isActive}>{props.children}</StyledListItem>}
    </Link>
  );
};

const Sidebar: FunctionComponent = () => {
  const { data, isLoading, error } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <CustomErrorMessage error="Sidebar could not be loaded" />;
  }

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole:MarketRole = data?.data?.roles.find(role => role.market.toLowerCase() === userSelectedMarket.toLowerCase());

  const hasAnyAdminRole = currentRole &&
    (currentRole.create_user ||
      currentRole.edit_user ||
      currentRole.delete_user ||
      currentRole.view_user ||
      currentRole.create_role ||
      currentRole.edit_role ||
      currentRole.view_role ||
      currentRole.delete_role) 

  return (
    <Sider>
      <Link to={routes.home}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Link>
      <UserContainer>
        <UserInfo userRoles={data.data.roles} />
      </UserContainer>
      <SidebarNavigationContainer>
        <List>
          <NavLink to={routes.home}>
            <SiderIcon>
              <HomeIcon />
            </SiderIcon>
            Home
          </NavLink>
          {currentRole.show_subscribers === true && (
            <NavLink to={routes.subscriberProfilesCdr}>
              <SiderIcon>
                <SubscriberIcon />
              </SiderIcon>
              Subscriber Profiles & CDR
            </NavLink>
          )}
          {currentRole.show_serviceData === true && (
            <NavLink to={routes.servicesData}>
              <SiderIcon>
                <ServicesIcon />
              </SiderIcon>
              Services Data
            </NavLink>
          )}

          {currentRole.show_logs === true && (
            <NavLink to={routes.logs}>
              <SiderIcon>
                <LogsIcon />
              </SiderIcon>
              Logs
            </NavLink>
          )}

          {currentRole.show_measurements === true && (
            <NavLink to={routes.measurements}>
              <SiderIcon>
                <MeasurementsIcon />
              </SiderIcon>
              Measurements
            </NavLink>
          )}

          {currentRole.view_export === true && (
            <NavLink to={routes.exports}>
              <SiderIcon>
                <ExportIcon />
              </SiderIcon>
              Export
            </NavLink>
          )}

          {/* {roles.show_custom_query == true && (
            <NavLink to={routes.customQuery}>
              <SiderIcon>
                <CustomQuery />
              </SiderIcon>
              Custom Query
            </NavLink>
          )} */}
        </List>
        <LogoutWrapper>
          {hasAnyAdminRole && (
            <AdminContainer>
              <ButtonRouterLink to="/admin">
                <AdminButtonWrapper>
                  <img alt="" src={AdminButtonImg} />
                  <AdminButtonText>Admin Console</AdminButtonText>
                </AdminButtonWrapper>
              </ButtonRouterLink>
            </AdminContainer>
          )}

          <Link to={routes.logout}>
            <LogoutList>
              <StyledListItem>
                <SiderIcon>
                  <LogoutIcon />
                </SiderIcon>
                Logout
              </StyledListItem>
            </LogoutList>
          </Link>
        </LogoutWrapper>
      </SidebarNavigationContainer>
    </Sider>
  );
};

export default Sidebar;
