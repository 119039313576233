import styled from "@emotion/styled";
import { Button, TableCell, TableRow } from "@mui/material";
import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { colors } from "../../common/colors";
import PreviewIcon from "../../icons/preview-icon";
import { exportsSubroutes, routes } from "../../lib/routes";
import ExportGenerationActionCell from "../../lib/types/export-generation-action-cell";
import { Export } from "../../lib/types/export-types";
import ExportActionsCell from "./export-actions-cell";

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

const PreviewButton = styled(Button)`
  width: 81px;
  height: 28px;
  background: #ff5c5c 0% 0% no-repeat padding-box;
  border-radius: 7px;
  text-transform: none;
  color: ${colors.textWhite};
  &:hover {
    background: #ff5c5c 0% 0% no-repeat padding-box;
    color: ${colors.textWhite};
  }
`;

const ExportRow: FunctionComponent<{
  exportRowInfo: Export;
}> = ({ exportRowInfo }) => {
  const defaultValueSelected = exportRowInfo.status;

  return (
    <TableRow
      key={exportRowInfo.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
      }}
    >
      <CenteredTableCell component="th" scope="row">
        {exportRowInfo.name}
      </CenteredTableCell>
      <CenteredTableCell>{exportRowInfo.firstExecution}</CenteredTableCell>
      <CenteredTableCell>{exportRowInfo.endpoint}</CenteredTableCell>
      <CenteredTableCell>Recurrency</CenteredTableCell>
      <CenteredTableCell>
        {defaultValueSelected === "running" && <div>Running</div>}
        {defaultValueSelected === "paused" && <div>Paused</div>}
        {defaultValueSelected === "stopped" && <div>Stopped</div>}
      </CenteredTableCell>
      <CenteredTableCell>
        <ExportGenerationActionCell defaultValue={defaultValueSelected} id={exportRowInfo.id} />
      </CenteredTableCell>
      <CenteredTableCell>
        <ExportActionsCell exportInfo={exportRowInfo} />
      </CenteredTableCell>
      <CenteredTableCell>
        <RouterLink
          to={`${routes.exports}/${exportsSubroutes.previousExports}/${exportRowInfo.id}`}
        >
          <PreviewButton>
            <PreviewIcon /> Preview
          </PreviewButton>
        </RouterLink>
      </CenteredTableCell>
    </TableRow>
  );
};

export default ExportRow;
