/**
 * This function is used to convert nested Object to string without containing " sign and avoid using JSON.stringify .
 * It supposes to solve excel sheets error in case of download
 */

type AnyObject = Record<string, any>;
export const convertObjectToStringWithoutQuotes = (obj: AnyObject): string => {
  const stringifyValue = (value: any): string => {
    if (typeof value === "object" && value !== null) {
      return convertObjectToStringWithoutQuotes(value);
    } else if (
      typeof value === "string" ||
      typeof value === "number" ||
      typeof value === "boolean"
    ) {
      return `'${value}'`;
    } else {
      JSON.stringify(value);
    }
  };

  let result = "{";

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      result += `${key}: ${stringifyValue(value)}, `;
    }
  }

  if (result.length > 1) {
    result = result.slice(0, -2);
  }
  result += "}";
  return result;
};
