import styled from "@emotion/styled";
import { Button, FormControl, InputLabel, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Logs } from "../../lib/types/logs-types";
import { FormikSelect } from "../formik-fields/FormikSelect";
import { DetailCdrEdr, ProfileInfoTableData, RecordElement } from "./table-types";
import { Subscription } from "./types";
import { CdrDashboardTabs } from "./cdrDashboardTabs";
import { validateFunctionForExcelExport } from "../../lib/utils";

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DataInput = styled.div`
  min-width: 200px;
  margin-right: 20px;
`;
const FileTypeInput = styled.div`
  min-width: 200px;
`;

const CenteredLabel = styled(InputLabel)`
  padding-left: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const ModalButton = styled(Button)`
  min-width: 150px;
`;

type ExportDataTypes =
  | Logs[]
  | ProfileInfoTableData["products"]
  | Subscription[]
  | (string | number)[]
  | ({} | RecordElement)[]
  | RecordElement
  | DetailCdrEdr;

interface ModalDataFileTypeProps {
  data: ExportDataTypes;
  fullData: ExportDataTypes;
  headers?: {
    label: string;
    value?:string;
    mapping?: (row: ExportDataTypes, index?: number) => ExportDataTypes;
  }[];
  selectedTab: string;
  fetchFullData?: (filterResult?: boolean) => Promise<ExportDataTypes>;
  onCloseModal?: () => void;
}

const fileTypeTest = [
  { id: "firstField", name: "Json" },
  { id: "secondField", name: "CSV" },
  // { id: "thirdField", name: "TBC" }, US97318
];

const ModalDataFileType = ({
  data,
  headers,
  selectedTab,
  fetchFullData,
  onCloseModal,
}: ModalDataFileTypeProps) => {
  const handleCloseModal = () => {
    // Call the onCloseModal callback to close the modal
    onCloseModal();
  };

  const dataTest = [
    { id: "data1", name: "Viewed data" },
    { id: "data2", name: "Full data" },
    { id: "data3", name: "Filtered data" },
  ];

  //filtered data option only in cdrEdr, and services data
  if (
    selectedTab !== CdrDashboardTabs.lastCdrs &&
    selectedTab !== CdrDashboardTabs.lastEdrs &&
    selectedTab !== "serviceData"
  )
    dataTest.pop();

  const formik = useFormik({
    initialValues: {
      data: dataTest[0].name,
      fileType: fileTypeTest[0].name,
    },
    validationSchema: yup.object({
      data: yup.string(),
      fileType: yup.string(),
    }),
    onSubmit: async () => {
      try {
        let fullData;
        const isFullDataSelected = formik.values.data === "Full data";
        const filteredDataSelected = formik.values.data === "Filtered data";

        //only if full data is selected we query BE and GCP again
        if (fetchFullData !== undefined) {
          if (isFullDataSelected)
            fullData = await fetchFullData(
              CdrDashboardTabs.products === selectedTab ? true : false,
            );
          if (filteredDataSelected) fullData = await fetchFullData(true);
        }

        const dataToConvert = isFullDataSelected || filteredDataSelected ? fullData : data;
        if (formik.values.fileType === "CSV") {
          convertToCsv(dataToConvert);
        }
        if (formik.values.fileType === "Json") {
          // call json convertion function
          convertToJson(dataToConvert);
        }
        if (formik.values.fileType === "TBC") {
          // call TBC convertion function
          convertToTbc(dataToConvert);
        }
        // Close the modal, after the export is complete
        handleCloseModal();
      } catch (e) {
        console.log(e);
      }
    },
  });

  const downloadFile = (data, fileName, fileType) => {
    const blob = new Blob([data], { type: fileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCsv = (dataToConvert) => {
    if (selectedTab === CdrDashboardTabs.profileInfo) {
      const rowData = headers.map(({ value }) => validateFunctionForExcelExport(value));
      const headerLabels = headers.map(({ label }) => label);
      const csvRows = rowData.map((row) => {
        return row;
      });
      const csvContent = [headerLabels.join(","), csvRows.join(",")].join("\n");
      downloadFile(csvContent, "data.csv", "text/csv;charset=utf-8;");
    } else {
      const csvData = dataToConvert?.map((row, index) => {
        const rowData = headers?.map(({ mapping }) => mapping(row, index));
        return rowData.join(",");
      });

      const headerLabels = headers?.map(({ label }) => label);
      const csvContent = [headerLabels?.join(","), ...csvData].join("\n");
      downloadFile(csvContent, "data.csv", "text/csv;charset=utf-8;");
    }
  };

  const convertToTbc = (dataToConvert) => {
    if (selectedTab === CdrDashboardTabs.profileInfo) {
      const rowData = headers.map(({ mapping }) => mapping(data));
      const headerLabels = headers.map(({ label }) => label);
      const tbcRows = rowData.map((row) => {
        return `"${row}"`;
      });
      const tbcContent = [headerLabels.join("\t"), tbcRows.join("\t")].join("\n");
      downloadFile(tbcContent, "data.tbc", "text/tab-separated-values;charset=utf-8");
    } else {
      const tbcData = dataToConvert.map((row, index) => {
        const rowData = headers.map(({ mapping }) => mapping(row, index));
        return rowData.join("\t");
      });

      const headerLabels = headers.map(({ label }) => label);
      const tbcContent = [headerLabels.join("\t"), ...tbcData].join("\n");

      downloadFile(tbcContent, "data.tbc", "text/tab-separated-values;charset=utf-8");
    }
  };

  const convertToJson = (dataToConvert) => {
    // Create a copy of dataToConvert to avoid reassignment or mutation
    let copyeOfdataToConvert = { ...dataToConvert };
    // Check if dataToConvert is an array or an obj, if so, create a copy accordingly
    Array.isArray(dataToConvert)
      ? (copyeOfdataToConvert = [...dataToConvert])
      : (copyeOfdataToConvert = { ...dataToConvert });

    if (selectedTab === CdrDashboardTabs.profileInfo) {
      copyeOfdataToConvert = { ...data };
    }
    const jsonData = JSON.stringify(copyeOfdataToConvert, null, 2).replace(/\\"/g, "");
    downloadFile(jsonData, "data.json", "application/json;charset=utf-8");
  };

  const singleView = [CdrDashboardTabs.profileInfo, "singleLogs"];
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <InputWrapper>
          <DataInput>
            <InputLabel>Data</InputLabel>
            <FormControl fullWidth>
              {/* <CenteredLabel>-Choose-</CenteredLabel> does not look right*/}
              <FormikSelect size="small" name="data" formik={formik} id="data">
                {singleView.includes(selectedTab) ? (
                  <MenuItem selected value={dataTest[0].name}>
                    Viewed Data
                  </MenuItem>
                ) : (
                  dataTest.map((d) => (
                    <MenuItem key={d.id} value={d.name}>
                      {d.name}
                    </MenuItem>
                  ))
                )}
              </FormikSelect>
            </FormControl>
          </DataInput>
          <FileTypeInput>
            <InputLabel>File Type</InputLabel>
            <FormControl fullWidth>
              {/* <CenteredLabel>-Choose-</CenteredLabel> does not look right*/}
              <FormikSelect size="small" name="fileType" formik={formik} id="fileType">
                {fileTypeTest.map((fType) => (
                  <MenuItem key={fType.id} value={fType.name}>
                    {fType.name}
                  </MenuItem>
                ))}
              </FormikSelect>
            </FormControl>
          </FileTypeInput>
        </InputWrapper>
        <ButtonWrapper>
          <ModalButton type="submit" variant="contained" size="small">
            Export
          </ModalButton>
        </ButtonWrapper>
      </form>
    </div>
  );
};

export default ModalDataFileType;
