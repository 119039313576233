import {  format  } from "date-fns";
import { useAppSelector } from "../../lib/store/hooks";
import { selectUser } from "../../lib/store/slices/authentication-slice";

const LastLogin = () => {
  const user = useAppSelector(selectUser);
  return <div> Last login was at {user.lastLogin ?format(new Date(user.lastLogin),  'dd/MM/yyyy HH:mm:ss') : 'Not Available'} </div>;
};

export default LastLogin;
