import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";



const servicesData = createSlice({
  name: "servicesData",
  initialState: {
    isAllTables: false,
    tableName: "",
    searchResultField: undefined,
  },
  reducers: {
    setSearchResultField: (state, action) => {
      
      state.searchResultField = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchResultField)
      setLocalStorageSearch && localStorage.removeItem("searchService")
      setLocalStorageSearch &&  localStorage.setItem("searchService",setLocalStorageSearch);
    },
    setIsAllTables: (state, action) => {
      state.isAllTables = action.payload;
      const setLocalStorageIsAllTables:string = JSON.stringify(state.isAllTables)
      setLocalStorageIsAllTables && localStorage.removeItem("isAllTables")
      setLocalStorageIsAllTables &&  localStorage.setItem("isAllTables",setLocalStorageIsAllTables);
    },
    setTableName: (state, action) => {
      state.tableName = action.payload;
    },
  },
});


export const { setSearchResultField, setIsAllTables, setTableName } = servicesData.actions;
export const selectLastServiceSearch = (state: RootState) => {
const searchValue:string = localStorage.getItem("searchService");

  if (state.servicesData.searchResultField) {
    return state.servicesData.searchResultField
  } else {
    return JSON.parse(searchValue)
  }
}
export const selectLastServiceIsAllTables = (state: RootState) => {
const isAllTablesValue:string = localStorage.getItem("isAllTables");

  if (state.servicesData.isAllTables) {
    return state.servicesData.isAllTables
  } else {
    return JSON.parse(isAllTablesValue)
  }
}
export const selectLastServiceTables = (state: RootState) => {
const tableNameValue:string = localStorage.getItem("tableNameValue");

  if (state.servicesData.tableName) {
    return state.servicesData.tableName
  } else {
    return JSON.parse(tableNameValue)
  }
}
export default servicesData.reducer;
