import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useNavigate } from "react-router";
import { colors } from "../../common/colors";
import DeleteIcon from "../../icons/delete-icon";
import { adminSubroutes, routes } from "../../lib/routes";
import { useDeleteRoleMutation } from "../../lib/store/services/symphony";
import { FunctionComponent } from "react";
import { Loading } from "../shared/loading";
import CustomErrorMessage from "../custom-error-message";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 485,
  height: 272,
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};
const ModalButton = styled(Button)`
  color: #4a4a4a;
  background: ${colors.greyBackground};
  border-radius: 35px;
  width: 145px;
  height: 40px;
  &.MuiButton-root {
    :hover {
      background: ${colors.vodafoneRed};
      color: ${colors.textWhite};
    }
  }
`;

const ModalBox = styled(Box)`
  text-align: center;
  padding-top: 80px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`;

const DeleteRoleComponent: FunctionComponent<{ id: number }> = ({ id }) => {
  const navigate = useNavigate();
  const [deleteUser, { isLoading, error }] = useDeleteRoleMutation();
  const [isOpen, setIsOpen] = React.useState(false);

  if (isLoading) {
    return <Loading />;
  }
  if (error && "status" in error && error.status === 503) {
    return <CustomErrorMessage error="Can't delete role assigned to users" />;
  }
  if (error) {
    return <CustomErrorMessage error="Data can not be loaded" />;
  }

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleConfirm = () => {
    deleteUser(id).then(() => {
      navigate(`${routes.admin}/${adminSubroutes.roles}`);
    });
    setIsOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <DeleteIcon />
      </Button>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this role?
          </Typography>
          <ButtonWrapper>
            <ModalButton onClick={handleConfirm}>Yes</ModalButton>
            <ModalButton onClick={handleClose}>No</ModalButton>
          </ButtonWrapper>
        </ModalBox>
      </Modal>
    </div>
  );
};

export default DeleteRoleComponent;
