import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../lib/store/hooks";
import { setSearchResultField, setShowLogsTable } from "../../lib/store/slices/logs-slice";
import { RootState } from "../../lib/store/store";
import { Page } from "../shared/Page";
import LogsForm from "./logs-form";
import LogsTable from "./logs-table";

const LogsDashboard = () => {
  const showLogsTable = useSelector((state: RootState) => state.logs.showLogsTable);
  const searchResultField = useSelector((state: RootState) => state.logs.searchResultField);
  const dispatch = useAppDispatch();

  const handleSetShowLogsTable = (value) => {
    dispatch(setShowLogsTable(value));
  };

  const handleSetSearchResultField = (value) => {
    dispatch(setSearchResultField(value));
  };

  return (
    <Page title="Logs">
      <LogsForm setShowLogsTable={handleSetShowLogsTable} onSearch={handleSetSearchResultField} />
      {searchResultField && <Typography variant="h6">Search Result</Typography>}
      {searchResultField && <LogsTable filters={searchResultField} />}
    </Page>
  );
};

export default LogsDashboard;
