import styled from "@emotion/styled";
import { TableHead, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Route, Routes } from "react-router";
import { colors } from "../../common/colors";
import { exportsSubroutes } from "../../lib/routes";
import { useExportListQuery } from "../../lib/store/services/symphony";
import { Page } from "../shared/Page";
import Error from "../shared/error";
import { Loading } from "../shared/loading";
import AuthorizationDataExportButton from "./authorization-data-export-button";
import CreateNewExport from "./create-export";
import EditExportComponent from "./edit-export";
import ExportRow from "./export-row";
import PreviousExports from "./previous-exports";
import DownloadExport from "./download-export";

const ColoredTableRow = styled(ExportRow, {
  shouldForwardProp: (prop) => (prop === "isColored" ? false : true),
})<{ isColored?: boolean }>`
  background: ${({ isColored }) =>
    isColored ? colors.lightGreyBackground : colors.fadedGreyBackground};
  text-align: center;
`;

const ExportTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const SectionTitle = styled(Typography)`
  margin: 15px 0 15px 0;
`;

const CenteredTableCell = styled(TableCell)`
  text-align: center;
`;

export const ExportsPageIndex = () => {
  const { data, isLoading, error } = useExportListQuery(undefined);
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <Error />;
  }

  return (
    <Page title="Exports" actionButton={<AuthorizationDataExportButton />}>
      <SectionTitle variant="h6">Current Exports</SectionTitle>
      <TableContainer
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
        sx={{ maxHeight: 400 }}
        component={Paper}
      >
        <Table stickyHeader size="small" aria-label="user table">
          <ExportTableHead>
            <TableRow>
              <CenteredTableCell>Name</CenteredTableCell>
              <CenteredTableCell>Last generation date</CenteredTableCell>
              <CenteredTableCell>End Point</CenteredTableCell>
              <CenteredTableCell>Recurrency</CenteredTableCell>
              <CenteredTableCell>Status</CenteredTableCell>
              <CenteredTableCell>Export Genereation Actions</CenteredTableCell>
              <CenteredTableCell>Actions</CenteredTableCell>
              <CenteredTableCell>Preview</CenteredTableCell>
            </TableRow>
          </ExportTableHead>
          <TableBody>
            {data.data.map((row, i) => (
              <ColoredTableRow isColored={i % 2 === 0} key={row.id} exportRowInfo={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  );
};

export const ExportsPage = () => {
  return (
    <Routes>
      <Route path="/" element={<ExportsPageIndex />} />
      <Route path={exportsSubroutes.createNewExport} element={<CreateNewExport />} />
      <Route path={`${exportsSubroutes.previousExports}/:id`} element={<PreviousExports />} />
      <Route path={`${exportsSubroutes.editExport}/:id`} element={<EditExportComponent />} />
      <Route path={`:id/${exportsSubroutes.download}`} element={<DownloadExport />} />
    </Routes>
  );
};
