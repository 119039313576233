import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";

interface User {
  email: string;
  lastLogin: string;
  localMarket:string;
}

interface AuthenticationState {
  user: User | null;
}

// Define the initial state using that type
const initialState: AuthenticationState = {
  user: null,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, removeUser } = authenticationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.authentication.user;

const authenticationReducer = authenticationSlice.reducer;
export default authenticationReducer;
