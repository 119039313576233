import styled from "@emotion/styled";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../lib/store/hooks";
import { useGetServiceDataAllTablesQuery } from "../../lib/store/services/symphony";
import {
  setIsAllTables,
  setSearchResultField,
  setTableName,
} from "../../lib/store/slices/services-data-slice";
import { RootState } from "../../lib/store/store";
import CustomErrorMessage from "../custom-error-message";
import { Page } from "../shared/Page";
import { Loading } from "../shared/loading";
import { ServiceDataFields } from "../subscriber-profiles-cdr/types";
import ServiceDataTable from "./service-data-table";
import ServicesDataForm from "./services-data-form";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { retrieveOpCoFromMarket } from "../shared/markets";

const getFormattedDate = (dateString: string) => {
  return format(new Date(dateString), "yyyy-MM-dd HH:mm:ss");
};

const ServiceDataInput = styled(FormControl)`
  &.MuiFormControl-root {
    width: 330px;
    margin-bottom: 20px;
  }
`;

const SearchTitle = styled(Typography)`
  margin-top: 20px;
`;

const SearchResultTitle = styled(InputLabel)`
  margin-bottom: 15px;
`;

const ServicesDataTableList: FunctionComponent<{
  filters: ServiceDataFields;
  onChange: (tn: string) => void;
}> = ({ filters, onChange }) => {
  const tableName = useSelector((state: RootState) => state.servicesData.tableName);
  const selectedMarket: string = useSelector(selectMarket);
  const opco: string = useMemo(() => {
    return retrieveOpCoFromMarket(selectedMarket);
  }, [selectedMarket]);
  // Check if dates are valid before making the API call
  const isFromDateValid = isDateTimeValid(filters?.from);
  const { data, isLoading, isFetching, error } = useGetServiceDataAllTablesQuery({
    ...filters,
    searchKeyword: filters.searchFor,
    from: isFromDateValid ? getFormattedDate(filters.from) : undefined,
    to: filters?.to ? getFormattedDate(filters.to) : undefined,
    colFilters:{},
    opco:opco
  });



  
  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (error || !data) {
    if (!isFromDateValid) {
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;
    }
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  const tableNames = data.tablesList;
  return tableNames.length !== 0 ? (
    <div>
      <ServiceDataInput>
        <Select
          onChange={(e) => {
            onChange(e.target.value as string);
          }}
          labelId="in"
          id="in"
          name="in"
          value={tableName}
        >
          {tableNames.map((tn) => (
            <MenuItem key={tn} value={tn}>
              {tn}
            </MenuItem>
          ))}
        </Select>
      </ServiceDataInput>
    </div>
  ) : (
    <CustomErrorMessage error="There is no data to be shown" />
  );
};

const ServicesDataDashboard = () => {
  const searchResultField = useSelector((state: RootState) => state.servicesData.searchResultField);
  const tableName = useSelector((state: RootState) => state.servicesData.tableName);

  const dispatch = useAppDispatch();

  const handleSetSearchResultField = (value) => {
    dispatch(setSearchResultField(value));
  };

  const onTableChange = (tn: string) => {
    dispatch(setTableName(tn));
  };

  const isAllTables = useSelector((state: RootState) => state.servicesData.isAllTables);

  useEffect(() => {
    if (searchResultField?.in === "All_Tables") {
      dispatch(setIsAllTables(true));
    } else {
      dispatch(setIsAllTables(false));
    }
  }, [searchResultField, dispatch]);

  const finalTableName = isAllTables ? tableName : searchResultField?.in;
  const tablenameSelectedOrSearchResultFieldInSet = !isAllTables || tableName;
  return (
    <Page title="Services Data">
      <ServicesDataForm onSearch={handleSetSearchResultField} />
      {searchResultField && (
        <div>
          <SearchTitle variant="h6">Search Result</SearchTitle>
          <SearchResultTitle id="in">{searchResultField.in}</SearchResultTitle>
        </div>
      )}
      {searchResultField && isAllTables && (
        <ServicesDataTableList filters={searchResultField} onChange={onTableChange} />
      )}
      {searchResultField && tablenameSelectedOrSearchResultFieldInSet && finalTableName && (
        <ServiceDataTable filters={{ ...searchResultField, in: finalTableName }} />
      )}
    </Page>
  );
};

export default ServicesDataDashboard;
