import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { colors } from "../../common/colors";
import { useGetMeasurementsDataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import { GetMeasurementsData } from "../subscriber-profiles-cdr/types";
import { LineGraphCreation } from "./graph-creation";
import GraphTypeSelector from "./graph-type-selector";
import { MeasurementsGraphDataType } from "./table-types";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { getFormattedDate } from "../../lib/date-utils";

const hoverButtonStyles = `
  color: ${colors.textWhite};
  background: ${colors.vodafoneRed};
`;

const TimeSelectionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  border-radius: 4px;
  margin-right: 15px;
  height: 25px;
  color: #000000;
  background: ${colors.textWhite};
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  ${({ isActive }) => (isActive ? hoverButtonStyles : "")}
  &:hover {
    ${hoverButtonStyles}
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  margin: 30px 0 60px 0;
`;

const Wrapper = styled.div`
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  min-width: 85%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const TimeButton: FunctionComponent<{
  onClick: (value: string) => void;
  value: string;
  isActive: boolean;
}> = ({ onClick, value, isActive }) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick((e.target as HTMLButtonElement).value);
  };

  return (
    <TimeSelectionButton
      variant="contained"
      value={value}
      onClick={handleClick}
      isActive={isActive}
    >
      {value}
    </TimeSelectionButton>
  );
};

const MeasurementsGraph: FunctionComponent<{
  from: string;
  to: string;
  kpiTables: GetMeasurementsData["tables"];
}> = ({ from, to, kpiTables }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  // Check if dates are valid before making the API call
  const isFromDateValid = isDateTimeValid(from);
  const isToDateValid = isDateTimeValid(to);

  const { data, isLoading, isFetching, isError, error, ...rest } = useGetMeasurementsDataQuery({
    from: isFromDateValid ? getFormattedDate(from) : undefined,
    to: isToDateValid ? getFormattedDate(to) : undefined,
    tables: kpiTables,
  });

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (isError || !data) {
    if (!isFromDateValid || !isToDateValid) {
      return <CustomErrorMessage error="Invalid date/time input. Please enter valid dates." />;
    }
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return <MeasurementsGraphInner kpiTables={kpiTables} data={data} />;
};

const timeOptions = [
  {
    label: "5min",
    value: { stepSize: 5, unit: "minute" },
  },
  {
    label: "30min",
    value: { stepSize: 30, unit: "minute" },
  },
  {
    label: "Daily",
    value: { stepSize: 1, unit: "day" },
  },
  {
    label: "Weekly",
    value: { stepSize: 1, unit: "week" },
  },
  {
    label: "Monthly",
    value: { stepSize: 1, unit: "month" },
  },
];

const MeasurementsGraphInner: FunctionComponent<{
  data: MeasurementsGraphDataType;
  kpiTables: GetMeasurementsData["tables"];
}> = ({ data, kpiTables }) => {
  const [selectedButton, setSelectedButton] = useState(timeOptions[0]);
  const [selectGraphType, setSelectGraphType] = useState("Line");

  const handleButtonClick = (option: typeof timeOptions[0]) => {
    setSelectedButton(option);
  };

  return (
    <Wrapper>
      <form>
        <HeaderWrapper>
          <Typography style={{ marginRight: 15 }} variant="h6">
            KPI_type
          </Typography>
          <ButtonsWrapper>
            {timeOptions.map((to) => (
              <TimeButton
                key={to.label}
                isActive={selectedButton.label === to.label}
                value={to.label}
                onClick={() => handleButtonClick(to)}
              />
            ))}
            <GraphTypeSelector setSelectGraphType={setSelectGraphType} />
          </ButtonsWrapper>
        </HeaderWrapper>
        <div>
          {selectGraphType === "Line" && (
            <LineGraphCreation
              kpiTables={kpiTables}
              tables={data.data}
              timeOption={selectedButton.value}
            />
          )}
        </div>
        {/* <div>{selectGraphType === "Pie" && <PieGraphCreation tables={data.data} />}</div>
        <div>{selectGraphType === "Scatter" && <ScatterGraphCreation tables={data.data} />}</div> */}
      </form>
    </Wrapper>
  );
};

export default MeasurementsGraph;
