import { useParams } from "react-router";
import { useDownloadExportQuery } from "../../lib/store/services/symphony";
import { Loading } from "../shared/loading";
import Error from "../shared/error";

const DownloadExport = () => {
  const params = useParams();
  const { data, error, isLoading } = useDownloadExportQuery({
    id: params.id,
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <Error />;
  }

  setTimeout(() => {
    const link = document.createElement("a");
    link.href = data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, 2000);

  return <h3 style={{ color: "red" }}>Downloading target file ...</h3>;
};

export default DownloadExport;
