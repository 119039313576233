import { FormikValues } from "formik";
import { ReactNode } from "react";
import { FormikInstance } from "./use-formik-return-type";

export const generateMuiFormikFields = <T extends FormikValues>(
  name: string, //specific name of the input field in formik.values, acts as parameter
  formik: FormikInstance<T>
) => {
  return {
    name,
    value: formik.values[name], //example, this is like writing formik.values["statusField"] or formik.values.statusField
    onChange: formik.handleChange,
    error: formik.touched[name] && Boolean(formik.errors[name]),
    helperText: formik.touched[name] && (formik.errors[name] as ReactNode),
  };
};
