import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import ButtonRouterLink from "../../common/button-router-link";
import { exportsSubroutes, routes } from "../../lib/routes";
import { useGetUserAuthorizationdataQuery } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
import { selectMarket } from "../../lib/store/slices/market-slice";
import { useSelector } from "react-redux";

const AuthorizationDataExportButton = () => {
  const { data, error, isLoading } = useGetUserAuthorizationdataQuery(null);
  const userSelectedMarket:string = useSelector(selectMarket);

  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  if (isLoading) {
    return <Loading />;
  }

  //UK-onboarding one role object for each market, retrieve role for user's selected market
  const currentRole = data?.data?.roles.find(
    (role) => role.market.toLowerCase() === userSelectedMarket.toLowerCase(),
  );

  return currentRole && currentRole.create_export ? (
    <ButtonRouterLink to={`${routes.exports}/${exportsSubroutes.createNewExport}`}>
      <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
        New Export
      </Button>
    </ButtonRouterLink>
  ) : (
    <Button disabled variant="contained" startIcon={<Icon icon={plusFill} />}>
      New Export
    </Button>
  );
};

export default AuthorizationDataExportButton;
