import { SingleRowDisplayer } from "./single-row-displayer";
import { RecordElement } from "./table-types";

export const SelectedCdrEdrSingleRow = ({
  cols,
  onBackClick,
}: {
  cols: RecordElement;
  onBackClick: () => void;
}) => {
  const columns = Object.entries(cols).map(([key, value]) => {
    return {
      header: key,
      value:
        cols[key] === undefined || cols[key] == null || cols[key] === ""
          ? ""
          : typeof cols[key] == "boolean"
          ? cols[key]
            ? "true"
            : "false"
          : typeof cols[key] == "number"
          ? String(BigInt(cols[key]))
          : cols[key],
    };
  });

  return <SingleRowDisplayer cols={columns} onBackClick={onBackClick} />;
};
