const ServicesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g id="Group_567" data-name="Group 567" transform="translate(-25.396 -267.396)">
      <path
        id="Path_340"
        data-name="Path 340"
        d="M0,8q0-3.275,0-6.55A1.339,1.339,0,0,1,1.442,0H14.558A1.338,1.338,0,0,1,16,1.446Q16,8,16,14.562A1.339,1.339,0,0,1,14.566,16H1.433A1.339,1.339,0,0,1,0,14.562Q0,11.279,0,8M15.466,2.671H.533v.194q0,5.841,0,11.682a.819.819,0,0,0,.925.919H14.54a.821.821,0,0,0,.926-.935q0-5.824,0-11.649Zm0-.555c0-.23,0-.441,0-.652a.819.819,0,0,0-.929-.931H1.457c-.05,0-.1,0-.15,0a.748.748,0,0,0-.738.606,7.174,7.174,0,0,0-.009.976Z"
        transform="translate(25.396 267.396)"
        fill="currentColor"
      />
      <path
        id="Path_341"
        data-name="Path 341"
        d="M23.879,88.078c0-.885.007-1.769,0-2.654a1.115,1.115,0,0,1,.425-.91,3.091,3.091,0,0,1,1.427-.634,6.138,6.138,0,0,1,3.593.256,2.006,2.006,0,0,1,.78.536,1.023,1.023,0,0,1,.263.694q0,2.72,0,5.44a1.091,1.091,0,0,1-.442.868,2.825,2.825,0,0,1-1.123.546,6.272,6.272,0,0,1-3.7-.114,2.209,2.209,0,0,1-.942-.589,1.03,1.03,0,0,1-.28-.721c0-.907,0-1.813,0-2.72M27.2,84.26a5.681,5.681,0,0,0-1.591.2,2.266,2.266,0,0,0-1.018.521.4.4,0,0,0,0,.66,2.169,2.169,0,0,0,.633.393,5.415,5.415,0,0,0,3.647.059,2.55,2.55,0,0,0,.742-.407.422.422,0,0,0,0-.738,2.488,2.488,0,0,0-.6-.355A4.847,4.847,0,0,0,27.2,84.26M24.42,86.273a5.293,5.293,0,0,1,0,.649.759.759,0,0,0,.467.8,2.573,2.573,0,0,0,.347.167,5.576,5.576,0,0,0,3.085.208,2.738,2.738,0,0,0,1.189-.476.744.744,0,0,0,.3-.4,6.756,6.756,0,0,0,.014-.951,6.033,6.033,0,0,1-5.4,0m0,1.846a5.319,5.319,0,0,1,0,.652.759.759,0,0,0,.469.8,2.57,2.57,0,0,0,.347.167,5.575,5.575,0,0,0,3.068.211,2.749,2.749,0,0,0,1.205-.48.744.744,0,0,0,.3-.4,6.76,6.76,0,0,0,.014-.95,6.026,6.026,0,0,1-5.4,0m5.4,1.846a6.016,6.016,0,0,1-5.4,0,5.24,5.24,0,0,1-.005.656.751.751,0,0,0,.457.788,2.524,2.524,0,0,0,.362.174,5.561,5.561,0,0,0,3.068.209,2.672,2.672,0,0,0,1.244-.512.721.721,0,0,0,.262-.4,7.071,7.071,0,0,0,.012-.916"
        transform="translate(2.597 187.445)"
        fill="currentColor"
      />
      <path
        id="Path_342"
        data-name="Path 342"
        d="M191.045,116.474a2.7,2.7,0,1,1-2.72-2.691,2.7,2.7,0,0,1,2.72,2.691m-2.7-1.07a1.08,1.08,0,1,0,1.083,1.082,1.082,1.082,0,0,0-1.083-1.082m-1.324,2.017a1.634,1.634,0,0,1,1.05-2.539v-.517a2.118,2.118,0,0,0-1.426,3.418l.377-.362m.01.782a2.125,2.125,0,0,0,2.3.2,2.1,2.1,0,0,0,1.157-1.639h-.538a1.634,1.634,0,0,1-2.531,1.059l-.388.38m3.438-2a2.068,2.068,0,0,0-1.842-1.837v.514a1.657,1.657,0,0,1,1.323,1.323Z"
        transform="translate(-152.035 158.647)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_306"
        data-name="Rectangle 306"
        width="5.381"
        height="0.52"
        transform="translate(33.351 280.162)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_307"
        data-name="Rectangle 307"
        width="5.387"
        height="0.519"
        transform="translate(33.352 281.246)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_308"
        data-name="Rectangle 308"
        width="3.761"
        height="0.52"
        transform="translate(34.965 279.132)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_309"
        data-name="Rectangle 309"
        width="1.057"
        height="0.526"
        transform="translate(33.401 271.933)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_310"
        data-name="Rectangle 310"
        width="1.059"
        height="0.519"
        transform="translate(39.248 272.472)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_311"
        data-name="Rectangle 311"
        width="1.059"
        height="0.521"
        transform="translate(39.247 273.537)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_312"
        data-name="Rectangle 312"
        width="1.062"
        height="0.52"
        transform="translate(38.975 276.734)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_313"
        data-name="Rectangle 313"
        width="1.061"
        height="0.519"
        transform="translate(38.975 277.8)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_314"
        data-name="Rectangle 314"
        width="1.06"
        height="0.523"
        transform="translate(33.397 279.131)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_315"
        data-name="Rectangle 315"
        width="0.521"
        height="0.517"
        transform="translate(28.106 268.489)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_316"
        data-name="Rectangle 316"
        width="0.523"
        height="0.52"
        transform="translate(27.024 268.488)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_317"
        data-name="Rectangle 317"
        width="0.52"
        height="0.523"
        transform="translate(29.136 268.484)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_318"
        data-name="Rectangle 318"
        width="3.226"
        height="0.519"
        transform="translate(28.062 272.472)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_319"
        data-name="Rectangle 319"
        width="0.516"
        height="0.525"
        transform="translate(27.297 274.329)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_320"
        data-name="Rectangle 320"
        width="0.525"
        height="0.513"
        transform="translate(28.339 274.606)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_321"
        data-name="Rectangle 321"
        width="0.52"
        height="0.523"
        transform="translate(27.298 276.202)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_322"
        data-name="Rectangle 322"
        width="0.525"
        height="0.518"
        transform="translate(28.334 276.468)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_323"
        data-name="Rectangle 323"
        width="0.516"
        height="0.525"
        transform="translate(27.297 278.066)"
        fill="currentColor"
      />
      <rect
        id="Rectangle_324"
        data-name="Rectangle 324"
        width="0.521"
        height="0.524"
        transform="translate(28.336 278.331)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default ServicesIcon;
