import { FunctionComponent } from "react";
import { Switch } from "@mui/material";
import { useUpdateUserMutation } from "../../lib/store/services/symphony";
import CustomErrorMessage from "../custom-error-message";
import { Loading } from "../shared/loading";
const UserActivationToggle: FunctionComponent<{
  id: number;
  isEnabled: boolean;
}> = ({ id, isEnabled }) => {
  const [updateUserStatus, { error, isLoading }] = useUpdateUserMutation();
  const action = isEnabled ? "deactivate" : "activate";

  const handleStatusUpdate = () => {
    updateUserStatus({ id, action });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <CustomErrorMessage error="Data could not be loaded" />;
  }

  return (
    <div>
      <Switch checked={isEnabled} onChange={handleStatusUpdate} />
    </div>
  );
};

export default UserActivationToggle;
