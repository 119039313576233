export const unsortedInValues = [
  { name: "All_Tables" },
  { name: "APN_to_Destination_Zone" },
  { name: "AllMappingTables_Structures" },
  { name: "AMA_Code_Mapping" },
  { name: "DID_Synchronization_COSPList" },
  { name: "BillCycleCalendar" },
  { name: "Bucket" },
  { name: "Bundle" },
  { name: "CategoryOrder" },
  { name: "CAOC" },
  { name: "Call_Screening_Definition" },
  { name: "ChLogic" },
  { name: "ChLogicCategory" },
  { name: "ChRuleComponent" },
  { name: "Class_of_Service" },
  { name: "Counter" },
  { name: "KafkaNotificationServer" },
  { name: "Crossed_Counters" },
  { name: "Group_Zone" },
  { name: "LifeCycle" },
  { name: "NotificationLanguage" },
  { name: "NotificationProfile" },
  { name: "NotificationTemplate" },
  { name: "Location_Area_Zone" },
  { name: "Outgoing_Screening" },
  { name: "PTP_Blacklist" },
  { name: "QoS_Assignment" },
  { name: "QoS_Info" },
  { name: "QoS_Valid_APN_Zones" },
  { name: "Rate" },
  { name: "SMPPServer" },
  { name: "SlicingProfile" },
  { name: "SGSN_IP_Zone" },
  { name: "Special_Day" },
  { name: "Supplementary_Slicing_Data" },
  { name: "Tariff_Plan_Definition" },
  { name: "Taxation" },
  { name: "Threshold" },
  { name: "Unit" },
  { name: "TieredAllowance" },
  { name: "Usage_Rate_ID" },
  { name: "Usage_Tariff" },
  { name: "Zone_Label" },
  { name: "Zone_Mapping" },
];

export const inValues = unsortedInValues.sort((a,b) => a.name.localeCompare(b.name));
