import styled from "@emotion/styled";
import { Button, FormControl, Grid, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { FormikDateTimePicker } from "../formik-fields/FormikDateTimePicker";
import { FormikTextField } from "../formik-fields/FormikTextField";
import { GetLogsData } from "../subscriber-profiles-cdr/types";
import { useSelector } from "react-redux";
import { isDateTimeValid } from "../subscriber-profiles-cdr/utils";
import { selectLastLogsSearch } from "../../lib/store/slices/logs-slice";
import { useEffect } from "react";
import { colors } from "../../common/colors";
import { endOfDay, startOfDay } from "date-fns";

const SearchButton = styled(Button)`
  margin-top: 45px;
`;

const InputLabelTitle = styled(InputLabel)`
  margin-bottom: 15px;
  font: normal normal medium 14px/16px Raleway;
`;
const TitleLable = styled(InputLabel)`
  margin-bottom: 15px;
  color: ${colors.lightBlackSubtitle};
  font-weight: 520;
  font: normal normal medium 14px/16px Raleway;
`;
const ContainerGrid = styled(Grid)`
  align-items: center;
  margin-bottom: 15px;
  &.MuiGrid-root {
    padding: 0px;
    width: 100%;
  }
`;
const FormGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  & > div {
    margin-right: 20px;
  }
`;

const InputGrid = styled(Grid)`
  margin-right: 25px;
`;

const DatePickerWrapper = styled.div`
  width: 180px;
`;

const LogsForm = ({
  setShowLogsTable,
  onSearch,
}: {
  setShowLogsTable: React.Dispatch<React.SetStateAction<boolean>>;
  onSearch: (value: GetLogsData) => void;
}) => {


  let searchDefaultValues: any = {
    searchKeyword: "",
      from: startOfDay(new Date().setDate(new Date().getDate())).toString(),
      to: endOfDay(new Date().setDate(new Date().getDate())).toString(),
      pageNo: 0,
      perPage: 10,
      colFilters:{}
  }  
  const searchLastLogs =  useSelector(selectLastLogsSearch) ??  searchDefaultValues;

  const formik = useFormik({
    initialValues: {
      ...searchDefaultValues,
    },
    enableReinitialize:true,
    validationSchema: yup.object({
      searchKeyword: yup.string(),
      from: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
      to: yup
        .string()
        .required("Mandatory Field")
        .nullable()
        .test("is-not-null", "Mandatory Field", (value) => value !== null)
        .test("invalid-date-format", "please , update your date format", (value) =>
          isDateTimeValid(value),
        ),
    }),

    onSubmit: (values) => {
      setShowLogsTable(true);
      onSearch(values);
    },
  });
  useEffect(() => {
    Object.entries(searchLastLogs).map(item=>{
      formik.setFieldValue(
        item[0],
          item[1],
        true,
      );
    })
    if(searchLastLogs !== searchDefaultValues ) onSearch(searchLastLogs);

  }, [])
  const handleResetForm = ()=>{
    localStorage.removeItem("searchLogs");
    onSearch(undefined)
    formik.initialValues = searchDefaultValues
    formik.resetForm({values:undefined})

  }
  
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormGrid >
          <InputGrid item>
            <InputLabelTitle id="search">Search for</InputLabelTitle>
            <FormControl fullWidth>
              <FormikTextField
                style={{ width: 180 }}
                size="small"
                formik={formik}
                name="searchKeyword"
              />
            </FormControl>
          </InputGrid>
          <InputGrid>
            <InputLabelTitle id="in">From</InputLabelTitle>
            <FormControl>
              <DatePickerWrapper>
                <FormikDateTimePicker formik={formik} name="from" />
              </DatePickerWrapper>
            </FormControl>
          </InputGrid>
          <InputGrid>
            <InputLabelTitle>To</InputLabelTitle>
            <FormControl>
              <DatePickerWrapper style={{ width: 180 }}>
                <FormikDateTimePicker formik={formik} name="to" />
              </DatePickerWrapper>
            </FormControl>
          </InputGrid>
          <div>

          <TitleLable>Search</TitleLable>
            <Button type="submit" variant="contained">
              Search
            </Button>
          </div>
          <div>
            <TitleLable>Clear</TitleLable>
            <Button size="small"  type="reset"  onClick={ e => handleResetForm()} color="inherit" variant="contained">
            Clear
            </Button>
          </div>
        </FormGrid>
      </form>
    </div>
  );
};

export default LogsForm;
