import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SearchFields, paginationField } from "../../../components/subscriber-profiles-cdr/types";

interface LastCdrEdrInitialState {
  search: SearchFields | undefined;
  searchCdr:paginationField;
  searchEdr:paginationField;
  searchBundle:paginationField;
  searchCounter:paginationField;
}

const lastCdrEdrSlice = createSlice({
  name: "lastCdrEdr",
  initialState: {
    search:   undefined,
    searchCdr:{
      pageNo: 0,
      perPage: 50,
      colFilters:{}
    },
    searchEdr:{
      pageNo: 0,
      perPage: 50,
      colFilters:{}
    },
    searchBundle:{
      pageNo: 0,
      perPage: 50,
      colFilters:{}
    },
    searchCounter:{
      pageNo: 0,
      perPage: 50,
      colFilters:{}
    },
  } as LastCdrEdrInitialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
      state.searchEdr = {
        pageNo: 0,
        perPage: 50,
        colFilters:{}
      };
      state.searchCdr = {
        pageNo: 0,
        perPage: 50,
        colFilters:{}
      };
      state.searchBundle = {
        pageNo: 0,
        perPage: 50,
        colFilters:{}
      }
      state.searchCounter = {
        pageNo: 0,
        perPage: 50,
        colFilters:{}
      }
      localStorage.removeItem("searchCdr")
      localStorage.removeItem("searchEdr")
      localStorage.removeItem("searchBundle")
      localStorage.removeItem("searchCounter")
      const setLocalStorageSearch:any = JSON.stringify(state.search)
      setLocalStorageSearch && localStorage.removeItem("searchSubscriber")
      setLocalStorageSearch &&  localStorage.setItem("searchSubscriber",setLocalStorageSearch);
    },
    setEdrSearch: (state, action) => {
      state.searchEdr = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchEdr)
      setLocalStorageSearch && localStorage.removeItem("searchEdr")
      setLocalStorageSearch &&  localStorage.setItem("searchEdr",setLocalStorageSearch);
    },
    setCdrSearch: (state, action) => {

      state.searchCdr = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchCdr)
      setLocalStorageSearch && localStorage.removeItem("searchCdr")
      setLocalStorageSearch &&  localStorage.setItem("searchCdr",setLocalStorageSearch);
    },
    setBundleSearch: (state, action) => {
      state.searchBundle = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchBundle)
      setLocalStorageSearch && localStorage.removeItem("searchBundle")
      setLocalStorageSearch &&  localStorage.setItem("searchBundle",setLocalStorageSearch);
    },
    setCounterSearch: (state, action) => {
      state.searchCounter = action.payload;
      const setLocalStorageSearch:any = JSON.stringify(state.searchCounter)
      setLocalStorageSearch && localStorage.removeItem("searchCounter")
      setLocalStorageSearch &&  localStorage.setItem("searchCounter",setLocalStorageSearch);
    },
    setClear: (state, action) => {
      state.search = undefined;
      localStorage.removeItem("searchSubscriber")
      localStorage.removeItem("searchCdr")
      localStorage.removeItem("searchEdr")
      localStorage.removeItem("searchBundle")
      localStorage.removeItem("searchCounter")
    },
  },
});

export const {setCounterSearch, setBundleSearch, setEdrSearch,setCdrSearch,setSearch, setClear } = lastCdrEdrSlice.actions;
export const selectLastCdrEdrSearch = (state: RootState) => {
const searchValue:any = localStorage.getItem("searchSubscriber");

  if (state.lastCdrEdr.search) {
    return state.lastCdrEdr.search
  } else {
    return JSON.parse(searchValue)
  }
}

export default lastCdrEdrSlice.reducer;
