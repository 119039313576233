import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { MeasurementsTableDataType } from "./table-types";
import { Box, Tooltip } from "@mui/material";
import styled from "@emotion/styled";
import _ from "lodash";

const ScrollableKpiList = styled.div`
  overflow: scroll;
  height: 350px;
`;

type MeasurementsCheckboxesProps = {
  handleChange: (name: string, value: boolean, children: string[]) => void;
  handleCheckChild: (parent: string, child: string, value: boolean) => void;
  measurementsKpisTables: MeasurementsTableDataType;
  checkedChildren: Record<string, Record<string, boolean>>;
  checked: Record<string, boolean>;
};

const MeasurementsCheckboxes = ({
  handleChange,
  handleCheckChild,
  checked,
  measurementsKpisTables,
  checkedChildren,
}: MeasurementsCheckboxesProps) => {
  const sortedTableNames = _.sortBy(measurementsKpisTables.tables, "table");
  return (
    <ScrollableKpiList>
      {sortedTableNames.map((kpi) => {
        return (
          <div key={kpi.table}>
            <FormControlLabel
              label={kpi.table}
              control={
                <Checkbox
                  checked={Boolean(checked[kpi.table])}
                  onChange={(_, value) => handleChange(kpi.table, value, kpi.cols)}
                />
              }
            />
            {checked[kpi.table] && (
              <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                {_.sortBy(kpi.cols).map((col) => (
                  <div key={col}>
                    <Tooltip title={col} placement="bottom-end">
                      <FormControlLabel
                        label={col}
                        control={
                          <Checkbox
                            checked={Boolean(checkedChildren?.[kpi.table]?.[col])}
                            onChange={(_, value) => handleCheckChild(kpi.table, col, value)}
                          />
                        }
                      />
                    </Tooltip>
                  </div>
                ))}
              </Box>
            )}
          </div>
        );
      })}
    </ScrollableKpiList>
  );
};

export default MeasurementsCheckboxes;
