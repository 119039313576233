import { createTheme } from "@mui/material";
import { colors } from "../../common/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.vodafoneRed,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 34,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderRadius: 34,
          boxShadow: "0px 0px 6px #00000029",
        },
        root: {
          borderRadius: 34,
          boxShadow: "0px 0px 6px #00000029",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {},
      },
    },
  },
});
