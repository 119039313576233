const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g id="noun_Info_4011782" transform="translate(-2 -2)">
      <path
        id="Path_328"
        data-name="Path 328"
        d="M16,30A14,14,0,1,0,2,16,14,14,0,0,0,16,30ZM16,9a1.4,1.4,0,1,1-1.4,1.4A1.4,1.4,0,0,1,16,9Zm-1.4,5.6a1.4,1.4,0,0,1,2.8,0v7a1.4,1.4,0,0,1-2.8,0Z"
        transform="translate(0 0)"
        fill="#909ba8"
      />
    </g>
  </svg>
);

export default InfoIcon;
