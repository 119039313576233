import { useEffect } from "react";
import { Page } from "../shared/Page";
import CdrDashboard from "./cdr-dashboard";
import CdrForm from "./cdr-form";
import { useSelector } from "react-redux";
import { RootState } from "../../lib/store/store";
import { useAppDispatch } from "../../lib/store/hooks";
import {
  selectLastCdrEdrSearch,
  setBundleSearch,
  setCdrSearch,
  setCounterSearch,
  setEdrSearch,
  setSearch,
} from "../../lib/store/slices/last-cdredr-slice";
import { SearchFields } from "./types";

const CdrSearch = () => {
  const search = useSelector(selectLastCdrEdrSearch);

  const dispatch = useAppDispatch();

  const handlesetSearch = (value: SearchFields) => {
    
    dispatch(setSearch({...value, searchClickedTimestamp: new Date()}));
  };


  useEffect(() => {
    const searchCdrValue:any = JSON.parse(localStorage.getItem("searchCdr"));
    const searchEdrValue:any = JSON.parse(localStorage.getItem("searchEdr"));
    const searchBundleValue:any = JSON.parse(localStorage.getItem("searchBundle"));
    const searchCounterValue:any = JSON.parse(localStorage.getItem("searchCounter"));
    searchCdrValue !== null && searchCdrValue &&  dispatch(setCdrSearch(searchCdrValue))
    searchBundleValue !== null && searchBundleValue && dispatch(setBundleSearch(searchBundleValue))
    searchEdrValue !== null && searchEdrValue && dispatch(setEdrSearch(searchEdrValue))
    searchCounterValue !== null && searchCounterValue && dispatch(setCounterSearch(searchCounterValue))
    
  }, [])
  

  // this made it switch tabs for no reason. If i'm in last 50cdrs tab and I set another date, it switched back to products tab...
  // useEffect(() => {
  //   if (search) {
  //     const subroute =
  //       search.types === "cdr"
  //         ? subscriberProfilesCdrSubroutes.lastCdrs
  //         : subscriberProfilesCdrSubroutes.profileInfo;
  //     const fullRoute = `${routes.subscriberProfilesCdr}/${subroute}`;
  //     //navigate(fullRoute);
  //   }
  // }, [search]);

  return (
    <Page title="Subscriber Profiles and CDRs">
      <CdrForm onSearch={handlesetSearch} />
      {search &&  search !== undefined && search !== null && <CdrDashboard  search={search} />}
    </Page>
  );
};

export default CdrSearch;
