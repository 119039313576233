import Alert, { AlertColor } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { FunctionComponent } from "react";

export interface customAlertProps {
  msg: string;
  showTitle: boolean;
  type: AlertColor;
  title?: string;
}

const CustomErrorMessage: FunctionComponent<{ error: string; notShowTitle?: boolean }> = ({
  error,
  notShowTitle,
}) => {
  return (
    <div>
      <Alert severity="warning">
        {notShowTitle ? null : <AlertTitle>An error accurred</AlertTitle>}
        <strong>{error}</strong>
      </Alert>
    </div>
  );
};
export const CustomAlert: FunctionComponent<customAlertProps> = ({ msg, showTitle,title,type }) => {
  return (
    <div>
      <Alert severity={type}>
        {showTitle && <AlertTitle>{title}</AlertTitle>}
        <strong>{msg}</strong>
      </Alert>
    </div>
  );
};

export default CustomErrorMessage;
