import styled from "@emotion/styled";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonRouterLink from "../../common/button-router-link";
import { colors } from "../../common/colors";
import { adminSubroutes, routes } from "../../lib/routes";
import { useRoleListQuery } from "../../lib/store/services/symphony";
import RoleRow from "../roles/role-row";
import Error from "../shared/error";
import { Loading } from "../shared/loading";

const TableFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UserTableHead = styled(TableHead)`
  width: 100%;
  height: 46px;
  background: ${colors.vodafoneRed};
  border-radius: 20px 20px 0 0;
  opacity: 1;
  & .MuiTableCell-head {
    color: white;
    background: ${colors.vodafoneRed};
  }
`;

const TableHeadCell = styled(TableCell)`
  text-align: center;
  max-width: 100px;
  min-width: 100px;
`;

const ColoredTableRow = styled(RoleRow, {
  shouldForwardProp: (prop) => (prop === "isColored" ? false : true),
})<{ isColored?: boolean }>`
  background: ${({ isColored }) =>
    isColored ? colors.lightGreyBackground : colors.fadedGreyBackground};
`;

const AdminRoles = () => {
  const { error, isLoading, data } = useRoleListQuery();

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data) {
    return <Error />;
  }

  return (
    <div>
      <TableContainer
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
        sx={{ maxHeight: 400 }}
        component={Paper}
      >
        <div>
          <Table stickyHeader size="small" aria-label="user table">
            <UserTableHead>
              <TableRow>
                <TableHeadCell>Role</TableHeadCell>
                <TableHeadCell>Local Market</TableHeadCell>
                <TableHeadCell>Role information</TableHeadCell>
                <TableHeadCell>Actions</TableHeadCell>
              </TableRow>
            </UserTableHead>
            <TableBody>
              {data.data.map((row, i) => (
                <ColoredTableRow isColored={i % 2 === 0} key={row.name} role={row} />
              ))}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      <TableFooter>
        <ButtonRouterLink to={`${routes.admin}/${adminSubroutes.addRole}`}>
          <Button variant="contained" startIcon={<Icon icon={plusFill} />}>
            Add New Role
          </Button>
        </ButtonRouterLink>
      </TableFooter>
    </div>
  );
};

export default AdminRoles;
