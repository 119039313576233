import styled from "@emotion/styled";
import { TableContainer } from "@mui/material";

const SectionTitle = styled.div`
  margin: 15px 0 15px 0;
  font-weight: bold;
`;

const AdminMessageParagraph = styled.div`
  font-weight: bold;
`;

const CenteredTitle = styled.div`
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
`;

const UpcomingMaintenance = () => {
  return (
    <TableContainer>
      <SectionTitle>
        Upcoming Maintenance <br />
        System under UAT
      </SectionTitle>
      <CenteredTitle>***Important Notice***</CenteredTitle>
      <AdminMessageParagraph>
        In order to be able to see the embedded charts below, you have to be logged into GCP. Please
        open another tab in this browser and log in using your Vodafone.com account, then, refresh
        this page.
      </AdminMessageParagraph>
    </TableContainer>
  );
};

export default UpcomingMaintenance;
