import styled from "@emotion/styled";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ModalDataFileType from "../subscriber-profiles-cdr/modal-data-fileType";
import { Logs } from "../../lib/types/logs-types";
import { DetailCdrEdr, ProfileInfoTableData, RecordElement } from "../subscriber-profiles-cdr/table-types";
import { Subscription } from "../subscriber-profiles-cdr/types";
import { Loading } from "./loading";

const ModalHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const style = {
  position: "absolute",
  border: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 485,
  height: 272,
  opacity: 1,
  borderRadius: "32px",
  background: "#fff",
  boxShadow: 24,
  p: 4,
};
const CloseModalButton = styled(Button)`
  background: none;
  color: black;
  font-weight: 700;
  font-size: 22px;
`;

const ModalBox = styled(Box)`
  text-align: center;
`;

const ModalDataFileTypeWrapper = styled.div`
  margin: 20px 0 20px 0;
  display: flex;
  justify-content: space-between;
`;

const FooterButton = styled(Button)`
  background: #e60a17;
  border-radius: 34px;
  height: 40px;
  min-width: 180px;
  text-transform: none;
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
`;

interface ExportModalProps {
  data?:
    | Logs[]
    | ProfileInfoTableData["products"]
    | Subscription[]
    | (string | number)[]
    | ({} | RecordElement)[]
    | RecordElement
    | DetailCdrEdr;
  fullData?: Logs[] | Subscription[] | (string | number)[] | ({} | RecordElement)[];
  headers?: {
    label: string;
    value?:string;
    mapping?: (row: any, index?: number) => any;
  }[];
  selectedTab: string;
  fetchFullData?: (filterResult?:boolean) => Promise<
    | Logs[]
    | ProfileInfoTableData["products"]
    | Subscription[]
    | (string | number)[]
    | ({} | RecordElement)[]
  >;
  isFetching?:boolean;
}

const ExportModal = ({ data, fullData, headers, selectedTab, fetchFullData, isFetching }: ExportModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <FooterButton variant="contained" onClick={handleOpen}>
        Export
      </FooterButton>
      <Modal open={isOpen} onClose={handleClose}>
        <ModalBox sx={style}>
          <ModalHeadWrapper>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={700}>
              Export
            </Typography>
            <CloseModalButton onClick={handleClose}>X</CloseModalButton>
          </ModalHeadWrapper>
          <ModalDataFileTypeWrapper>
            {isFetching ? <Loading /> : 
            <ModalDataFileType
              data={data}
              fullData={fullData}
              headers={headers}
              selectedTab={selectedTab}
              fetchFullData={fetchFullData}
              onCloseModal={handleClose}
            />
          }
          </ModalDataFileTypeWrapper>
        </ModalBox>
      </Modal>
    </div>
  );
};

export default ExportModal;
