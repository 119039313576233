import { TextField, TextFieldProps } from "@mui/material";
import { FormikValues } from "formik";
import { generateMuiFormikFields } from "../../lib/mui-formik-field-generator";
import { FormikInstance } from "../../lib/use-formik-return-type";

export const FormikTextField = <T extends FormikValues>({
  formik,
  name,
  ...rest
}: TextFieldProps & { formik: FormikInstance<T>; name: string }) => {
  const formikFields = generateMuiFormikFields(name, formik);
  return <TextField {...rest} {...formikFields} />;
};
