const PreviewIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.141"
    height="12.155"
    viewBox="0 0 12.141 12.155"
  >
    <g id="noun_preview_3107351" transform="translate(-8.978 -8.978)">
      <path
        id="Path_484"
        data-name="Path 484"
        d="M19.881,10.575H15.592a3.614,3.614,0,1,0-3,5.631,3.677,3.677,0,0,0,.545-.041v3.7a1.254,1.254,0,0,0,1.252,1.263h3.24a.148.148,0,0,0,.056-.012.126.126,0,0,0,.047-.031l3.354-3.354a.148.148,0,0,0,.028-.043.053.053,0,0,0,0-.013.353.353,0,0,0,0-.039V11.828A1.254,1.254,0,0,0,19.881,10.575Zm-6.74,5.294a3.318,3.318,0,1,1,2.085-5.294c.037.049.074.1.108.148s.065.1.1.148a3.315,3.315,0,0,1-1.993,4.933c-.049.013-.1.025-.148.036Zm6.066,3.337-1.423,1.424v-1.9a.956.956,0,0,1,.954-.954h1.889Zm1.63-1.719h-2.1a1.252,1.252,0,0,0-1.251,1.251v2.1H14.393a.956.956,0,0,1-.956-.956v-3.77a3.612,3.612,0,0,0,2.336-5.239h4.108a.956.956,0,0,1,.956.956Z"
        fill="#fff"
      />
      <path
        id="Path_485"
        data-name="Path 485"
        d="M19.505,24.449a.148.148,0,0,0,0-.148,3.26,3.26,0,0,0-1.9-1.352c-.052-.015-.11-.027-.164-.039s-.1-.025-.159-.036a4.083,4.083,0,0,0-.741-.065A3.41,3.41,0,0,0,13.576,24.3a.148.148,0,0,0,0,.148A3.407,3.407,0,0,0,16.54,25.94a4.072,4.072,0,0,0,.5-.033l.148-.019.148-.025A3.321,3.321,0,0,0,19.505,24.449Zm-2.464,1.162a4.053,4.053,0,0,1-.5.033,3.172,3.172,0,0,1-2.657-1.267,3.206,3.206,0,0,1,2.657-1.27,3.853,3.853,0,0,1,.593.043l.148.028c.052.01.1.021.148.033a3.051,3.051,0,0,1,1.778,1.165,3.057,3.057,0,0,1-1.863,1.186l-.148.028Z"
        transform="translate(-3.9 -11.782)"
        fill="#fff"
      />
      <path
        id="Path_486"
        data-name="Path 486"
        d="M28.006,26.848a1.134,1.134,0,0,0-.3-.766,1.16,1.16,0,0,0-.139-.13,1.22,1.22,0,0,0-.136-.092,1.14,1.14,0,1,0-.021,1.99,1.22,1.22,0,0,0,.148-.1,1.186,1.186,0,0,0,.148-.136,1.134,1.134,0,0,0,.3-.768Zm-.593.643a.843.843,0,1,1,0-1.286.866.866,0,0,1,.148.164.833.833,0,0,1,.148.434v.089a.834.834,0,0,1-.148.436.815.815,0,0,1-.151.163Z"
        transform="translate(-14.269 -14.253)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default PreviewIcon;
